import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../utils/axiosConfig';
import './Transfer.css';

function Transfer() {
    const [friends, setFriends] = useState([]);
    const [selectedFriend, setSelectedFriend] = useState('');
    const [amount, setAmount] = useState('');
    const [userBalance, setUserBalance] = useState(0);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const currentUserId = localStorage.getItem('userId');

    useEffect(() => {
        if (!currentUserId) {
            navigate('/login');
            return;
        }
        fetchInitialData();
    }, [navigate, currentUserId]);

    const fetchInitialData = async () => {
        try {
            setLoading(true);
            setError('');
            
            // Fetch friends and balance in parallel
            const [friendsResponse, balanceResponse] = await Promise.all([
                axios.get('/backend/handlers/get_friends.php', {
                    params: {
                        user_id: currentUserId,
                        status: 'accepted'
                    }
                }),
                axios.get('/backend/handlers/user_data.php', {
                    params: { id: currentUserId }
                })
            ]);
            
            if (friendsResponse.data.success) {
                setFriends(friendsResponse.data.friends);
            } else {
                throw new Error(friendsResponse.data.message || 'Failed to fetch friends list');
            }

            if (balanceResponse.data.success) {
                setUserBalance(balanceResponse.data.user.balance);
            } else {
                throw new Error(balanceResponse.data.message || 'Failed to fetch user balance');
            }
        } catch (err) {
            console.error('Error fetching data:', err);
            setError(err.message || 'Failed to load data. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const handleTransfer = async () => {
        if (!selectedFriend || !amount) {
            setError('Please fill in all fields');
            return;
        }

        if (parseFloat(amount) <= 0) {
            setError('Amount must be greater than 0');
            return;
        }

        if (parseFloat(amount) > userBalance) {
            setError('Insufficient balance');
            return;
        }

        setShowConfirmation(true);
    };

    const confirmTransfer = async () => {
        try {
            setError('');
            const formData = new FormData();
            formData.append('from_user_id', currentUserId);
            formData.append('to_user_id', selectedFriend);
            formData.append('amount', amount);

            const response = await axios.post('/backend/handlers/transfer.php', formData);

            if (response.data.success) {
                setSuccess('Transfer completed successfully!');
                setAmount('');
                setSelectedFriend('');
                await fetchInitialData(); // Refresh balance and friends list
            } else {
                throw new Error(response.data.message || 'Transfer failed');
            }
        } catch (err) {
            console.error('Error processing transfer:', err);
            setError(err.message || 'Failed to process transfer. Please try again.');
        }
        setShowConfirmation(false);
    };

    if (loading) return <div className="loading">Loading...</div>;

    return (
        <div className="transfer-container">
            <h1>Transfer FanCoins</h1>
            <div className="balance-display">
                Your Balance: {userBalance} FC
            </div>

            {error && <div className="error-message">{error}</div>}
            {success && <div className="success-message">{success}</div>}

            <div className="transfer-form">
                <div className="form-group">
                    <label>Select Friend:</label>
                    <select 
                        value={selectedFriend} 
                        onChange={(e) => setSelectedFriend(e.target.value)}
                        required
                    >
                        <option value="">Select a friend</option>
                        {friends.map(friend => (
                            <option key={friend.user_id} value={friend.user_id}>
                                {friend.username}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="form-group">
                    <label>Amount (FC):</label>
                    <input
                        type="number"
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                        min="1"
                        max={userBalance}
                        required
                        placeholder="Enter amount"
                    />
                </div>

                <button onClick={handleTransfer} className="transfer-btn">
                    Transfer FanCoins
                </button>
            </div>

            {showConfirmation && (
                <div className="confirmation-modal">
                    <div className="modal-content">
                        <h2>Confirm Transfer</h2>
                        <p>Are you sure you want to transfer {amount} FC to {friends.find(f => f.user_id === parseInt(selectedFriend))?.username}?</p>
                        <div className="modal-actions">
                            <button onClick={() => setShowConfirmation(false)} className="cancel-btn">
                                Cancel
                            </button>
                            <button onClick={confirmTransfer} className="confirm-btn">
                                Confirm Transfer
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Transfer; 