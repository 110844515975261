// Error types enum
export const ErrorTypes = {
  NETWORK: 'NETWORK_ERROR',
  AUTH: 'AUTHENTICATION_ERROR',
  VALIDATION: 'VALIDATION_ERROR',
  SERVER: 'SERVER_ERROR',
  CLIENT: 'CLIENT_ERROR',
  UNKNOWN: 'UNKNOWN_ERROR'
};

// Error severity levels
export const ErrorSeverity = {
  INFO: 'info',
  WARNING: 'warning',
  ERROR: 'error',
  CRITICAL: 'critical'
};

// Custom error class
export class AppError extends Error {
  constructor(message, type = ErrorTypes.UNKNOWN, severity = ErrorSeverity.ERROR, details = {}) {
    super(message);
    this.name = 'AppError';
    this.type = type;
    this.severity = severity;
    this.details = details;
    this.timestamp = new Date();
  }
}

// Error handler function
export const handleError = (error, context = {}) => {
  // Determine error type
  let appError;
  if (error instanceof AppError) {
    appError = error;
  } else if (error.response) {
    // Axios error with response
    appError = new AppError(
      error.response.data?.message || 'Server error occurred',
      error.response.status === 401 ? ErrorTypes.AUTH :
      error.response.status === 400 ? ErrorTypes.VALIDATION :
      ErrorTypes.SERVER,
      error.response.status >= 500 ? ErrorSeverity.CRITICAL : ErrorSeverity.ERROR,
      {
        status: error.response.status,
        data: error.response.data,
        ...context
      }
    );
  } else if (error.request) {
    // Network error
    appError = new AppError(
      'Network error occurred',
      ErrorTypes.NETWORK,
      ErrorSeverity.ERROR,
      { originalError: error, ...context }
    );
  } else {
    // Unknown error
    appError = new AppError(
      error.message || 'An unexpected error occurred',
      ErrorTypes.UNKNOWN,
      ErrorSeverity.ERROR,
      { originalError: error, ...context }
    );
  }

  // Log error
  logError(appError);

  return appError;
};

// Error logger
const logError = (error) => {
  // Create error log object with all details
  const errorLog = {
    message: error.message,
    type: error.type,
    severity: error.severity,
    timestamp: error.timestamp,
    details: error.details,
    stack: error.stack
  };

  // Log to console with appropriate styling using the errorLog object
  console.group('%cApplication Error', 'color: red; font-weight: bold;');
  console.log('%cTimestamp:', 'font-weight: bold;', errorLog.timestamp);
  console.log('%cType:', 'font-weight: bold;', errorLog.type);
  console.log('%cSeverity:', 'font-weight: bold;', errorLog.severity);
  console.log('%cMessage:', 'font-weight: bold;', errorLog.message);
  console.log('%cDetails:', 'font-weight: bold;', errorLog.details);
  if (errorLog.stack) {
    console.log('%cStack Trace:', 'font-weight: bold;');
    console.log(errorLog.stack);
  }
  console.groupEnd();

  // Return the error log object in case it's needed by the caller
  return errorLog;
};

// User-friendly error messages
export const getErrorMessage = (error) => {
  if (error instanceof AppError) {
    switch (error.type) {
      case ErrorTypes.NETWORK:
        return 'Unable to connect to the server. Please check your internet connection.';
      case ErrorTypes.AUTH:
        return 'Your session has expired. Please log in again.';
      case ErrorTypes.VALIDATION:
        return error.message || 'Please check your input and try again.';
      case ErrorTypes.SERVER:
        return 'Server error occurred. Please try again later.';
      default:
        return error.message || 'An unexpected error occurred.';
    }
  }
  return error.message || 'An unexpected error occurred.';
};

// Retry mechanism for failed requests
export const retryOperation = async (operation, maxAttempts = 3, delay = 1000) => {
  let lastError;

  for (let attempt = 1; attempt <= maxAttempts; attempt++) {
    try {
      return await operation();
    } catch (error) {
      lastError = error;
      if (attempt === maxAttempts) break;
      
      // Wait before retrying
      await new Promise(resolve => setTimeout(resolve, delay * attempt));
      
      console.log(`Retry attempt ${attempt} of ${maxAttempts}`);
    }
  }

  throw handleError(lastError, { retryAttempts: maxAttempts });
};

// HTTP status code handler
export const handleHttpStatus = (status) => {
  switch (status) {
    case 400:
      return { type: ErrorTypes.VALIDATION, severity: ErrorSeverity.WARNING };
    case 401:
    case 403:
      return { type: ErrorTypes.AUTH, severity: ErrorSeverity.ERROR };
    case 404:
      return { type: ErrorTypes.CLIENT, severity: ErrorSeverity.WARNING };
    case 500:
      return { type: ErrorTypes.SERVER, severity: ErrorSeverity.CRITICAL };
    default:
      return { type: ErrorTypes.UNKNOWN, severity: ErrorSeverity.ERROR };
  }
}; 