import React, { useEffect, useState } from 'react';
import './AlertMessage.css';

const AlertMessage = ({ message, type = 'success', duration = 3000, onClose }) => {
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsVisible(false);
            setTimeout(() => {
                onClose && onClose();
            }, 500); // Wait for fade out animation
        }, duration);

        return () => clearTimeout(timer);
    }, [duration, onClose]);

    return (
        <div className={`alert-message ${type} ${!isVisible ? 'fade-out' : ''}`}>
            <span className="alert-icon">
                {type === 'success' ? '✓' : '⚠'}
            </span>
            <span className="alert-text">{message}</span>
        </div>
    );
};

export default AlertMessage;
