import React from 'react';
import { ErrorSeverity } from '../utils/errorHandler';
import './ErrorAlert.css';

const ErrorAlert = ({ error, onClose }) => {
  if (!error) return null;

  const getSeverityClass = (severity) => {
    switch (severity) {
      case ErrorSeverity.INFO:
        return 'info';
      case ErrorSeverity.WARNING:
        return 'warning';
      case ErrorSeverity.ERROR:
        return 'error';
      case ErrorSeverity.CRITICAL:
        return 'critical';
      default:
        return 'error';
    }
  };

  const severityClass = getSeverityClass(error.severity);

  return (
    <div className={`error-alert ${severityClass}`}>
      <div className="error-alert-content">
        <div className="error-alert-header">
          <span className="error-alert-type">{error.type}</span>
          {onClose && (
            <button className="error-alert-close" onClick={onClose}>
              ×
            </button>
          )}
        </div>
        <div className="error-alert-message">{error.message}</div>
        {error.details && Object.keys(error.details).length > 0 && (
          <div className="error-alert-details">
            <pre>{JSON.stringify(error.details, null, 2)}</pre>
          </div>
        )}
      </div>
    </div>
  );
};

export default ErrorAlert; 