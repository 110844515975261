import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './LeagueSeasonManagement.css';

const API_BASE_URL = '/backend';

function LeagueSeasonManagement() {
    const { leagueId } = useParams();
    const [league, setLeague] = useState(null);
    const [seasons, setSeasons] = useState([]);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [loading, setLoading] = useState(true);

    const fetchLeagueDetails = useCallback(async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/handlers/league_management.php?league_id=${leagueId}`);
            if (response.data.status === 200) {
                setLeague(response.data.data);
            } else {
                setError(response.data.message || 'Failed to fetch league details');
            }
        } catch (err) {
            console.error('Error fetching league details:', err);
            setError('Failed to fetch league details');
        }
    }, [leagueId]);

    const fetchSeasons = useCallback(async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/handlers/league_season_management.php?league_id=${leagueId}`);
            if (response.data.status === 200) {
                setSeasons(response.data.data || []);
            } else {
                setError(response.data.message || 'Failed to fetch seasons');
            }
        } catch (err) {
            console.error('Error fetching seasons:', err);
            setError('Failed to fetch seasons');
        } finally {
            setLoading(false);
        }
    }, [leagueId]);

    const handleCreateSeason = useCallback(async (seasonData) => {
        try {
            setError('');
            const response = await axios.post(
                `${API_BASE_URL}/handlers/league_season_management.php`,
                { ...seasonData, league_id: leagueId }
            );
            
            if (response.data.status === 201) {
                setSuccess('Season created successfully');
                setShowCreateModal(false);
                fetchSeasons();
            } else {
                setError(response.data.message || 'Failed to create season');
            }
        } catch (err) {
            console.error('Error creating season:', err);
            setError('Failed to create season. Please try again.');
        }
    }, [leagueId, fetchSeasons]);

    const handleEndSeason = useCallback(async (seasonId) => {
        try {
            setError('');
            const response = await axios.put(
                `${API_BASE_URL}/handlers/league_season_management.php`,
                { season_id: seasonId, action: 'end' }
            );
            
            if (response.data.status === 200) {
                setSuccess('Season ended successfully');
                fetchSeasons();
            } else {
                setError(response.data.message || 'Failed to end season');
            }
        } catch (err) {
            console.error('Error ending season:', err);
            setError('Failed to end season. Please try again.');
        }
    }, [fetchSeasons]);

    useEffect(() => {
        const loadData = async () => {
            try {
                await Promise.all([fetchLeagueDetails(), fetchSeasons()]);
            } catch (error) {
                console.error('Error loading data:', error);
            }
        };
        loadData();
    }, [leagueId, fetchLeagueDetails, fetchSeasons]);

    return (
        <div className="league-season-management">
            <div className="header">
                <h1>{league?.name} - Season Management</h1>
                <button className="create-button" onClick={() => setShowCreateModal(true)}>
                    Create New Season
                </button>
            </div>

            {error && <div className="error-message">{error}</div>}
            {success && <div className="success-message">{success}</div>}

            {loading ? (
                <div className="loading">Loading seasons...</div>
            ) : (
                <div className="seasons-list">
                    {seasons.length === 0 ? (
                        <div className="no-seasons">No seasons found for this league</div>
                    ) : (
                        seasons.map(season => (
                            <div key={season.season_id} className="season-card">
                                <div className="season-header">
                                    <h3>{season.season_name}</h3>
                                    <span className={`status ${season.status.toLowerCase()}`}>
                                        {season.status}
                                    </span>
                                </div>
                                <div className="season-details">
                                    <p>Start Date: {new Date(season.start_date).toLocaleDateString()}</p>
                                    <p>End Date: {new Date(season.end_date).toLocaleDateString()}</p>
                                    <p>Prize Pool: {season.prize_pool}</p>
                                </div>
                                {season.status === 'active' && (
                                    <div className="season-actions">
                                        <button 
                                            className="end-season-button"
                                            onClick={() => handleEndSeason(season.season_id)}
                                        >
                                            End Season
                                        </button>
                                    </div>
                                )}
                            </div>
                        ))
                    )}
                </div>
            )}

            {showCreateModal && (
                <CreateSeasonModal 
                    onSubmit={handleCreateSeason}
                    onClose={() => setShowCreateModal(false)}
                />
            )}
        </div>
    );
}

function CreateSeasonModal({ onSubmit, onClose }) {
    const [seasonData, setSeasonData] = useState({
        season_name: '',
        start_date: '',
        end_date: '',
        prize_pool: ''
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(seasonData);
    };

    return (
        <div className="modal-overlay">
            <div className="season-modal">
                <button className="close-button" onClick={onClose}>×</button>
                <h2>Create New Season</h2>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>Season Name</label>
                        <input
                            type="text"
                            value={seasonData.season_name}
                            onChange={(e) => setSeasonData({...seasonData, season_name: e.target.value})}
                            required
                        />
                    </div>
                    <div className="form-row">
                        <div className="form-group">
                            <label>Start Date</label>
                            <input
                                type="datetime-local"
                                value={seasonData.start_date}
                                onChange={(e) => setSeasonData({...seasonData, start_date: e.target.value})}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label>End Date</label>
                            <input
                                type="datetime-local"
                                value={seasonData.end_date}
                                onChange={(e) => setSeasonData({...seasonData, end_date: e.target.value})}
                                required
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <label>Prize Pool (₦)</label>
                        <input
                            type="number"
                            value={seasonData.prize_pool}
                            onChange={(e) => setSeasonData({...seasonData, prize_pool: e.target.value})}
                            required
                        />
                    </div>
                    <div className="form-actions">
                        <button type="button" className="cancel-button" onClick={onClose}>Cancel</button>
                        <button type="submit" className="submit-button">Create Season</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default LeagueSeasonManagement;
