import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaUserCheck, FaUserTimes, FaTrophy, FaStar, FaUserClock } from 'react-icons/fa';
import './FriendRequests.css';

const API_BASE_URL = '/backend';

function FriendRequests() {
    const [pendingRequests, setPendingRequests] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [teams, setTeams] = useState([]);
    const [messageStatus, setMessageStatus] = useState({ show: false, type: '', message: '' });
    const userId = localStorage.getItem('userId');

    useEffect(() => {
        fetchPendingRequests();
        fetchTeams();
    }, []);

    const fetchPendingRequests = async () => {
        try {
            console.log('Fetching friend requests for user:', userId);
            const response = await axios.get(`${API_BASE_URL}/handlers/friendrequest.php?user_id=${userId}`);
            console.log('Friend requests response:', response.data);
            if (response.data.success) {
                setPendingRequests(response.data.requests || []);
            } else {
                setError(response.data.message || 'Failed to fetch friend requests');
            }
        } catch (error) {
            console.error('Error fetching friend requests:', error);
            setError('Failed to fetch friend requests');
        } finally {
            setLoading(false);
        }
    };

    const fetchTeams = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/handlers/team_management.php`);
            if (response.data.status === 200) {
                setTeams(response.data.data);
            }
        } catch (error) {
            console.error('Error fetching teams:', error);
        }
    };

    const getTeamLogo = (teamName) => {
        const team = teams.find(team => team.name === teamName);
        return team ? `${API_BASE_URL}/${team.logo}` : '';
    };

    const handleRequest = async (friendId, action) => {
        try {
            const response = await axios.post(`${API_BASE_URL}/handlers/friendrequest.php`, {
                action: action,
                user_id: userId,
                friend_id: friendId
            });

            if (response.data.success) {
                setPendingRequests(prev => prev.filter(request => request.id !== friendId));
                setMessageStatus({
                    show: true,
                    type: 'success',
                    message: action === 'accept' ? 'Friend request accepted!' : 'Friend request rejected'
                });
                
                setTimeout(() => {
                    setMessageStatus({ show: false, type: '', message: '' });
                }, 3000);
            }
        } catch (error) {
            setMessageStatus({
                show: true,
                type: 'error',
                message: 'Failed to process request'
            });
        }
    };

    const cancelRequest = async (friendId) => {
        try {
            const response = await axios.post(`${API_BASE_URL}/handlers/friendrequest.php`, {
                action: 'cancel',
                user_id: userId,
                friend_id: friendId
            });

            if (response.data.success) {
                setPendingRequests(prev => prev.filter(request => request.id !== friendId));
                setMessageStatus({
                    show: true,
                    type: 'success',
                    message: 'Friend request cancelled'
                });
                
                setTimeout(() => {
                    setMessageStatus({ show: false, type: '', message: '' });
                }, 3000);
            }
        } catch (error) {
            setMessageStatus({
                show: true,
                type: 'error',
                message: 'Failed to cancel request'
            });
        }
    };

    if (loading) return <div className="requests-loading">Loading requests...</div>;
    if (error) return <div className="requests-error">{error}</div>;

    const receivedRequests = pendingRequests.filter(req => req.request_type === 'received');
    const sentRequests = pendingRequests.filter(req => req.request_type === 'sent');

    return (
        <div className="friend-requests-container">
            {messageStatus.show && (
                <div className={`alert alert-${messageStatus.type}`}>
                    {messageStatus.message}
                </div>
            )}

            {/* Received Requests Section */}
            <div className="requests-section">
                <div className="requests-header">
                    <h1>Received Requests</h1>
                    <span className="requests-count">{receivedRequests.length} Pending</span>
                </div>

                {receivedRequests.length === 0 ? (
                    <div className="no-requests">
                        <div className="no-requests-content">
                            <h3>No Received Requests</h3>
                            <p>You don't have any incoming friend requests at the moment.</p>
                        </div>
                    </div>
                ) : (
                    <div className="requests-grid">
                        {receivedRequests.map(request => (
                            <div key={request.id} className="request-card">
                                <div className="request-header">
                                    <div className="request-avatar">
                                        <img 
                                            src={getTeamLogo(request.favorite_team)} 
                                            alt={request.favorite_team}
                                        />
                                    </div>
                                    <div className="request-user-info">
                                        <h3>{request.username}</h3>
                                        <span className="favorite-team">{request.favorite_team}</span>
                                    </div>
                                </div>
                                
                                <div className="request-stats">
                                    <div className="stat-item">
                                        <FaTrophy className="stat-icon" />
                                        <div className="stat-details">
                                            <label>Win Rate</label>
                                            <span>{request.win_rate || 0}%</span>
                                        </div>
                                    </div>
                                    <div className="stat-item">
                                        <FaStar className="stat-icon" />
                                        <div className="stat-details">
                                            <label>Score</label>
                                            <span>{request.leaderboard_score || 0}</span>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="request-actions">
                                    <button 
                                        className="accept-btn"
                                        onClick={() => handleRequest(request.id, 'accept')}
                                    >
                                        <FaUserCheck /> Accept
                                    </button>
                                    <button 
                                        className="reject-btn"
                                        onClick={() => handleRequest(request.id, 'reject')}
                                    >
                                        <FaUserTimes /> Reject
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>

            {/* Sent Requests Section */}
            <div className="requests-section">
                <div className="requests-header">
                    <h1>Sent Requests</h1>
                    <span className="requests-count">{sentRequests.length} Pending</span>
                </div>

                {sentRequests.length === 0 ? (
                    <div className="no-requests">
                        <div className="no-requests-content">
                            <h3>No Sent Requests</h3>
                            <p>You haven't sent any friend requests yet.</p>
                        </div>
                    </div>
                ) : (
                    <div className="requests-grid">
                        {sentRequests.map(request => (
                            <div key={request.id} className="request-card sent-request">
                                <div className="request-header">
                                    <div className="request-avatar">
                                        <img 
                                            src={getTeamLogo(request.favorite_team)} 
                                            alt={request.favorite_team}
                                        />
                                    </div>
                                    <div className="request-user-info">
                                        <h3>{request.username}</h3>
                                        <span className="favorite-team">{request.favorite_team}</span>
                                    </div>
                                </div>
                                
                                <div className="request-stats">
                                    <div className="stat-item">
                                        <FaTrophy className="stat-icon" />
                                        <div className="stat-details">
                                            <label>Win Rate</label>
                                            <span>{request.win_rate || 0}%</span>
                                        </div>
                                    </div>
                                    <div className="stat-item">
                                        <FaStar className="stat-icon" />
                                        <div className="stat-details">
                                            <label>Score</label>
                                            <span>{request.leaderboard_score || 0}</span>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="request-actions single-action">
                                    <button 
                                        className="cancel-btn"
                                        onClick={() => cancelRequest(request.id)}
                                    >
                                        <FaUserClock /> Cancel Request
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
}

export default FriendRequests; 