import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from '../utils/axiosConfig';
import '../styles/leagueDetails.css';
import { 
    FaTrophy, FaMedal
} from 'react-icons/fa';

const LeagueDetails = () => {
    const { leagueId } = useParams();
    const navigate = useNavigate();
    const [league, setLeague] = useState(null);
    const [leaderboard, setLeaderboard] = useState([]);
    const [userPosition, setUserPosition] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const userId = localStorage.getItem('userId');

    const formatNumber = (num) => {
        return new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        }).format(num);
    };

    const getOrdinalSuffix = (num) => {
        const j = num % 10;
        const k = num % 100;
        if (j === 1 && k !== 11) return num + "st";
        if (j === 2 && k !== 12) return num + "nd";
        if (j === 3 && k !== 13) return num + "rd";
        return num + "th";
    };

    useEffect(() => {
        const fetchLeagueDetails = async () => {
            try {
                setLoading(true);
                setError('');
                const response = await axios.get(
                    `/backend/handlers/league_details.php?league_id=${leagueId}${userId ? `&user_id=${userId}` : ''}`
                );
                console.log('API Response:', response.data);
                
                if (response.data.status === 200) {
                    const {
                        league: leagueData,
                        leaderboard: leaderboardData,
                        user_position: userPositionData
                    } = response.data.data;
                    console.log('League data:', leagueData);
                    
                    if (!leagueData || typeof leagueData.id !== 'number') {
                        throw new Error('Invalid league data received');
                    }

                    const formattedLeaderboard = leaderboardData.map((player, index) => ({
                        ...player,
                        deposit_amount_formatted: formatNumber(player.deposit_amount) + ' FC',
                        rank_display: getOrdinalSuffix(player.rank_position),
                        rank_position: index + 1
                    }));
                    
                    const formattedUserPosition = userPositionData
                        ? {
                            ...userPositionData,
                            deposit_amount_formatted: formatNumber(userPositionData.deposit_amount) + ' FC',
                            rank_display: getOrdinalSuffix(userPositionData.rank)
                        }
                        : null;
                    
                    setLeague(leagueData);
                    setLeaderboard(formattedLeaderboard);
                    setUserPosition(formattedUserPosition);
                } else {
                    setError(response.data.message || 'Failed to load league details');
                }
            } catch (err) {
                console.error('Error fetching league details:', err);
                if (err.response?.status === 401) {
                    navigate('/login');
                } else {
                    setError('Failed to load league details. Please try again.');
                }
            } finally {
                setLoading(false);
            }
        };

        if (leagueId) {
            fetchLeagueDetails();
        }
    }, [leagueId, userId, navigate]);

    return (
        <div className="league-details__container">
            {loading ? (
                <div>Loading...</div>
            ) : error ? (
                <div className="error-message">{error}</div>
            ) : !league ? (
                <div>No league found</div>
            ) : (
                <>
                    <div className="league-details__banner-wrapper">
                        <img 
                            src={league.league_banner}
                            alt={`${league.name} banner`}
                            className="league-details__banner-image"
                            onError={(e) => {
                                e.target.src = '/images/default-league-banner.jpg';
                                e.target.onerror = null;
                            }}
                        />
                        <div className="league-details__banner-overlay">
                            <div className="league-details__icon-wrapper">
                                <img 
                                    src={league.league_icon}
                                    alt={`${league.name} icon`}
                                    className="league-details__icon-image"
                                    onError={(e) => {
                                        e.target.src = '/images/default-league-icon.png';
                                        e.target.onerror = null;
                                    }}
                                />
                            </div>
                            <div className="league-details__banner-content">
                                <h1 className="league-details__title">{league.name}</h1>
                                <p className="league-details__description">{league.description}</p>
                            </div>
                        </div>
                    </div>

                    {userPosition && (
                        <div className="league-details__position-card">
                            <h2 className="league-details__position-title">
                                <FaTrophy /> Your Position
                            </h2>
                            <div className="league-details__stats-grid">
                                <div className="league-details__stat-item">
                                    <span className="league-details__stat-label">Rank</span>
                                    <span className="league-details__stat-value">{userPosition.rank_display}</span>
                                </div>
                                <div className="league-details__stat-item">
                                    <span className="league-details__stat-label">Points</span>
                                    <span className="league-details__stat-value">{userPosition.points}</span>
                                </div>
                                <div className="league-details__stat-item">
                                    <span className="league-details__stat-label">Streak</span>
                                    <span className="league-details__stat-value">{userPosition.current_streak}</span>
                                </div>
                                <div className="league-details__stat-item">
                                    <span className="league-details__stat-label">W/D/L</span>
                                    <span className="league-details__stat-value">
                                        {userPosition.wins}/{userPosition.draws}/{userPosition.losses}
                                    </span>
                                </div>
                                <div className="league-details__stat-item">
                                    <span className="league-details__stat-label">Total Bets</span>
                                    <span className="league-details__stat-value">{userPosition.total_bets}</span>
                                </div>
                                <div className="league-details__stat-item">
                                    <span className="league-details__stat-label">Deposit</span>
                                    <span className="league-details__stat-value">{userPosition.deposit_amount_formatted}</span>
                                </div>
                            </div>
                            <div className="league-details__progress-section">
                                <div className="league-details__progress-info">
                                    <span>Progress to Next Rank</span>
                                    <span>{Math.max(50 - userPosition.points, 0)} points needed</span>
                                </div>
                                <div className="league-details__progress-container">
                                    <div 
                                        className="league-details__progress-bar"
                                        style={{ width: `${Math.min((userPosition.points / 50) * 100, 100)}%` }}
                                    ></div>
                                </div>
                            </div>
                        </div>
                    )}
                    {/* Leaderboard */}
                    <div className="league-details__leaderboard">
                        <h2 className="league-details__leaderboard-title">
                            <FaTrophy /> League Leaderboard
                        </h2>
                        <div className="league-details__table-container">
                            <table className="league-details__table">
                                <thead>
                                    <tr>
                                        <th>Rank</th>
                                        <th>Player</th>
                                        <th>Points</th>
                                        <th>Streak</th>
                                        <th>W/D/L</th>
                                        <th>Total Bets</th>
                                        <th>Deposit</th>
                                        <th>Join Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {leaderboard.map((player, index) => (
                                        <tr 
                                            key={`${player.user_id}-${player.membership_id ?? index}`}
                                            className={userPosition?.user_id === player.user_id ? 'current-user' : ''}
                                        >
                                            <td>
                                                <div className="league-details__rank">
                                                    {player.rank_display}
                                                    {player.rank_position === 1 && (
                                                        <FaTrophy className="league-details__rank-icon gold" />
                                                    )}
                                                    {player.rank_position === 2 && (
                                                        <FaMedal className="league-details__rank-icon silver" />
                                                    )}
                                                    {player.rank_position === 3 && (
                                                        <FaMedal className="league-details__rank-icon bronze" />
                                                    )}
                                                </div>
                                            </td>
                                            <td>{player.username}</td>
                                            <td className="league-details__points">
                                                {player.points}
                                                <div className="league-details__progress-container">
                                                    <div 
                                                        className="league-details__progress-bar"
                                                        style={{ width: `${Math.min((player.points / 50) * 100, 100)}%` }}
                                                    />
                                                </div>
                                            </td>
                                            <td>{player.current_streak}</td>
                                            <td>{player.wins}/{player.draws}/{player.losses}</td>
                                            <td>{player.total_bets}</td>
                                            <td>{player.deposit_amount_formatted}</td>
                                            <td>{new Date(player.join_date).toLocaleDateString()}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default LeagueDetails;
