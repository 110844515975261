import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './AdminHeader.css';

function AdminHeader() {
  const location = useLocation();
  const navigate = useNavigate();
  const adminUsername = localStorage.getItem('adminUsername');

  const handleLogout = () => {
    localStorage.removeItem('adminId');
    localStorage.removeItem('adminUsername');
    localStorage.removeItem('adminRole');
    navigate('/admin/login');
  };

  const getPageTitle = () => {
    const path = location.pathname;
    if (path === '/admin' || path === '/admin/dashboard') return 'Dashboard';
    if (path.includes('challenge-system')) return 'Challenge System';
    if (path.includes('challenge-management')) return 'Challenge Management';
    if (path.includes('team-management')) return 'Team Management';
    if (path.includes('league-management')) return 'League Management';
    if (path.includes('users')) return 'User Management';
    if (path.includes('credit-user')) return 'Credit User';
    if (path.includes('debit-user')) return 'Debit User';
    if (path.includes('payment-methods')) return 'Payment Methods';
    if (path.includes('bets')) return 'Bet Management';
    if (path.includes('transactions')) return 'Transaction Management';
    if (path.includes('leaderboard')) return 'Leaderboard';
    if (path.includes('settings')) return 'System Settings';
    if (path.includes('reports')) return 'Reports & Analytics';
    return 'Admin Panel';
  };

  return (
    <header className="admin-header">
      <div className="header-content">
        <div className="header-left">
          <h1 className="page-title">{getPageTitle()}</h1>
        </div>
        <div className="header-right">
          <div className="admin-info">
            <span className="admin-name">
              <span className="admin-icon" role="img" aria-label="Admin user icon">
                👤
              </span>
              {adminUsername}
            </span>
          </div>
          <div className="header-actions">
            <button className="action-button notifications">
              <span className="action-icon" role="img" aria-label="Notifications">
                🔔
              </span>
            </button>
            <button className="action-button settings">
              <Link to="/admin/settings">
                <span className="action-icon" role="img" aria-label="Settings">
                  ⚙️
                </span>
              </Link>
            </button>
            <button onClick={handleLogout} className="action-button logout">
              <span className="action-icon" role="img" aria-label="Logout">
                🚪
              </span>
            </button>
          </div>
        </div>
      </div>
    </header>
  );
}

export default AdminHeader; 