import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './PaymentMethods.css';

const API_BASE_URL = '/backend';

function PaymentMethods() {
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [newMethod, setNewMethod] = useState({
    name: '',
    type: '',
    fields: [{ fieldName: '', fieldValue: '' }]
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [editingMethodId, setEditingMethodId] = useState(null);

  const paymentTypes = [
    { value: 'bank', label: 'Bank Account' },
    { value: 'crypto', label: 'Cryptocurrency' },
    { value: 'mobile_money', label: 'Mobile Money' },
    { value: 'paypal', label: 'PayPal' },
    { value: 'other', label: 'Other' }
  ];

  useEffect(() => {
    fetchPaymentMethods();
  }, []);

  const fetchPaymentMethods = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/handlers/payment_methods.php`);
      console.log('Payment methods response:', response.data);
      
      // Check for either success flag or status code
      if (response.data.success || response.data.status === 200) {
        if (!response.data.data) {
          console.warn('No payment methods data in response:', response.data);
        }
        setPaymentMethods(response.data.data || []);
      } else {
        console.error('Failed response:', response.data);
        setError('Failed to fetch payment methods: ' + response.data.message);
      }
    } catch (err) {
      console.error('Error fetching payment methods:', err);
      setError('Failed to fetch payment methods. Please try again later.');
    }
  };

  // Handle input change for the payment method name and type
  const handleBasicInputChange = (e) => {
    const { name, value } = e.target;
    setNewMethod(prev => ({
      ...prev,
      [name]: value
    }));
  };

  // Handle changes to field names and values
  const handleFieldChange = (index, key, value) => {
    setNewMethod(prev => {
      const updatedFields = [...prev.fields];
      updatedFields[index] = {
        ...updatedFields[index],
        [key]: value
      };
      return {
        ...prev,
        fields: updatedFields
      };
    });
  };

  // Add a new field
  const addField = () => {
    setNewMethod(prev => ({
      ...prev,
      fields: [...prev.fields, { fieldName: '', fieldValue: '' }]
    }));
  };

  // Remove a field
  const removeField = (index) => {
    setNewMethod(prev => ({
      ...prev,
      fields: prev.fields.filter((_, i) => i !== index)
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    if (!newMethod.name.trim()) {
      setError('Payment method name is required');
      return;
    }

    if (!newMethod.type) {
      setError('Payment method type is required');
      return;
    }

    // Validate fields
    const invalidFields = newMethod.fields.some(field => 
      !field.fieldName.trim() || !field.fieldValue.trim()
    );

    if (invalidFields) {
      setError('All fields must have both name and value');
      return;
    }

    try {
      const response = await axios.post(`${API_BASE_URL}/handlers/payment_methods.php`, newMethod);
      if (response.data.success) {
        setSuccess('Payment method added successfully!');
        fetchPaymentMethods();
        setNewMethod({
          name: '',
          type: '',
          fields: [{ fieldName: '', fieldValue: '' }]
        });
      } else {
        setError(response.data.message || 'Failed to add payment method');
      }
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to add payment method');
      console.error('Error details:', err.response?.data);
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(`${API_BASE_URL}/handlers/payment_methods.php?id=${id}`);
      if (response.data.success) {
        fetchPaymentMethods();
        setSuccess('Payment method deleted successfully!');
      }
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to delete payment method');
      console.error('Delete error:', err.response?.data);
    }
  };

  const handleEdit = (method) => {
    setEditingMethodId(method.id);
    setNewMethod({
      name: method.name,
      type: method.type,
      fields: Array.isArray(method.fields) && method.fields.length > 0 
        ? method.fields 
        : [{ fieldName: '', fieldValue: '' }]
    });
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(
        `${API_BASE_URL}/handlers/payment_methods.php?id=${editingMethodId}`,
        newMethod
      );
      if (response.data.success) {
        setSuccess('Payment method updated successfully!');
        fetchPaymentMethods();
        setEditingMethodId(null);
        setNewMethod({
          name: '',
          type: '',
          fields: [{ fieldName: '', fieldValue: '' }]
        });
      } else {
        setError(response.data.message || 'Failed to update payment method');
      }
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to update payment method');
    }
  };

  return (
    <div className="payment-methods-container">
      <div className="payment-methods-content">
        <div className="payment-methods-card">
          <h2>{editingMethodId ? 'Edit Payment Method' : 'Add New Payment Method'}</h2>
          {error && <div className="error-message">{error}</div>}
          {success && <div className="success-message">{success}</div>}
          
          <form onSubmit={editingMethodId ? handleUpdate : handleSubmit} className="payment-form">
            <div className="form-group">
              <label>Payment Method Name:</label>
              <input
                type="text"
                name="name"
                value={newMethod.name}
                onChange={handleBasicInputChange}
                placeholder="Enter payment method name"
                className="form-input"
                required
              />
            </div>

            <div className="form-group">
              <label>Payment Method Type:</label>
              <select
                name="type"
                value={newMethod.type}
                onChange={handleBasicInputChange}
                className="form-input"
                required
              >
                <option value="">Select a type</option>
                {paymentTypes.map(type => (
                  <option key={type.value} value={type.value}>
                    {type.label}
                  </option>
                ))}
              </select>
            </div>

            {newMethod.fields.map((field, index) => (
              <div key={index} className="field-group">
                <div className="field-inputs">
                  <input
                    type="text"
                    value={field.fieldName}
                    onChange={(e) => handleFieldChange(index, 'fieldName', e.target.value)}
                    placeholder="Field Name (e.g., Email, Account Number)"
                    className="form-input"
                    required
                  />
                  <input
                    type="text"
                    value={field.fieldValue}
                    onChange={(e) => handleFieldChange(index, 'fieldValue', e.target.value)}
                    placeholder="Field Value"
                    className="form-input"
                    required
                  />
                </div>
                <button 
                  type="button" 
                  onClick={() => removeField(index)}
                  className="remove-field-button"
                  disabled={newMethod.fields.length === 1}
                >
                  Remove Field
                </button>
              </div>
            ))}

            <button type="button" onClick={addField} className="add-field-button">
              Add Field
            </button>

            <button type="submit" className="submit-button">
              {editingMethodId ? 'Update Payment Method' : 'Add Payment Method'}
            </button>
          </form>
        </div>

        <div className="payment-methods-card">
          <h2>Existing Payment Methods</h2>
          <div className="payment-methods-list">
            {paymentMethods.map(method => (
              <div key={method.id} className="payment-method-item">
                <h3>{method.name}</h3>
                <div className="payment-type">{paymentTypes.find(t => t.value === method.type)?.label || method.type}</div>
                <div className="field-list">
                  {Array.isArray(method.fields) ? (
                    method.fields.map((field, index) => (
                      <div key={index} className="field-item">
                        <strong>{field.fieldName}:</strong> {field.fieldValue}
                      </div>
                    ))
                  ) : (
                    <div className="field-item">No fields available</div>
                  )}
                </div>
                <div className="action-buttons">
                  <button onClick={() => handleEdit(method)} className="edit-button">
                    Edit
                  </button>
                  <button onClick={() => handleDelete(method.id)} className="delete-button">
                    Delete
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentMethods;