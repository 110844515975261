// API Configuration
const isDevelopment = process.env.NODE_ENV === 'development' || 
                     window.location.hostname === 'localhost' || 
                     window.location.hostname === '127.0.0.1';

// Fallback API URL if environment variable is not set
const defaultApiUrl = isDevelopment ? '/FanBet247/backend' : window.location.origin + '/FanBet247/backend';

// Use environment variable if available, otherwise use default
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || defaultApiUrl;

// Asset URLs with environment variable support
export const getAssetUrl = (path) => {
    const baseUrl = process.env.REACT_APP_ASSET_BASE_URL || window.location.origin;
    return baseUrl + path;
};