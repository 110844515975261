import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import './RecentBets.css';
import { FaEye } from 'react-icons/fa';

const API_BASE_URL = '/backend';
const ITEMS_PER_PAGE = 100;

function RecentBets() {
    const navigate = useNavigate();
    const [bets, setBets] = useState([]);
    const [teams, setTeams] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [filter, setFilter] = useState('all');
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [showBetDetailsModal, setShowBetDetailsModal] = useState(false);
    const [selectedBet, setSelectedBet] = useState(null);

    useEffect(() => {
        const userId = localStorage.getItem('userId');
        if (!userId) {
            navigate('/user/login');
            return;
        }

        const initializePage = async () => {
            try {
                setError(null);
                await Promise.all([
                    fetchUserBets(),
                    fetchTeams()
                ]);
            } catch (err) {
                console.error('Page initialization error:', err);
                setError('Failed to load bets. Please try again later.');
            } finally {
                setLoading(false);
            }
        };

        initializePage();
    }, [navigate]);

    const fetchTeams = async () => {
        try {
            console.log('Fetching teams...');
            const response = await axios.get(`${API_BASE_URL}/handlers/team_management.php`);
            console.log('Teams response:', response.data);
            
            if (response.data.status === 200) {
                setTeams(response.data.data || []);
            } else {
                console.error('Failed to fetch teams:', response.data.message);
                throw new Error(response.data.message || 'Failed to fetch teams');
            }
        } catch (err) {
            console.error('Error fetching teams:', err);
            throw err;
        }
    };

    const getTeamLogo = (teamName) => {
        const team = teams.find(team => team.name === teamName);
        return team ? `${API_BASE_URL}/${team.logo}` : '';
    };

    const fetchUserBets = async () => {
        try {
            console.log('Fetching user bets...');
            const userId = localStorage.getItem('userId');
            const response = await axios.get(`${API_BASE_URL}/handlers/get_all_user_bets.php?userId=${userId}`);
            console.log('User bets response:', response.data);
            
            if (response.data.success) {
                setBets(response.data.bets || []);
            } else {
                console.error('Failed to fetch user bets:', response.data.message);
                throw new Error(response.data.message || 'Failed to fetch user bets');
            }
        } catch (error) {
            console.error('Error fetching user bets:', error);
            throw error;
        }
    };

    const getBetChoice = (bet) => {
        const choice = bet.bet_choice_user1;
        if (choice === 'team_a_win') return bet.team_a;
        if (choice === 'team_b_win') return bet.team_b;
        return 'Draw';
    };

    const formatDate = (dateString) => {
        if (!dateString) return 'N/A';
        const date = new Date(dateString);
        return date.toLocaleString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        });
    };

    const handleShowBetDetails = (bet) => {
        setSelectedBet(bet);
        setShowBetDetailsModal(true);
    };

    const filteredBets = bets
        .filter(bet => {
            if (searchTerm) {
                const searchLower = searchTerm.toLowerCase();
                return bet.team_a?.toLowerCase().includes(searchLower) ||
                       bet.team_b?.toLowerCase().includes(searchLower);
            }
            return true;
        })
        .filter(bet => {
            switch (filter) {
                case 'won':
                    return bet.bet_status === 'won';
                case 'lost':
                    return bet.bet_status === 'lost';
                case 'pending':
                    return bet.bet_status === 'pending';
                default:
                    return true;
            }
        })
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

    // Pagination calculations
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const paginatedBets = filteredBets.slice(startIndex, startIndex + ITEMS_PER_PAGE);
    const totalPages = Math.ceil(filteredBets.length / ITEMS_PER_PAGE);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
        window.scrollTo(0, 0);
    };

    if (loading) {
        return <div className="loading">Loading...</div>;
    }

    if (error) {
        return <div className="error-message">{error}</div>;
    }

    return (
        <div className="recent-bets-page">
            <div className="recent-bets-header">
                <h1>Recent Bets</h1>
                <div className="recent-bets-controls">
                    <div className="search-box">
                        <input
                            type="text"
                            placeholder="Search teams..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </div>
                    <div className="filter-buttons">
                        <button 
                            className={`filter-button ${filter === 'all' ? 'active' : ''}`}
                            onClick={() => setFilter('all')}
                        >
                            All
                        </button>
                        <button 
                            className={`filter-button ${filter === 'won' ? 'active' : ''}`}
                            onClick={() => setFilter('won')}
                        >
                            Won
                        </button>
                        <button 
                            className={`filter-button ${filter === 'lost' ? 'active' : ''}`}
                            onClick={() => setFilter('lost')}
                        >
                            Lost
                        </button>
                        <button 
                            className={`filter-button ${filter === 'pending' ? 'active' : ''}`}
                            onClick={() => setFilter('pending')}
                        >
                            Pending
                        </button>
                    </div>
                </div>
            </div>

            <div className="table-container">
                <table className="recent-bets-table">
                    <thead>
                        <tr>
                            <th className="number-column">#</th>
                            <th>Teams</th>
                            <th>Details</th>
                            <th>Returns</th>
                            <th className="actions-column">Status & Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {paginatedBets.length === 0 ? (
                            <tr>
                                <td colSpan={5} className="no-data">No bets found</td>
                            </tr>
                        ) : (
                            paginatedBets.map((bet, index) => (
                                <tr key={bet.bet_id}>
                                    <td className="number-column">{startIndex + index + 1}</td>
                                    <td className="teams-cell">
                                        <div className="match-date">{formatDate(bet.created_at)}</div>
                                        <div className="recent-bets-teams-row">
                                            <div className="recent-bets-team">
                                                <div className="recent-bets-team-details">
                                                    <img src={getTeamLogo(bet.team_a)} alt={bet.team_a} className="recent-bets-team-logo" />
                                                    <span className="recent-bets-team-name">{bet.team_a}</span>
                                                </div>
                                                <div className="recent-bets-user-info">
                                                    <Link to={`/user/profile/${bet.user1_id}`} className="bet-username">{bet.user1_name}</Link>
                                                    <span className="bet-amount">{bet.amount_user1}</span>
                                                </div>
                                            </div>
                                            <span className="recent-bets-vs">VS</span>
                                            <div className="recent-bets-team">
                                                <div className="recent-bets-team-details">
                                                    <img src={getTeamLogo(bet.team_b)} alt={bet.team_b} className="recent-bets-team-logo" />
                                                    <span className="recent-bets-team-name">{bet.team_b}</span>
                                                </div>
                                                <div className="recent-bets-user-info">
                                                    {bet.user2_name ? (
                                                        <Link to={`/user/profile/${bet.user2_id}`} className="bet-username">{bet.user2_name}</Link>
                                                    ) : (
                                                        <span className="bet-username">Waiting for opponent</span>
                                                    )}
                                                    <span className="bet-amount">{bet.amount_user2 || '-'}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="bet-details-compact">
                                        <div className="bet-details-stack">
                                            <span className="reference-code clickable">
                                                {bet.unique_code?.toUpperCase() || `${bet.bet_id}DNRBKCC`}
                                            </span>
                                            <span className="bet-choice">
                                                Choice: {getBetChoice(bet)}
                                            </span>
                                        </div>
                                    </td>
                                    <td className="returns-compact">
                                        <div>Win: {bet.potential_return_win_user1}</div>
                                        <div>Draw: {bet.potential_return_draw_user1}</div>
                                        <div>Loss: {bet.potential_return_loss_user1}</div>
                                    </td>
                                    <td className="actions-column">
                                        <div className="status-actions">
                                            <span className={`status-badge ${bet.bet_status?.toLowerCase()}`}>
                                                {bet.bet_status}
                                            </span>
                                            <button
                                                className="view-details-btn"
                                                onClick={() => handleShowBetDetails(bet)}
                                                title="View Details"
                                            >
                                                <FaEye />
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            </div>

            {totalPages > 1 && (
                <div className="pagination">
                    <button
                        className="pagination-button nav"
                        onClick={() => handlePageChange(1)}
                        disabled={currentPage === 1}
                    >
                        &lt;&lt;
                    </button>
                    <button
                        className="pagination-button nav"
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                    >
                        &lt;
                    </button>
                    
                    {Array.from({ length: totalPages }, (_, i) => i + 1)
                        .filter(page => {
                            const distance = Math.abs(page - currentPage);
                            return distance === 0 || distance === 1 || page === 1 || page === totalPages;
                        })
                        .map((page, index, array) => {
                            if (index > 0 && array[index - 1] !== page - 1) {
                                return [
                                    <span key={`ellipsis-${page}`} className="pagination-ellipsis">...</span>,
                                    <button
                                        key={page}
                                        className={`pagination-button ${currentPage === page ? 'active' : ''}`}
                                        onClick={() => handlePageChange(page)}
                                    >
                                        {page}
                                    </button>
                                ];
                            }
                            return (
                                <button
                                    key={page}
                                    className={`pagination-button ${currentPage === page ? 'active' : ''}`}
                                    onClick={() => handlePageChange(page)}
                                >
                                    {page}
                                </button>
                            );
                        })}
                    
                    <button
                        className="pagination-button nav"
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                    >
                        &gt;
                    </button>
                    <button
                        className="pagination-button nav"
                        onClick={() => handlePageChange(totalPages)}
                        disabled={currentPage === totalPages}
                    >
                        &gt;&gt;
                    </button>
                </div>
            )}
            {/* Bet Details Modal */}
            {showBetDetailsModal && selectedBet && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3>Bet Details</h3>
                            <button className="close-btn" onClick={() => setShowBetDetailsModal(false)}>×</button>
                        </div>
                        <div className="modal-body">
                            <div className="bet-section">
                                <div className="section-title">MATCH DETAILS</div>
                                <div className="match-grid">
                                    <div className="team-card">
                                        <div className="team-header">
                                            <img src={getTeamLogo(selectedBet.team_a)} alt={selectedBet.team_a} className="team-logo" />
                                            <span className="team-name">{selectedBet.team_a}</span>
                                            {selectedBet.bet_choice_user1 === 'team_a_win' && <span className="pick-badge">Your Pick</span>}
                                        </div>
                                        <div className="team-odds">
                                            <span className="odds-label">Win Odds</span>
                                            <span className="odds-value">{selectedBet.odds_team_a}x</span>
                                        </div>
                                        <div className="user-bet-info">
                                            <span className="username">{selectedBet.user1_name}</span>
                                            <span className="bet-amount">{selectedBet.amount_user1}</span>
                                        </div>
                                    </div>

                                    <div className="vs-container">
                                        <div className="vs">VS</div>
                                        <div className="draw-odds">
                                            <span className="odds-label">Draw</span>
                                            <span className="odds-value">2.0x</span>
                                        </div>
                                        <div className="match-date">
                                            {formatDate(selectedBet.created_at)}
                                        </div>
                                    </div>

                                    <div className="team-card">
                                        <div className="team-header">
                                            <img src={getTeamLogo(selectedBet.team_b)} alt={selectedBet.team_b} className="team-logo" />
                                            <span className="team-name">{selectedBet.team_b}</span>
                                            {selectedBet.bet_choice_user1 === 'team_b_win' && <span className="pick-badge">Your Pick</span>}
                                        </div>
                                        <div className="team-odds">
                                            <span className="odds-label">Win Odds</span>
                                            <span className="odds-value">{selectedBet.odds_team_b}x</span>
                                        </div>
                                        <div className="user-bet-info">
                                            <span className="username">{selectedBet.user2_name || 'Waiting for opponent'}</span>
                                            <span className="bet-amount">{selectedBet.amount_user2 || '-'}</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="section-title">FINANCIAL DETAILS</div>
                                <div className="financial-grid">
                                    <div className="financial-item">
                                        <span className="financial-label">Your Bet</span>
                                        <span className="financial-value">{selectedBet.amount_user1}</span>
                                    </div>
                                    <div className="financial-item">
                                        <span className="financial-label">Potential Win</span>
                                        <span className="financial-value win">+{selectedBet.potential_return_win_user1}</span>
                                    </div>
                                    <div className="financial-item">
                                        <span className="financial-label">Potential Loss</span>
                                        <span className="financial-value loss">-{selectedBet.potential_return_loss_user1}</span>
                                    </div>
                                    <div className="financial-item">
                                        <span className="financial-label">Draw Return</span>
                                        <span className="financial-value draw">{selectedBet.potential_return_draw_user1}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default RecentBets;
