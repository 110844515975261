import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import Countdown from 'react-countdown';
import './Challenges.css';

const API_BASE_URL = '/backend';
const ITEMS_PER_PAGE = 20;

function Challenges() {
    const navigate = useNavigate();
    const [challenges, setChallenges] = useState([]);
    const [teams, setTeams] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [filter, setFilter] = useState('all'); 
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        const userId = localStorage.getItem('userId');
        if (!userId) {
            navigate('/user/login');
            return;
        }

        const initializePage = async () => {
            try {
                setError(null);
                await Promise.all([
                    fetchChallenges(),
                    fetchTeams()
                ]);
            } catch (err) {
                console.error('Page initialization error:', err);
                setError('Failed to load challenges. Please try again later.');
            } finally {
                setLoading(false);
            }
        };

        initializePage();
    }, [navigate]);

    const fetchChallenges = async () => {
        try {
            console.log('Fetching challenges...');
            const response = await axios.get(`${API_BASE_URL}/handlers/challenge_management.php`);
            console.log('Challenges response:', response.data);
            
            if (response.data.success) {
                const challengesWithDate = (response.data.challenges || []).sort((a, b) => {
                    // Sort by status priority and then by date
                    const statusPriority = { 'Open': 1, 'Closed': 2, 'Settled': 3, 'Expired': 4 };
                    if (statusPriority[a.status] !== statusPriority[b.status]) {
                        return statusPriority[a.status] - statusPriority[b.status];
                    }
                    return new Date(b.match_date) - new Date(a.match_date);
                }).map(challenge => ({
                    ...challenge,
                    end_time: new Date(challenge.end_time)
                }));
                setChallenges(challengesWithDate);
            } else {
                console.error('Failed to fetch challenges:', response.data.message);
                throw new Error(response.data.message || 'Failed to fetch challenges');
            }
        } catch (error) {
            console.error('Error fetching challenges:', error);
            throw error;
        }
    };

    const fetchTeams = async () => {
        try {
            console.log('Fetching teams...');
            const response = await axios.get(`${API_BASE_URL}/handlers/team_management.php`);
            console.log('Teams response:', response.data);
            
            if (response.data.status === 200) {
                setTeams(response.data.data || []);
            } else {
                console.error('Failed to fetch teams:', response.data.message);
                throw new Error(response.data.message || 'Failed to fetch teams');
            }
        } catch (err) {
            console.error('Error fetching teams:', err);
            throw err;
        }
    };

    const getTeamLogo = (teamName) => {
        const team = teams.find(team => team.name === teamName);
        return team ? `${API_BASE_URL}/${team.logo}` : '';
    };

    const countdownRenderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            return <span className="challenges-countdown-compact">Expired</span>;
        }

        let display = '';
        if (days > 0) display += `${days}d `;
        if (hours > 0) display += `${hours}h `;
        if (minutes > 0) display += `${minutes}m `;
        display += `${seconds}s`;

        return <span className="challenges-countdown-compact">{display}</span>;
    };

    const filteredChallenges = challenges
        .filter(challenge => {
            if (searchTerm) {
                const searchLower = searchTerm.toLowerCase();
                return challenge.team_a.toLowerCase().includes(searchLower) ||
                       challenge.team_b.toLowerCase().includes(searchLower);
            }
            return true;
        })
        .filter(challenge => {
            const now = new Date();
            const matchDate = new Date(challenge.match_date);
            const endTime = new Date(challenge.end_time);
            
            switch (filter) {
                case 'closed':
                    return challenge.status === 'Closed';
                case 'expired':
                    return now > endTime && challenge.status !== 'Settled';
                case 'settled':
                    return challenge.status === 'Settled';
                default:
                    return true;
            }
        })
        .sort((a, b) => {
            const now = new Date();
            const aMatchDate = new Date(a.match_date);
            const bMatchDate = new Date(b.match_date);
            const aEndTime = new Date(a.end_time);
            const bEndTime = new Date(b.end_time);

            // Sort based on filter type
            if (filter === 'closed') {
                return bMatchDate - aMatchDate; // Most recent matches first
            } else if (filter === 'expired') {
                return bEndTime - aEndTime; // Most recently expired first
            } else if (filter === 'settled') {
                return bMatchDate - aMatchDate; // Most recent matches first
            }

            // For 'all' filter, sort by status and then date
            const statusPriority = {
                'Open': 1,
                'Closed': 2,
                'Expired': 3,
                'Settled': 4
            };

            if (statusPriority[a.status] !== statusPriority[b.status]) {
                return statusPriority[a.status] - statusPriority[b.status];
            }

            // If same status, sort by appropriate date
            if (a.status === 'Expired') {
                return bEndTime - aEndTime; // Most recently expired first
            } else {
                return aMatchDate - bMatchDate; // Upcoming matches first
            }
        });

    // Pagination calculations
    const totalItems = filteredChallenges.length;
    const totalPages = Math.ceil(totalItems / ITEMS_PER_PAGE);
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const paginatedChallenges = filteredChallenges.slice(startIndex, startIndex + ITEMS_PER_PAGE);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        window.scrollTo(0, 0);
    };

    const renderPagination = () => {
        const pages = [];
        const maxVisiblePages = 5;
        let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
        let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

        if (endPage - startPage + 1 < maxVisiblePages) {
            startPage = Math.max(1, endPage - maxVisiblePages + 1);
        }

        if (startPage > 1) {
            pages.push(
                <button key="first" onClick={() => handlePageChange(1)} className="pagination-button">
                    1
                </button>
            );
            if (startPage > 2) {
                pages.push(<span key="ellipsis1" className="pagination-ellipsis">...</span>);
            }
        }

        for (let i = startPage; i <= endPage; i++) {
            pages.push(
                <button
                    key={i}
                    onClick={() => handlePageChange(i)}
                    className={`pagination-button ${currentPage === i ? 'active' : ''}`}
                >
                    {i}
                </button>
            );
        }

        if (endPage < totalPages) {
            if (endPage < totalPages - 1) {
                pages.push(<span key="ellipsis2" className="pagination-ellipsis">...</span>);
            }
            pages.push(
                <button
                    key="last"
                    onClick={() => handlePageChange(totalPages)}
                    className="pagination-button"
                >
                    {totalPages}
                </button>
            );
        }

        return (
            <div className="pagination">
                <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    className="pagination-button nav"
                >
                    ←
                </button>
                {pages}
                <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    className="pagination-button nav"
                >
                    →
                </button>
            </div>
        );
    };

    if (error) {
        return (
            <div className="challenges-page">
                <div className="error-message">{error}</div>
            </div>
        );
    }

    if (loading) {
        return (
            <div className="challenges-page">
                <div className="loading">Loading challenges...</div>
            </div>
        );
    }

    return (
        <div className="challenges-page">
            <div className="challenges-header">
                <h1>All Challenges</h1>
                <div className="challenges-controls">
                    <div className="search-box">
                        <input
                            type="text"
                            placeholder="Search teams..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </div>
                    <div className="filter-buttons">
                        <button 
                            className={`filter-button ${filter === 'all' ? 'active' : ''}`}
                            onClick={() => setFilter('all')}
                        >
                            All
                        </button>
                        <button 
                            className={`filter-button ${filter === 'closed' ? 'active' : ''}`}
                            onClick={() => setFilter('closed')}
                        >
                            Closed
                        </button>
                        <button 
                            className={`filter-button ${filter === 'expired' ? 'active' : ''}`}
                            onClick={() => setFilter('expired')}
                        >
                            Expired
                        </button>
                        <button 
                            className={`filter-button ${filter === 'settled' ? 'active' : ''}`}
                            onClick={() => setFilter('settled')}
                        >
                            Settled
                        </button>
                    </div>
                </div>
            </div>

            <div className="challenges-list">
                {filteredChallenges.length === 0 ? (
                    <div className="no-data">No challenges found</div>
                ) : (
                    <>
                        <div className="table-container">
                            <table>
                                <thead>
                                    <tr>
                                        <th className="number-column">#</th>
                                        <th>Teams</th>
                                        <th>Odds</th>
                                        <th>Goals</th>
                                        <th>Date</th>
                                        <th>Time Left</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {paginatedChallenges.map((challenge, index) => (
                                        <tr key={challenge.challenge_id}>
                                            <td className="number-column">{startIndex + index + 1}</td>
                                            <td>
                                                <div className="challenges-teams-compact">
                                                    <div className="challenges-team-info">
                                                        <img 
                                                            src={getTeamLogo(challenge.team_a)}
                                                            alt={challenge.team_a}
                                                            className="challenges-team-logo-small"
                                                            onError={(e) => {
                                                                e.target.src = '/default-team-logo.png';
                                                            }}
                                                        />
                                                        <span className="challenges-team-name-compact">{challenge.team_a}</span>
                                                    </div>
                                                    <span className="challenges-vs-small">vs</span>
                                                    <div className="challenges-team-info">
                                                        <img 
                                                            src={getTeamLogo(challenge.team_b)}
                                                            alt={challenge.team_b}
                                                            className="challenges-team-logo-small"
                                                            onError={(e) => {
                                                                e.target.src = '/default-team-logo.png';
                                                            }}
                                                        />
                                                        <span className="challenges-team-name-compact">{challenge.team_b}</span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="challenges-odds-compact">{challenge.odds_team_a} - {challenge.odds_team_b}</td>
                                            <td className="challenges-goals-compact">+{challenge.team_a_goal_advantage} / +{challenge.team_b_goal_advantage}</td>
                                            <td className="challenges-date-compact">{new Date(challenge.match_date).toLocaleDateString()}</td>
                                            <td>
                                                <Countdown 
                                                    date={new Date(challenge.end_time)}
                                                    renderer={countdownRenderer}
                                                    onComplete={() => {}}
                                                />
                                            </td>
                                            <td>
                                                <span className={`status-badge ${challenge.status.toLowerCase()}`}>
                                                    {challenge.status}
                                                </span>
                                            </td>
                                            <td>
                                                {challenge.status === 'Open' && (
                                                    <Link to={`/user/join-challenge/${challenge.challenge_id}`} className="challenges-place-bet-button-small">
                                                        Bet
                                                    </Link>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        {totalPages > 1 && renderPagination()}
                    </>
                )}
            </div>
        </div>
    );
}

export default Challenges;
