import React, { useState, useEffect, useRef } from 'react';
import axios from '../../utils/axiosConfig';
import { API_BASE_URL } from '../../config';
import { FaPaperPlane, FaTimes, FaInbox, FaArrowLeft, FaPaperPlane as FaSent, FaComments } from 'react-icons/fa';
import './Messages.css';

const Messages = () => {
    const [activeTab, setActiveTab] = useState('inbox');
    const [conversations, setConversations] = useState([]);
    const [selectedConversation, setSelectedConversation] = useState(null);
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const messagesEndRef = useRef(null);
    const currentUserId = localStorage.getItem('userId');
    const currentUsername = localStorage.getItem('username');

    useEffect(() => {
        fetchConversations();
        const interval = setInterval(fetchConversations, 30000);
        return () => clearInterval(interval);
    }, [activeTab]);

    useEffect(() => {
        if (selectedConversation) {
            fetchMessages(selectedConversation.user_id);
            markMessagesAsRead(selectedConversation.user_id);
            const interval = setInterval(() => fetchMessages(selectedConversation.user_id), 5000);
            return () => clearInterval(interval);
        }
    }, [selectedConversation]);

    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages]);

    const handleDeleteConversation = async () => {
        try {
            const response = await axios.post('/backend/handlers/messages.php', {
                action: 'delete_conversation',
                user_id: currentUserId,
                other_user_id: selectedConversation.user_id
            });
            
            if (response.data.success) {
                setSelectedConversation(null);
                fetchConversations();
                setShowDeleteConfirm(false);
            }
        } catch (error) {
            console.error('Error deleting conversation:', error);
        }
    };

    const fetchConversations = async () => {
        try {
            const response = await axios.get('/backend/handlers/messages.php', {
                params: {
                    user_id: currentUserId,
                    type: activeTab
                }
            });
            if (response.data.success) {
                setConversations(response.data.conversations || []);
            }
        } catch (error) {
            console.error('Error fetching conversations:', error);
        }
    };

    const fetchMessages = async (otherUserId) => {
        try {
            const response = await axios.get('/backend/handlers/messages.php', {
                params: {
                    user_id: currentUserId,
                    other_user_id: otherUserId,
                    type: 'conversation'
                }
            });
            if (response.data.success) {
                setMessages(response.data.messages || []);
            }
        } catch (error) {
            console.error('Error fetching messages:', error);
        }
    };

    const markMessagesAsRead = async (otherUserId) => {
        try {
            await axios.post('/backend/handlers/messages.php', {
                action: 'mark_read',
                recipient_id: currentUserId,
                sender_id: otherUserId
            });
        } catch (error) {
            console.error('Error marking messages as read:', error);
        }
    };

    const handleSendMessage = async (e) => {
        e.preventDefault();
        if (!newMessage.trim() || !selectedConversation) return;

        setIsLoading(true);
        try {
            const response = await axios.post('/backend/handlers/messages.php', {
                sender_id: currentUserId,
                recipient_id: selectedConversation.user_id,
                content: newMessage.trim()
            });

            if (response.data.success) {
                setNewMessage('');
                fetchMessages(selectedConversation.user_id);
            } else {
                setError(response.data.message || 'Failed to send message');
            }
        } catch (error) {
            console.error('Error sending message:', error);
            setError('Failed to send message. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleConversationSelect = (conv) => {
        setSelectedConversation(conv);
        markMessagesAsRead(conv.user_id);
    };

    const formatMessageDate = (date) => {
        const messageDate = new Date(date);
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(yesterday.getDate() - 1);

        if (messageDate.toDateString() === today.toDateString()) {
            return 'Today';
        } else if (messageDate.toDateString() === yesterday.toDateString()) {
            return 'Yesterday';
        } else {
            return messageDate.toLocaleDateString();
        }
    };

    const formatMessageTime = (date) => {
        return new Date(date).toLocaleTimeString([], { 
            hour: '2-digit', 
            minute: '2-digit',
            hour12: true 
        });
    };

    const groupMessagesByDateAndUser = (messages) => {
        const groups = {};
        let currentDate = null;
        let currentGroup = [];
        let lastSenderId = null;

        messages.forEach((message) => {
            const messageDate = formatMessageDate(message.created_at);
            
            // Start new group if date changes or sender changes
            if (messageDate !== currentDate || message.sender_id !== lastSenderId) {
                if (currentGroup.length > 0) {
                    if (!groups[currentDate]) {
                        groups[currentDate] = [];
                    }
                    groups[currentDate].push(currentGroup);
                }
                currentGroup = [message];
                currentDate = messageDate;
            } else {
                currentGroup.push(message);
            }
            
            lastSenderId = message.sender_id;
        });

        // Add the last group
        if (currentGroup.length > 0 && currentDate) {
            if (!groups[currentDate]) {
                groups[currentDate] = [];
            }
            groups[currentDate].push(currentGroup);
        }

        return groups;
    };

    const renderMessages = () => {
        const groupedMessages = groupMessagesByDateAndUser(messages);
        
        return Object.entries(groupedMessages).map(([date, dateGroups]) => (
            <React.Fragment key={date}>
                <div className="message-date-divider">
                    <span className="message-date">{date}</span>
                </div>
                {dateGroups.map((group, groupIndex) => (
                    <div key={`${date}-${groupIndex}`} className="message-group">
                        {group.map((message, messageIndex) => {
                            const isSent = message.sender_id === currentUserId;
                            const username = isSent ? currentUsername : message.sender_username;
                            const showUsername = messageIndex === 0;
                            const isUnread = !message.is_read && !isSent;

                            return (
                                <div 
                                    key={message.id || messageIndex} 
                                    className={`message ${message.message_type} ${isUnread ? 'unread' : ''}`}
                                >
                                    {showUsername && (
                                        <div className="message-user-info">
                                            <div className="message-username">
                                                <span className="username-text">
                                                    {username.toUpperCase()}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                    <div className="message-content">
                                        <div className="message-bubble">
                                            {message.content}
                                        </div>
                                        <div className="message-time">
                                            {formatMessageTime(message.created_at)}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                ))}
                <div ref={messagesEndRef} />
            </React.Fragment>
        ));
    };

    const renderConversationsList = () => (
        <div className="conversations-list">
            {conversations.map((conv) => (
                <div
                    key={conv.user_id}
                    className={`conversation-item ${selectedConversation?.user_id === conv.user_id ? 'active' : ''} ${!conv.is_read ? 'unread' : ''}`}
                    onClick={() => handleConversationSelect(conv)}
                >
                    <div className="avatar">
                        {conv.username[0].toUpperCase()}
                    </div>
                    <div className="conversation-info">
                        <div className="conversation-name">
                            {conv.username}
                            {!conv.is_read && <span className="unread-indicator" />}
                        </div>
                        <div className="conversation-preview">
                            {conv.last_message}
                        </div>
                        <div className="conversation-time">
                            {formatMessageTime(conv.last_message_time)}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );

    return (
        <div className="messages-container">
            <div className={`messages-sidebar ${!selectedConversation ? 'active' : ''}`}>
                <div className="messages-header">
                    <h2>Messages</h2>
                </div>
                <div className="messages-tabs">
                    <div 
                        className={`tab ${activeTab === 'inbox' ? 'active' : ''}`}
                        onClick={() => setActiveTab('inbox')}
                    >
                        <FaInbox /> Inbox
                    </div>
                    <div 
                        className={`tab ${activeTab === 'sent' ? 'active' : ''}`}
                        onClick={() => setActiveTab('sent')}
                    >
                        <FaSent /> Sent
                    </div>
                </div>
                <div className="conversations-list">
                    {renderConversationsList()}
                </div>
            </div>

            <div className="chat-area">
                {selectedConversation ? (
                    <>
                        <div className="chat-header">
                            <button 
                                className="back-button"
                                onClick={() => setSelectedConversation(null)}
                            >
                                <FaArrowLeft />
                            </button>
                            <div className="chat-title">
                                <div className="avatar">
                                    {selectedConversation.username[0].toUpperCase()}
                                </div>
                                <h3>Chat with {selectedConversation.username.charAt(0).toUpperCase() + selectedConversation.username.slice(1).toLowerCase()}</h3>
                            </div>
                            <button 
                                className="delete-button"
                                onClick={() => setShowDeleteConfirm(true)}
                            >
                                <FaTimes />
                            </button>
                        </div>

                        {showDeleteConfirm && (
                            <div className="delete-confirm">
                                <p>Are you sure you want to delete this conversation?</p>
                                <div className="delete-actions">
                                    <button onClick={handleDeleteConversation}>Yes, Delete</button>
                                    <button onClick={() => setShowDeleteConfirm(false)}>Cancel</button>
                                </div>
                            </div>
                        )}

                        <div className="messages-content">
                            {error && <div className="error-message">{error}</div>}
                            {renderMessages()}
                            <div ref={messagesEndRef} />
                        </div>

                        <form onSubmit={handleSendMessage} className="message-input-container">
                            <textarea
                                className="message-input"
                                value={newMessage}
                                onChange={(e) => setNewMessage(e.target.value)}
                                placeholder="Type a message..."
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter' && !e.shiftKey) {
                                        e.preventDefault();
                                        handleSendMessage(e);
                                    }
                                }}
                            />
                            <button 
                                type="submit" 
                                className="send-button"
                                disabled={isLoading || !newMessage.trim()}
                            >
                                <FaPaperPlane />
                            </button>
                        </form>
                    </>
                ) : (
                    <div className="no-conversation">
                        <FaComments />
                        <p>Select a conversation to start messaging</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Messages;
