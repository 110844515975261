import React from 'react';

function PaymentHistory() {
  return (
    <div>
      <h1>Payment History</h1>
      {/* Add payment history content here */}
    </div>
  );
}

export default PaymentHistory;
