import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Leaderboard.css';

const API_BASE_URL = '/backend';

const getRankIcon = (rank) => {
    switch(rank) {
        case 1:
            return '👑'; // Gold crown for 1st
        case 2:
            return '🥈'; // Silver medal for 2nd
        case 3:
            return '🥉'; // Bronze medal for 3rd
        case 4:
        case 5:
            return '🏆'; // Trophy for 4th and 5th
        case 6:
        case 7:
        case 8:
            return '⭐'; // Star for 6th to 8th
        case 9:
        case 10:
            return '🌟'; // Glowing star for 9th and 10th
        default:
            return null;
    }
};

function Leaderboard() {
    const [leaderboard, setLeaderboard] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [pagination, setPagination] = useState({
        current_page: 1,
        total_pages: 0,
        total_records: 0,
        records_per_page: 10
    });

    useEffect(() => {
        fetchLeaderboard();
    }, [currentPage]);

    const fetchLeaderboard = async () => {
        try {
            setLoading(true);
            setError(null);
            console.log('Fetching leaderboard data for page:', currentPage);
            
            const response = await axios.get(`${API_BASE_URL}/handlers/leaderboard.php`, {
                params: {
                    page: currentPage,
                    limit: 10
                }
            });
            
            console.log('Leaderboard API response:', response.data);

            if (response.data.success) {
                if (response.data.data.leaderboard.length === 0 && currentPage > 1) {
                    console.log('No data on current page, going back to previous page');
                    setCurrentPage(prev => Math.max(1, prev - 1));
                    return;
                }
                
                setLeaderboard(response.data.data.leaderboard);
                setPagination(response.data.data.pagination);
            } else {
                console.error('API returned error:', response.data.message);
                throw new Error(response.data.message || 'Failed to load leaderboard');
            }
        } catch (err) {
            console.error('Error details:', {
                message: err.message,
                response: err.response?.data,
                status: err.response?.status,
                statusText: err.response?.statusText
            });
            
            let errorMessage = 'Failed to load leaderboard data. ';
            
            if (err.response?.data?.message) {
                errorMessage += err.response.data.message;
            } else if (err.response?.status === 404) {
                errorMessage += 'API endpoint not found. Please check the server configuration.';
            } else if (err.response?.status === 500) {
                errorMessage += 'Server error occurred. Please try again later.';
            } else if (err.message === 'Network Error') {
                errorMessage += 'Network error. Please check your internet connection.';
            } else {
                errorMessage += err.message || 'An unexpected error occurred';
            }
            
            setError(errorMessage);
        } finally {
            setLoading(false);
        }
    };

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= pagination.total_pages) {
            console.log('Changing to page:', newPage);
            setCurrentPage(newPage);
        }
    };

    const handleRetry = () => {
        console.log('Retrying leaderboard fetch...');
        fetchLeaderboard();
    };

    if (loading) {
        return (
            <div className="leaderboard-loading">
                <div className="loading-spinner"></div>
                <p>Loading leaderboard data...</p>
            </div>
        );
    }

    if (error) {
        return (
            <div className="leaderboard-error">
                <p>{error}</p>
                <button onClick={handleRetry} className="retry-button">
                    Retry
                </button>
            </div>
        );
    }

    return (
        <div className="leaderboard-container">
            <h2>Top User Leaderboard</h2>
            
            {leaderboard.length === 0 ? (
                <div className="no-data">No players found in the leaderboard</div>
            ) : (
                <>
                    <div className="leaderboard-table">
                        <div className="leaderboard-header">
                            <div className="rank-column">RANK</div>
                            <div className="player-column">PLAYER</div>
                            <div className="points-column">POINTS</div>
                            <div className="stats-column">W/L</div>
                            <div className="bets-column">BETS</div>
                            <div className="challenges-column">CHALLENGES</div>
                            <div className="streak-column">STREAK</div>
                        </div>
                        
                        {leaderboard.map((player, index) => {
                            const rank = (pagination.current_page - 1) * pagination.records_per_page + index + 1;
                            const rankIcon = getRankIcon(rank);
                            const winRate = player.total_bets > 0 
                                ? Math.round((player.wins / player.total_bets) * 100) 
                                : 0;

                            return (
                                <div key={player.user_id} className="leaderboard-row">
                                    <div className="rank-column">
                                        {rankIcon && <span className="rank-icon">{rankIcon}</span>}
                                        <span className="rank-number">#{rank}</span>
                                    </div>
                                    <div className="player-column">
                                        <span className="player-name">{player.username}</span>
                                    </div>
                                    <div className="points-column">
                                        {player.points}
                                    </div>
                                    <div className="stats-column">
                                        <span className="wins">{player.wins}</span>
                                        <span className="separator">/</span>
                                        <span className="losses">{player.losses}</span>
                                        <span className="win-rate">({winRate}%)</span>
                                    </div>
                                    <div className="bets-column">
                                        {player.total_bets}
                                    </div>
                                    <div className="challenges-column">
                                        {player.total_challenges}
                                    </div>
                                    <div className="streak-column">
                                        <span className="current-streak">{player.current_streak}</span>
                                        <span className="highest-streak">({player.highest_streak})</span>
                                    </div>
                                </div>
                            );
                        })}
                    </div>

                    {pagination.total_pages > 1 && (
                        <div className="pagination-controls">
                            <button 
                                onClick={() => handlePageChange(currentPage - 1)}
                                disabled={currentPage === 1}
                                className="pagination-button"
                            >
                                Previous
                            </button>
                            <span className="page-info">
                                Page {currentPage} of {pagination.total_pages}
                            </span>
                            <button 
                                onClick={() => handlePageChange(currentPage + 1)}
                                disabled={currentPage === pagination.total_pages}
                                className="pagination-button"
                            >
                                Next
                            </button>
                        </div>
                    )}
                </>
            )}
        </div>
    );
}

export default Leaderboard;
