import React, { useState, useEffect } from 'react';
import axios from '../utils/axiosConfig';
import './TeamManagement.css';

const API_BASE_URL = '/backend';

function TeamManagement() {
  const [teams, setTeams] = useState([]);
  const [newTeam, setNewTeam] = useState({ name: '', logo: null });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [editingTeamId, setEditingTeamId] = useState(null);
  const [editingTeam, setEditingTeam] = useState({ name: '', logo: null });

  useEffect(() => {
    fetchTeams();
  }, []);

  const fetchTeams = async () => {
    try {
      const response = await axios.get('/backend/handlers/team_management.php');
      if (response.data.data) {
        setTeams(response.data.data);
      }
    } catch (err) {
      setError('Failed to fetch teams');
      setTeams([]);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type } = e.target;
    setNewTeam(prev => ({
      ...prev,
      [name]: type === 'file' ? e.target.files[0] : value
    }));
  };

  const handleEditInputChange = (e) => {
    const { name, value, type } = e.target;
    setEditingTeam(prev => ({
      ...prev,
      [name]: type === 'file' ? e.target.files[0] : value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    if (!newTeam.name || !newTeam.logo) {
      setError('Team name and logo are required.');
      return;
    }

    const allowedFileTypes = ['image/svg+xml', 'image/png'];
    if (!allowedFileTypes.includes(newTeam.logo.type)) {
      setError('Only SVG and PNG files are allowed.');
      return;
    }

    try {
      const formData = new FormData();
      for (const key in newTeam) {
        formData.append(key, newTeam[key]);
      }
      const response = await axios.post('/backend/handlers/team_management.php', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      if (response.data.data.success) {
        setSuccess('Team created successfully!');
        fetchTeams();
        setNewTeam({ name: '', logo: null });
      } else {
        setError(response.data.message || 'Failed to create team');
      }
    } catch (err) {
      console.error('Error:', err.response?.data || err.message);
      setError(err.response?.data?.message || 'Failed to create team');
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`/backend/handlers/team_management.php?id=${id}`);
      fetchTeams();
    } catch (err) {
      setError('Failed to delete team');
    }
  };

  const handleEdit = async (id) => {
    setEditingTeamId(id);
    const teamToEdit = teams.find(team => team.id === id);
    setEditingTeam({ name: teamToEdit.name, logo: teamToEdit.logo });
  };

  const handleUpdateTeam = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append('name', editingTeam.name);
      if (editingTeam.logo instanceof File) {
        formData.append('logo', editingTeam.logo);
      }

      const response = await axios.post(`/backend/handlers/update_team.php?id=${editingTeamId}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });

      if (response.data.data.success) {
        setSuccess('Team updated successfully!');
        fetchTeams();
        setEditingTeamId(null);
        setEditingTeam({ name: '', logo: null });
      } else {
        setError(response.data.message || 'Failed to update team');
      }
    } catch (err) {
      setError('Failed to update team');
      console.error(err);
    }
  };

  return (
    <div className="team-management-container">
      <div className="team-management-content">
        <div className="team-management-card">
          <h2>Add New Team</h2>
          {error && <div className="error-message">{error}</div>}
          {success && <div className="success-message">{success}</div>}
          <form onSubmit={handleSubmit} encType="multipart/form-data" className="team-form">
            <div className="form-group">
              <label htmlFor="name">Team Name:</label>
              <input 
                type="text" 
                id="name" 
                name="name" 
                value={newTeam.name} 
                onChange={handleInputChange} 
                required 
                className="form-input"
              />
            </div>
            <div className="form-group">
              <label htmlFor="logo">Team Logo:</label>
              <input 
                type="file" 
                id="logo" 
                name="logo" 
                onChange={handleInputChange} 
                accept=".svg,.png" 
                required 
                className="form-input"
              />
              <small className="file-hint">Only SVG and PNG files are allowed</small>
            </div>
            <button type="submit" className="submit-button">Add Team</button>
          </form>
        </div>

        <div className="team-management-card">
          <h2>Existing Teams</h2>
          <div className="teams-table-container">
            <table className="teams-table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Logo</th>
                  <th>Name</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {teams.map(team => (
                  <tr key={team.id}>
                    <td>{team.id}</td>
                    <td>
                      <img
                        src={`${API_BASE_URL}/${team.logo}`}
                        alt={team.name}
                        className="team-logo-image"
                      />
                    </td>
                    <td>{team.name}</td>
                    <td className="action-buttons">
                      <button onClick={() => handleDelete(team.id)} className="delete-button">Delete</button>
                      <button onClick={() => handleEdit(team.id)} className="edit-button">Edit</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {editingTeamId && (
          <div className="modal-overlay">
            <div className="modal-content">
              <h3>Edit Team</h3>
              <form onSubmit={handleUpdateTeam} encType="multipart/form-data" className="team-form">
                <div className="form-group">
                  <label htmlFor="editName">Team Name:</label>
                  <input
                    type="text"
                    id="editName"
                    name="name"
                    value={editingTeam.name}
                    onChange={handleEditInputChange}
                    required
                    className="form-input"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="editLogo">Team Logo:</label>
                  <input
                    type="file"
                    id="editLogo"
                    name="logo"
                    onChange={handleEditInputChange}
                    accept=".svg,.png"
                    className="form-input"
                  />
                  <small className="file-hint">Only SVG and PNG files are allowed</small>
                </div>
                <div className="modal-buttons">
                  <button type="submit" className="update-button">Update Team</button>
                  <button type="button" onClick={() => setEditingTeamId(null)} className="cancel-button">Cancel</button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default TeamManagement;