import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

function Footer() {
  const [screenSize, setScreenSize] = useState('desktop');

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      
      if (width <= 768) {
        setScreenSize('mobile');
      } else if (height <= 768) { // 13-inch and smaller screens
        setScreenSize('small');
      } else if (height <= 900) { // 14-inch screens
        setScreenSize('medium');
      } else {
        setScreenSize('desktop');
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const renderMobileFooter = () => (
    <footer className="main-footer mobile-footer">
      <div className="mobile-footer-content">
        <div className="mobile-footer-top">
          <h3>FanBet247</h3>
          <div className="age-restriction">
            <span className="age-badge">18+</span>
          </div>
        </div>
        
        <div className="mobile-footer-links">
          <Link to="/">Home</Link>
          <Link to="/live-challenges">Live</Link>
          <Link to="/leaderboard">Leaderboard</Link>
          <Link to="/help">Help</Link>
        </div>

        <div className="mobile-footer-bottom">
          <div className="social-links">
            <a href="https://twitter.com/fanbet247" target="_blank" rel="noopener noreferrer">Twitter</a>
            <a href="https://t.me/fanbet247" target="_blank" rel="noopener noreferrer">Telegram</a>
          </div>
          <p className="copyright"> 2024 FanBet247</p>
        </div>
      </div>
    </footer>
  );

  const renderSmallFooter = () => (
    <footer className="main-footer small-footer">
      <div className="small-footer-content">
        <div className="small-footer-top">
          <div className="small-footer-main">
            <div className="small-footer-brand">
              <h3>FanBet247</h3>
              <div className="age-restriction">
                <span className="age-badge">18+</span>
                <p>Bet Responsibly</p>
              </div>
            </div>
            <div className="small-footer-features">
              <span>Live Betting</span>
              <span>•</span>
              <span>Best Odds</span>
              <span>•</span>
              <span>Fast Payouts</span>
            </div>
          </div>
          
          <div className="small-footer-nav">
            <div className="small-nav-column">
              <Link to="/live-challenges">Live Matches</Link>
              <Link to="/upcoming-matches">Upcoming</Link>
              <Link to="/leaderboard">Leaderboard</Link>
            </div>
            <div className="small-nav-column">
              <Link to="/help">Help Center</Link>
              <Link to="/responsible-gambling">Responsible Gaming</Link>
              <Link to="/terms">Terms</Link>
            </div>
          </div>
        </div>

        <div className="small-footer-bottom">
          <div className="social-links">
            <a href="https://twitter.com/fanbet247" target="_blank" rel="noopener noreferrer">Twitter</a>
            <a href="https://facebook.com/fanbet247" target="_blank" rel="noopener noreferrer">Facebook</a>
            <a href="https://t.me/fanbet247" target="_blank" rel="noopener noreferrer">Telegram</a>
          </div>
          <div className="security-badges">
            <span className="security-badge">SSL Secured</span>
            <span className="security-badge">Licensed</span>
          </div>
          <p className="copyright"> 2024 FanBet247. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );

  const renderMediumFooter = () => (
    <footer className="main-footer medium-footer">
      <div className="medium-footer-content">
        <div className="medium-footer-main">
          <div className="footer-section">
            <h3>FanBet247</h3>
            <div className="age-restriction">
              <span className="age-badge">18+</span>
              <p>Please gamble responsibly</p>
            </div>
          </div>

          <div className="footer-links-grid">
            <div className="footer-column">
              <h4>Quick Links</h4>
              <Link to="/">Home</Link>
              <Link to="/live-challenges">Live Matches</Link>
              <Link to="/leaderboard">Leaderboard</Link>
              <Link to="/promotions">Promotions</Link>
            </div>
            <div className="footer-column">
              <h4>Support</h4>
              <Link to="/help">Help Center</Link>
              <Link to="/responsible-gambling">Responsible Gaming</Link>
              <Link to="/terms">Terms</Link>
              <Link to="/privacy">Privacy</Link>
            </div>
          </div>
        </div>

        <div className="medium-footer-bottom">
          <div className="medium-bottom-content">
            <div className="social-links">
              <a href="https://twitter.com/fanbet247" target="_blank" rel="noopener noreferrer">Twitter</a>
              <a href="https://facebook.com/fanbet247" target="_blank" rel="noopener noreferrer">Facebook</a>
              <a href="https://t.me/fanbet247" target="_blank" rel="noopener noreferrer">Telegram</a>
            </div>
            <p className="copyright"> 2024 FanBet247. All rights reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  );

  const renderDesktopFooter = () => (
    <footer className="main-footer desktop-footer">
      <div className="footer-content">
        <div className="footer-section main-info">
          <h3>FanBet247</h3>
          <p>
            Your premier destination for sports betting. Experience live matches, competitive odds, 
            and instant payouts. Join thousands of sports fans making winning predictions daily.
          </p>
          <div className="betting-features">
            <div className="feature">
              <span role="img" aria-label="Trophy" className="feature-icon">🏆</span>
              <span>Live Betting</span>
            </div>
            <div className="feature">
              <span role="img" aria-label="Money bag" className="feature-icon">💰</span>
              <span>Instant Payouts</span>
            </div>
            <div className="feature">
              <span role="img" aria-label="Chart" className="feature-icon">📊</span>
              <span>Best Odds</span>
            </div>
          </div>
          <div className="age-restriction">
            <span className="age-badge">18+</span>
            <p>Please gamble responsibly. Betting can be addictive.</p>
          </div>
        </div>

        <div className="footer-section">
          <h4>Sports</h4>
          <nav className="footer-nav">
            <Link to="/sports/football">Football</Link>
            <Link to="/sports/basketball">Basketball</Link>
            <Link to="/sports/tennis">Tennis</Link>
            <Link to="/sports/cricket">Cricket</Link>
            <Link to="/sports/esports">Esports</Link>
          </nav>
        </div>

        <div className="footer-section">
          <h4>Betting</h4>
          <nav className="footer-nav">
            <Link to="/live-challenges">Live Matches</Link>
            <Link to="/upcoming-matches">Upcoming</Link>
            <Link to="/promotions">Promotions</Link>
            <Link to="/leaderboard">Leaderboard</Link>
            <Link to="/statistics">Statistics</Link>
          </nav>
        </div>

        <div className="footer-section">
          <h4>Support</h4>
          <nav className="footer-nav">
            <Link to="/responsible-gambling">Responsible Gaming</Link>
            <Link to="/help">Help Center</Link>
            <Link to="/terms">Terms & Conditions</Link>
            <Link to="/privacy">Privacy Policy</Link>
            <Link to="/contact">Contact Us</Link>
          </nav>
        </div>
      </div>

      <div className="footer-bottom">
        <div className="footer-bottom-content">
          <div className="social-links">
            <a href="https://twitter.com/fanbet247" target="_blank" rel="noopener noreferrer">Twitter</a>
            <a href="https://facebook.com/fanbet247" target="_blank" rel="noopener noreferrer">Facebook</a>
            <a href="https://instagram.com/fanbet247" target="_blank" rel="noopener noreferrer">Instagram</a>
            <a href="https://t.me/fanbet247" target="_blank" rel="noopener noreferrer">Telegram</a>
          </div>
          <div className="security-badges">
            <span className="security-badge">SSL Secured</span>
            <span className="security-badge">Licensed Gaming</span>
          </div>
          <p className="copyright"> 2024 FanBet247. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );

  switch (screenSize) {
    case 'mobile':
      return renderMobileFooter();
    case 'small':
      return renderSmallFooter();
    case 'medium':
      return renderMediumFooter();
    default:
      return renderDesktopFooter();
  }
}

export default Footer;