import React from 'react';
import Sidebar from '../components/Sidebar';

function DebitUser() {
  return (
    <div className="admin-page">
      <Sidebar />
      <div className="content">
        <h1>Debit User</h1>
        <p>Deduct credits from user accounts here.</p>
        {/* Add debit form or components here */}
      </div>
    </div>
  );
}

export default DebitUser;
