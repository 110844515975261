import React from 'react';
import AlertMessage from './AlertMessage';
import './AlertMessage.css';

const AlertContainer = ({ alerts, removeAlert }) => {
    return (
        <div className="alert-message-container">
            {alerts.map((alert) => (
                <AlertMessage
                    key={alert.id}
                    message={alert.message}
                    type={alert.type}
                    duration={alert.duration}
                    onClose={() => removeAlert(alert.id)}
                />
            ))}
        </div>
    );
};

export default AlertContainer;
