import React from 'react';
import './AdminFooter.css';

function AdminFooter() {
  const currentYear = new Date().getFullYear();
  
  return (
    <footer className="admin-footer">
      <div className="footer-content">
        <div className="footer-info">
          <p>&copy; {currentYear} FanBet247. All rights reserved.</p>
        </div>
        <div className="footer-links">
          <a href="/admin/help">Help</a>
          <a href="/admin/terms">Terms</a>
          <a href="/admin/privacy">Privacy</a>
        </div>
      </div>
    </footer>
  );
}

export default AdminFooter;
