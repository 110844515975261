import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import './ViewBets.css';

const API_BASE_URL = '/backend';

function ViewBets() {
  const [outgoingBets, setOutgoingBets] = useState([]);
  const [teams, setTeams] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage] = useState(20);
  const [searchTerm, setSearchTerm] = useState('');
  const userId = localStorage.getItem('userId');
  const navigate = useNavigate();
  const location = useLocation();
  const newBetRef = useRef(null);

  const [showLinkModal, setShowLinkModal] = useState(false);
  const [selectedBetLink, setSelectedBetLink] = useState('');
  const [showBetDetailsModal, setShowBetDetailsModal] = useState(false);
  const [selectedBet, setSelectedBet] = useState(null);
  const [newBetId, setNewBetId] = useState(location.state?.newBetId || null);

  const fetchBets = useCallback(async () => {
    try {
      console.log('Fetching bets...');
      const response = await axios.get(`${API_BASE_URL}/handlers/get_bets.php`, {
        params: {
          userId: userId,
          page: currentPage,
          limit: itemsPerPage,
          search: searchTerm
        }
      });
      console.log('Bets response:', response.data);

      if (response.data.success) {
        setOutgoingBets(response.data.bets || []);
        setTotalPages(response.data.pagination.totalPages);
      } else {
        console.error('Failed to fetch bets:', response.data.message);
        setError(response.data.message || 'Failed to fetch bets');
      }
    } catch (error) {
      console.error('Error fetching bets:', error);
      setError(error.response?.data?.message || 'An error occurred while fetching bets.');
    } finally {
      setLoading(false);
    }
  }, [userId, currentPage, itemsPerPage, searchTerm]);

  const fetchTeams = useCallback(async () => {
    try {
      console.log('Fetching teams...');
      const response = await axios.get(`${API_BASE_URL}/handlers/team_management.php`);
      console.log('Teams response:', response.data);

      if (response.data.status === 200) {
        setTeams(response.data.data || []);
      } else {
        console.error('Failed to fetch teams:', response.data.message);
        setError(response.data.message || 'Failed to fetch teams');
      }
    } catch (error) {
      console.error('Error fetching teams:', error);
      setError(error.response?.data?.message || 'An error occurred while fetching teams.');
    }
  }, []);

  useEffect(() => {
    if (!userId) {
      navigate('/login');
      return;
    }

    console.log('Initializing ViewBets with userId:', userId);
    const initializeData = async () => {
      try {
        setError(null);
        setLoading(true);
        await Promise.all([fetchBets(), fetchTeams()]);
      } catch (err) {
        console.error('ViewBets initialization error:', err);
        setError('Failed to initialize bets view. Please try again later.');
      }
    };

    initializeData();
  }, [userId, fetchBets, fetchTeams, navigate]);

  useEffect(() => {
    if (newBetId && newBetRef.current) {
      newBetRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      const timer = setTimeout(() => {
        setNewBetId(null);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [outgoingBets, newBetId]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to first page when searching
  };

  const renderPagination = () => {
    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={`pagination-button ${currentPage === i ? 'active' : ''}`}
        >
          {i}
        </button>
      );
    }
    return (
      <div className="pagination">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="pagination-button"
        >
          Previous
        </button>
        {pages}
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="pagination-button"
        >
          Next
        </button>
      </div>
    );
  };

  const calculateOdds = (bet) => {
    const totalPot = parseFloat(bet.amount_user1) * 2;
    const winReturn = parseFloat(bet.potential_return_win_user1);
    const lossReturn = parseFloat(bet.potential_return_loss_user1);
    const drawReturn = parseFloat(bet.potential_return_draw_user1);
    
    const winOdds = (winReturn / totalPot * 100).toFixed(1);
    const lossOdds = (lossReturn / totalPot * 100).toFixed(1);
    const drawOdds = (drawReturn / totalPot * 100).toFixed(1);
    
    return {
      win: { odds: winOdds, return: winReturn },
      loss: { odds: lossOdds, return: lossReturn },
      draw: { odds: drawOdds, return: drawReturn }
    };
  };

  const getUserStatus = (bet, isCreator) => {
    if (bet.bet_status.toLowerCase() === 'joined' || bet.bet_status.toLowerCase() === 'completed') {
      return isCreator ? 'Creator' : 'Opponent';
    }
    
    if (isCreator) {
      return 'Creator';
    }
    
    return 'Waiting for Opponent';
  };

  if (loading) {
    return <div className="loading">Loading bets...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  const getTeamLogo = (teamName) => {
    const team = teams.find(team => team.name === teamName);
    return team ? `${API_BASE_URL}/${team.logo}` : '';
  };

  const handleGenerateLink = (bet) => {
    const link = `${window.location.origin}/user/join-challenge2/${bet.challenge_id}/${bet.bet_id}/${bet.unique_code}/${bet.user1_id}`;
    setSelectedBetLink(link);
    setShowLinkModal(true);
  };

  const handleCloseModal = () => {
    setShowLinkModal(false);
  };

  const getReference = (bet) => {
    return (bet.unique_code || `${bet.bet_id}DNRBKCC`).toUpperCase();
  };

  const handleShowBetDetails = (bet) => {
    setSelectedBet(bet);
    setShowBetDetailsModal(true);
  };

  return (
    <div className="view-bets-container">
      <div className="title-section">
        <h2>Outgoing Bets</h2>
        <div className="title-line"></div>
      </div>
      
      <div className="search-container">
        <input
          type="text"
          placeholder="Search by reference number..."
          value={searchTerm}
          onChange={handleSearch}
          className="search-input"
        />
      </div>

      {/* Desktop Table View */}
      <div className="table-responsive">
        <table className="bets-table">
          <thead>
            <tr>
              <th>#</th>
              <th>Ref</th>
              <th colSpan="3" className="teams-header compact">Teams</th>
              <th>Amount</th>
              <th>Return</th>
              <th>Status</th>
              <th>Match Date</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {outgoingBets.map((bet, index) => (
              <tr 
                key={bet.bet_id} 
                ref={bet.bet_id === newBetId ? newBetRef : null}
                className={bet.bet_id === newBetId ? 'highlight-new-bet' : ''}
              >
                <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                <td>
                  <span className="reference" onClick={() => handleShowBetDetails(bet)}>
                    {getReference(bet)}
                  </span>
                </td>
                <td className="team-cell compact">
                  <div className="team-info">
                    <img 
                      src={getTeamLogo(bet.team_a)} 
                      alt={bet.team_a}
                      className="team-logo"
                    />
                    <span>{bet.team_a}</span>
                    {bet.bet_choice_user1 === 'team_a_win' && <span className="pick-badge">Your Pick</span>}
                  </div>
                </td>
                <td className="vs-cell compact">
                  <div className="vs-indicator">VS</div>
                </td>
                <td className="team-cell compact">
                  <div className="team-info">
                    <img 
                      src={getTeamLogo(bet.team_b)} 
                      alt={bet.team_b}
                      className="team-logo"
                    />
                    <span>{bet.team_b}</span>
                    {bet.bet_choice_user1 !== 'team_a_win' && <span className="pick-badge">Your Pick</span>}
                  </div>
                </td>
                <td className="amount-cell">
                  {bet.amount_user1} <span className="currency">FanCoins</span>
                </td>
                <td className="return-cell">
                  {bet.potential_return_win_user1} <span className="currency">FanCoins</span>
                </td>
                <td>
                  <div className="status-container">
                    <div className={`status-badge ${bet.bet_status}`}>
                      <span className="status-dot"></span>
                      <span className="status-text">
                        {bet.bet_status === 'open' && 'Open'}
                        {bet.bet_status === 'joined' && 'Joined'}
                        {bet.bet_status === 'completed' && 'Completed'}
                      </span>
                    </div>
                  </div>
                </td>
                <td className="date-cell">
                  <div className="date-display">
                    <div className="date-line">{new Date(bet.match_date).toLocaleDateString()}</div>
                    <div className="time-line">{new Date(bet.match_date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</div>
                  </div>
                </td>
                <td>
                  {bet.bet_status === 'open' && (
                    <button onClick={() => handleGenerateLink(bet)} className="generate-link-btn">
                      Generate Link
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Mobile Card View */}
      <div className="mobile-bets-grid">
        {outgoingBets.map((bet, index) => (
          <div 
            key={bet.bet_id}
            className="mobile-bet-card"
            ref={bet.bet_id === newBetId ? newBetRef : null}
            data-status={bet.bet_status.toLowerCase()}
          >
            <div className="mobile-bet-header">
              <span className="mobile-bet-ref" onClick={() => handleShowBetDetails(bet)}>
                {getReference(bet)}
              </span>
              <div className={`status-badge ${bet.bet_status.toLowerCase()}`}>
                <span className="status-dot"></span>
                {bet.bet_status}
              </div>
            </div>

            <div className="mobile-users-section">
              <div className="mobile-user-info">
                <span className="mobile-user-name">{bet.user1_username}</span>
                <span className="mobile-user-status creator">
                  {getUserStatus(bet, true)}
                </span>
              </div>
              <div className="mobile-vs-divider">VS</div>
              <div className="mobile-user-info">
                <span className="mobile-user-name">
                  {bet.user2_username || '---'}
                </span>
                <span className={`mobile-user-status ${bet.user2_username ? 'opponent' : 'waiting'}`}>
                  {getUserStatus(bet, false)}
                </span>
              </div>
            </div>

            <div className="mobile-teams-container">
              <div className="mobile-team">
                <img 
                  src={getTeamLogo(bet.team_a)}
                  alt={bet.team_a}
                  className="mobile-team-logo"
                />
                <span>{bet.team_a}</span>
              </div>
              <div className="mobile-vs">VS</div>
              <div className="mobile-team">
                <img 
                  src={getTeamLogo(bet.team_b)}
                  alt={bet.team_b}
                  className="mobile-team-logo"
                />
                <span>{bet.team_b}</span>
              </div>
            </div>

            <div className="mobile-bet-details">
              <div className="mobile-detail-item">
                <span className="mobile-detail-label">Amount</span>
                <span className="mobile-detail-value">{bet.amount_user1} FanCoins</span>
              </div>
              <div className="mobile-detail-item">
                <span className="mobile-detail-label">Potential Return</span>
                <span className="mobile-detail-value">{bet.potential_return_win_user1} FanCoins</span>
              </div>
              <div className="mobile-detail-item full-width">
                <span className="mobile-detail-label">Match Date</span>
                <span className="mobile-detail-value">
                  {new Date(bet.match_date).toLocaleString()}
                </span>
              </div>
            </div>

            {bet.bet_status === 'open' && (
              <div className="mobile-bet-actions">
                <button 
                  className="mobile-action-button"
                  onClick={() => handleGenerateLink(bet)}
                >
                  Generate Link
                </button>
              </div>
            )}
          </div>
        ))}
      </div>

      {/* Desktop Modal */}
      {window.innerWidth > 768 && showBetDetailsModal && selectedBet && (
        <div className="modal-overlay" onClick={() => setShowBetDetailsModal(false)}>
          <div className="bet-details-modal" onClick={e => e.stopPropagation()}>
            <button className="close-button" onClick={() => setShowBetDetailsModal(false)}>×</button>
            
            <div className="modal-left">
              <div className="modal-header">
                <h3 className="reference-title">Bet Reference: {getReference(selectedBet)}</h3>
                <div className="status-badges">
                  <div className={`status-badge-large ${selectedBet.bet_status}`}>
                    {selectedBet.bet_status === 'open' && 'OPEN FOR BETS'}
                    {selectedBet.bet_status === 'joined' && 'BET MATCHED'}
                    {selectedBet.bet_status === 'completed' && 'COMPLETED'}
                  </div>
                  <div className={`match-type-badge-large ${selectedBet.match_type}`}>
                    {selectedBet.match_type === 'half_time' ? 'HALF TIME' : 'FULL TIME'}
                  </div>
                </div>
              </div>
              
              <div className="teams-match">
                <div className="team-card">
                  {selectedBet.bet_choice_user1 === 'team_a_win' && (
                    <div className="selected-badge">Selected</div>
                  )}
                  <img src={getTeamLogo(selectedBet.team_a)} alt={selectedBet.team_a} />
                  <div className="team-name">{selectedBet.team_a}</div>
                  <div className="team-username">{selectedBet.user1_username}</div>
                  <div className="team-odds">{selectedBet.odds_team_a}x</div>
                </div>
                
                <div className="vs-badge">VS</div>
                
                <div className="team-card">
                  {selectedBet.bet_choice_user1 === 'team_b_win' && (
                    <div className="selected-badge">Selected</div>
                  )}
                  <img src={getTeamLogo(selectedBet.team_b)} alt={selectedBet.team_b} />
                  <div className="team-name">{selectedBet.team_b}</div>
                  <div className="team-username">{selectedBet.user2_username || 'Waiting for opponent'}</div>
                  <div className="team-odds">{selectedBet.odds_team_b}x</div>
                </div>
              </div>

              <div className="match-details-grid">
                <div className="details-section schedule-section">
                  <div className="section-title">MATCH SCHEDULE</div>
                  <div className="schedule-grid">
                    <div className="schedule-item">
                      <span className="schedule-label">MATCH DATE</span>
                      <span className="schedule-value">{new Date(selectedBet.match_date).toLocaleString()}</span>
                    </div>
                    <div className="schedule-item">
                      <span className="schedule-label">START TIME</span>
                      <span className="schedule-value">{new Date(selectedBet.start_time).toLocaleTimeString()}</span>
                    </div>
                    <div className="schedule-item">
                      <span className="schedule-label">END TIME</span>
                      <span className="schedule-value">{new Date(selectedBet.end_time).toLocaleTimeString()}</span>
                    </div>
                    <div className="schedule-item">
                      <span className="schedule-label">CHALLENGE CREATED</span>
                      <span className="schedule-value">{new Date(selectedBet.challenge_date).toLocaleString()}</span>
                    </div>
                  </div>
                </div>

                <div className="details-section odds-section">
                  <div className="section-title">ODDS INFORMATION</div>
                  <div className="odds-grid">
                    <div className="odds-item">
                      <span className="odds-label">{selectedBet.team_a} WIN</span>
                      <span className="odds-value">{selectedBet.odds_team_a}x</span>
                    </div>
                    <div className="odds-item">
                      <span className="odds-label">{selectedBet.team_b} WIN</span>
                      <span className="odds-value">{selectedBet.odds_team_b}x</span>
                    </div>
                    <div className="odds-item">
                      <span className="odds-label">DRAW</span>
                      <span className="odds-value">{selectedBet.odds_draw}x</span>
                    </div>
                    <div className="odds-item">
                      <span className="odds-label">LOSS MULTIPLIER</span>
                      <span className="odds-value">{selectedBet.odds_lost}x</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <div className="modal-right">
              <div className="details-section">
                <div className="section-title">BET STATUS</div>
                <div className="detail-row">
                  <span className="detail-label">CREATED BY</span>
                  <span className="detail-value created-by">{selectedBet.user1_username}</span>
                </div>
                <div className="detail-row">
                  <span className="detail-label">STATUS</span>
                  <span className="detail-value status-value">
                    {selectedBet.user2_username ? `Joined by ${selectedBet.user2_username}` : 'Waiting for opponent'}
                  </span>
                </div>
              </div>

              <div className="details-section">
                <div className="section-title">FINANCIAL DETAILS</div>
                <div className="detail-row">
                  <span className="detail-label">YOUR BET</span>
                  <span className="detail-value amount">{selectedBet.amount_user1} FanCoins</span>
                </div>
                <div className="detail-row">
                  <span className="detail-label">POTENTIAL WIN</span>
                  <span className="detail-value return">{selectedBet.potential_return_win_user1} FanCoins</span>
                </div>
                <div className="detail-row">
                  <span className="detail-label">POTENTIAL LOSS</span>
                  <span className="detail-value amount">{selectedBet.potential_loss_user1} FanCoins</span>
                </div>
                <div className="detail-row">
                  <span className="detail-label">DRAW OUTCOME</span>
                  <span className="detail-value">{selectedBet.potential_draw_win_user1} FanCoins</span>
                </div>
                {selectedBet.user2_username && (
                  <>
                    <div className="detail-row">
                      <span className="detail-label">OPPONENT BET</span>
                      <span className="detail-value amount">{selectedBet.amount_user2} FanCoins</span>
                    </div>
                    <div className="detail-row">
                      <span className="detail-label">OPPONENT WIN</span>
                      <span className="detail-value return">{selectedBet.potential_return_win_user2} FanCoins</span>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Mobile Modal */}
      {window.innerWidth <= 768 && showBetDetailsModal && selectedBet && (
        <div className="modal-overlay" onClick={() => setShowBetDetailsModal(false)}>
          <div className="mobile-modal-content" onClick={e => e.stopPropagation()}>
            <div className="mobile-modal-header">
              <h3>Bet Details</h3>
              <button className="mobile-modal-close" onClick={() => setShowBetDetailsModal(false)}>×</button>
            </div>

            <div className="mobile-modal-body">
              <div className="mobile-ref-section">
                <div className="mobile-ref-number">
                  <span className="mobile-ref-label">Reference Number</span>
                  <span className="mobile-ref-value">{getReference(selectedBet)}</span>
                </div>
                <div className="mobile-status-badges">
                  <span className={`mobile-status-badge ${selectedBet.bet_status.toLowerCase()}`}>
                    {selectedBet.bet_status === 'open' && 'OPEN FOR BETS'}
                    {selectedBet.bet_status === 'joined' && 'BET MATCHED'}
                    {selectedBet.bet_status === 'completed' && 'COMPLETED'}
                  </span>
                  <span className="mobile-status-badge mobile-match-type">
                    {selectedBet.match_type === 'half_time' ? 'HALF TIME' : 'FULL TIME'}
                  </span>
                </div>
              </div>

              <div className="mobile-users-section">
                <div className="mobile-user-info">
                  <span className="mobile-user-name">{selectedBet.user1_username}</span>
                  <span className="mobile-user-status creator">
                    {getUserStatus(selectedBet, true)}
                  </span>
                </div>
                <div className="mobile-vs-divider">VS</div>
                <div className="mobile-user-info">
                  <span className="mobile-user-name">
                    {selectedBet.user2_username || '---'}
                  </span>
                  <span className={`mobile-user-status ${selectedBet.user2_username ? 'opponent' : 'waiting'}`}>
                    {getUserStatus(selectedBet, false)}
                  </span>
                </div>
              </div>

              <div className="mobile-teams-container">
                <div className="mobile-team">
                  <img 
                    src={getTeamLogo(selectedBet.team_a)}
                    alt={selectedBet.team_a}
                    className="mobile-team-logo"
                  />
                  <span>{selectedBet.team_a}</span>
                </div>
                <div className="mobile-vs">VS</div>
                <div className="mobile-team">
                  <img 
                    src={getTeamLogo(selectedBet.team_b)}
                    alt={selectedBet.team_b}
                    className="mobile-team-logo"
                  />
                  <span>{selectedBet.team_b}</span>
                </div>
              </div>

              <div className="mobile-section-title">ODDS INFORMATION</div>
              <div className="mobile-odds-grid">
                <div className="mobile-odds-item">
                  <span className="mobile-odds-label">{selectedBet.team_a} WIN</span>
                  <span className="mobile-odds-value">{selectedBet.odds_team_a}x</span>
                </div>
                <div className="mobile-odds-item">
                  <span className="mobile-odds-label">{selectedBet.team_b} WIN</span>
                  <span className="mobile-odds-value">{selectedBet.odds_team_b}x</span>
                </div>
                <div className="mobile-odds-item">
                  <span className="mobile-odds-label">DRAW</span>
                  <span className="mobile-odds-value">{selectedBet.odds_draw}x</span>
                </div>
                <div className="mobile-odds-item">
                  <span className="mobile-odds-label">LOSS</span>
                  <span className="mobile-odds-value">{selectedBet.odds_lost}x</span>
                </div>
              </div>

              <div className="mobile-section-title">FINANCIAL DETAILS</div>
              <div className="mobile-financial-grid">
                <div className="mobile-financial-item">
                  <span className="mobile-financial-label">Your Bet</span>
                  <span className="mobile-financial-value">{selectedBet.amount_user1} FC</span>
                </div>
                <div className="mobile-financial-item">
                  <span className="mobile-financial-label">Potential Win</span>
                  <span className="mobile-financial-value win">+{selectedBet.potential_return_win_user1} FC</span>
                </div>
                <div className="mobile-financial-item">
                  <span className="mobile-financial-label">Potential Loss</span>
                  <span className="mobile-financial-value loss">-{selectedBet.potential_return_loss_user1} FC</span>
                </div>
                <div className="mobile-financial-item">
                  <span className="mobile-financial-label">Draw Return</span>
                  <span className="mobile-financial-value draw">{selectedBet.potential_return_draw_user1} FC</span>
                </div>
              </div>
            </div>

            {selectedBet.bet_status === 'open' && (
              <div className="mobile-modal-footer">
                <button 
                  className="mobile-modal-action-button"
                  onClick={() => handleGenerateLink(selectedBet)}
                >
                  Generate Link
                </button>
              </div>
            )}
          </div>
        </div>
      )}

      {showLinkModal && (
        <div className="modal-overlay" onClick={handleCloseModal}>
          <div className="link-modal" onClick={e => e.stopPropagation()}>
            <span className="close" onClick={handleCloseModal}>&times;</span>
            <h3>Share this link with your friend</h3>
            <div className="link-container">
              <input type="text" value={selectedBetLink} readOnly />
              <button onClick={() => {
                navigator.clipboard.writeText(selectedBetLink);
                alert('Link copied to clipboard!');
              }}>
                Copy
              </button>
            </div>
          </div>
        </div>
      )}

      <style jsx>{`
        .teams-header.compact {
          width: 20%;  /* Reduced from 30% */
        }
        
        .team-cell.compact {
          max-width: 80px;  /* Reduced from 100px */
          padding: 4px;  /* Reduced padding */
        }
        
        .vs-cell.compact {
          padding: 0 4px;  /* Reduced padding */
          width: 30px;  /* Fixed width */
        }
        
        .team-info {
          display: flex;
          align-items: center;
          gap: 4px;  /* Reduced gap between elements */
          max-width: 100%;
        }
        
        .team-info span {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 0.9rem;
        }

        .date-cell {
          padding: 4px 8px;
          min-width: 100px;
        }

        .date-display {
          display: flex;
          flex-direction: column;
          gap: 2px;
          font-size: 0.85rem;
        }

        .date-line {
          color: #333;
        }

        .time-line {
          color: #666;
          font-size: 0.8rem;
        }
      `}</style>
    </div>
  );
}

export default ViewBets;