import React, { useState, useEffect } from 'react';
import axios from '../utils/axiosConfig';
import { useNavigate } from 'react-router-dom';
import './CreditWallet.css';

function CreditWallet() {
    const [amount, setAmount] = useState('');
    const [step, setStep] = useState(1);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [selectedMethod, setSelectedMethod] = useState(null);
    const [proofImage, setProofImage] = useState(null);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [timeLeft, setTimeLeft] = useState(24 * 60 * 60); // 24 hours in seconds
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const currentUserId = localStorage.getItem('userId');
    const [copiedField, setCopiedField] = useState('');

    useEffect(() => {
        if (!currentUserId) {
            navigate('/login');
            return;
        }
        fetchPaymentMethods();
    }, [navigate, currentUserId]);

    useEffect(() => {
        if (step === 3 && timeLeft > 0) {
            const timer = setInterval(() => {
                setTimeLeft(prev => prev - 1);
            }, 1000);
            return () => clearInterval(timer);
        }
    }, [step, timeLeft]);

    const fetchPaymentMethods = async () => {
        try {
            setLoading(true);
            setError('');
            
            const response = await axios.get('/backend/handlers/payment_methods.php');
            
            if (response.data.status === 200) {
                setPaymentMethods(response.data.data || []);
            } else {
                throw new Error('Failed to fetch payment methods');
            }
        } catch (err) {
            console.error('Error fetching payment methods:', err);
            setError(err.message || 'Failed to load payment methods. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const handleAmountSubmit = (e) => {
        e.preventDefault();
        if (!amount || amount <= 0) {
            setError('Please enter a valid amount');
            return;
        }
        setError('');
        setStep(2);
    };

    const handleMethodSelect = (method) => {
        setSelectedMethod(method);
        setStep(3);
    };

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.size > 5 * 1024 * 1024) { // 5MB limit
                setError('File size should be less than 5MB');
                return;
            }
            setProofImage(file);
            setError('');
        }
    };

    const handleSubmitPayment = async (e) => {
        e.preventDefault();
        if (!proofImage) {
            setError('Please upload proof of payment');
            return;
        }

        try {
            setError('');
            const formData = new FormData();
            formData.append('amount', amount);
            formData.append('payment_method_id', selectedMethod.id);
            formData.append('proof_image', proofImage);
            formData.append('user_id', currentUserId);

            const response = await axios.post('/backend/handlers/credit_request.php', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data.success) {
                setSuccess('Payment proof submitted successfully. Please wait for admin approval.');
                setStep(4);
            } else {
                throw new Error(response.data.message || 'Failed to submit payment proof');
            }
        } catch (err) {
            console.error('Error submitting payment proof:', err);
            setError(err.message || 'Failed to submit payment proof. Please try again.');
        }
    };

    const formatTime = (seconds) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const secs = seconds % 60;
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
    };

    const handleCopyField = (fieldValue) => {
        setCopiedField(fieldValue);
        navigator.clipboard.writeText(fieldValue);
    };

    if (loading) {
        return <div className="loading">Loading...</div>;
    }

    return (
        <div className="credit-wallet-container">
            <h1>Credit Wallet</h1>

            {error && <div className="error-message">{error}</div>}
            {success && <div className="success-message">{success}</div>}

            {step === 1 && (
                <div className="step-container">
                    <h2>Enter Amount</h2>
                    <form onSubmit={handleAmountSubmit}>
                        <div className="form-group">
                            <label>Amount (₦)</label>
                            <input
                                type="number"
                                value={amount}
                                onChange={(e) => setAmount(e.target.value)}
                                min="1"
                                required
                                placeholder="Enter amount"
                            />
                        </div>
                        <button type="submit" className="next-btn">Next</button>
                    </form>
                </div>
            )}

            {step === 2 && (
                <div className="step-container">
                    <h2>Select Payment Method</h2>
                    <ul className="payment-methods-list">
                        {paymentMethods.map(method => (
                            <li key={method.id} 
                                className={`payment-method-item ${selectedMethod?.id === method.id ? 'selected' : ''}`}
                            >
                                <div className="method-type">
                                    {method.type === 'bank' ? '🏦' : 
                                     method.type === 'crypto' ? '💰' : 
                                     method.type === 'mobile_money' ? '📱' : 
                                     method.type === 'paypal' ? '💳' : '💵'}
                                </div>
                                <div className="method-details">
                                    <h3>{method.name}</h3>
                                    <div className="method-type-label">
                                        {method.type.replace('_', ' ').charAt(0).toUpperCase() + method.type.slice(1)}
                                    </div>
                                    <div className="field-list">
                                        {Array.isArray(method.fields) && method.fields.map((field, index) => (
                                            <div key={index} className="field-item" onClick={() => handleCopyField(field.fieldValue)}>
                                                <div className="field-content">
                                                    <strong>{field.fieldName}</strong>
                                                    <p>{field.fieldValue}</p>
                                                </div>
                                                <span className="copy-indicator">
                                                    {copiedField === field.fieldValue ? 'Copied!' : 'Click to copy'}
                                                </span>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="action-buttons">
                                        <button 
                                            className="action-button select-button"
                                            onClick={() => handleMethodSelect(method)}
                                        >
                                            Select Method
                                        </button>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            )}

            {step === 3 && (
                <div className="step-container">
                    <h2>Make Payment</h2>
                    <div className="timer">Time Remaining: {formatTime(timeLeft)}</div>
                    <div className="payment-details">
                        <h3>Payment Details</h3>
                        <p>Amount: ₦{amount}</p>
                        <h4>{selectedMethod.name}</h4>
                        <div className="method-fields">
                            {Array.isArray(selectedMethod.fields) && selectedMethod.fields.map((field, index) => (
                                <div key={index} className="field-item" onClick={() => handleCopyField(field.fieldValue)}>
                                    <div className="field-content">
                                        <strong>{field.fieldName}</strong>
                                        <p>{field.fieldValue}</p>
                                    </div>
                                    <span className="copy-indicator">
                                        {copiedField === field.fieldValue ? 'Copied!' : 'Click to copy'}
                                    </span>
                                </div>
                            ))}
                        </div>
                    </div>
                    <form onSubmit={handleSubmitPayment}>
                        <div className="form-group">
                            <label>Upload Payment Proof</label>
                            <input
                                type="file"
                                accept="image/*"
                                onChange={handleImageUpload}
                                required
                            />
                            <small>Maximum file size: 5MB</small>
                        </div>
                        <button type="submit" className="submit-btn">Submit Payment Proof</button>
                    </form>
                </div>
            )}

            {step === 4 && (
                <div className="step-container success-container">
                    <div className="success-icon">✅</div>
                    <h2>Payment Submitted</h2>
                    <p>Your payment proof has been submitted successfully.</p>
                    <p>Please wait for admin approval. This usually takes 5-10 minutes.</p>
                    <p>You will be notified once your payment is approved.</p>
                </div>
            )}
        </div>
    );
}

export default CreditWallet; 