import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import Countdown from 'react-countdown';
import { useUser } from '../context/UserContext';
import { FaArrowRight, FaChevronRight } from 'react-icons/fa';
import './UserDashboard.css';

const API_BASE_URL = '/backend';

function UserDashboard() {
    const navigate = useNavigate();
    const [user, setUser] = useState({});
    const { setUserData } = useUser();
    const [error, setError] = useState(null);
    const [greeting, setGreeting] = useState('');
    const [recentBets, setRecentBets] = useState([]);
    const [recentChallenges, setRecentChallenges] = useState([]);
    const [teams, setTeams] = useState([]);

    const fetchUserData = useCallback(async () => {
        try {
            console.log('Fetching user data...');
            const userId = localStorage.getItem('userId');
            const response = await axios.get(`${API_BASE_URL}/handlers/user_data.php?id=${userId}`);
            console.log('User data response:', response.data);
            
            if (response.data.success) {
                setUser(response.data.user);
                setUserData({
                    balance: response.data.user.balance || 0,
                    points: response.data.user.points || 0,
                    username: response.data.user.username || ''
                });
            } else {
                console.error('Failed to fetch user data:', response.data.message);
                throw new Error(response.data.message || 'Failed to fetch user data');
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
            throw error;
        }
    }, [setUserData]);

    useEffect(() => {
        const userId = localStorage.getItem('userId');
        if (!userId) {
            navigate('/user/login');
            return;
        }

        const initializeDashboard = async () => {
            try {
                setError(null);
                
                const results = await Promise.allSettled([
                    fetchUserData(),
                    fetchUserBets(),
                    fetchRecentChallenges(),
                    fetchTeams()
                ]);

                results.forEach((result, index) => {
                    if (result.status === 'rejected') {
                        console.error(`Error in Promise ${index}:`, result.reason);
                    }
                });

                setGreeting(getGreeting());
            } catch (err) {
                console.error('Dashboard initialization error:', err);
                setError('Failed to load dashboard data. Please try again later.');
            }
        };

        initializeDashboard();
    }, [navigate, fetchUserData]);

    const fetchUserBets = async () => {
        try {
            console.log('Fetching user bets...');
            const userId = localStorage.getItem('userId');
            const response = await axios.get(`${API_BASE_URL}/handlers/get_user_bets.php?userId=${userId}`);
            console.log('User bets response:', response.data);
            
            if (response.data.success) {
                setRecentBets(response.data.bets || []);
            } else {
                console.error('Failed to fetch user bets:', response.data.message);
                throw new Error(response.data.message || 'Failed to fetch user bets');
            }
        } catch (error) {
            console.error('Error fetching user bets:', error);
            throw error;
        }
    };

    const fetchRecentChallenges = async () => {
        try {
            console.log('Fetching recent challenges...');
            const response = await axios.get(`${API_BASE_URL}/handlers/recent_challenges.php`);
            console.log('Recent challenges response:', response.data);
            
            if (response.data.success) {
                const challengesWithDate = (response.data.challenges || []).map(challenge => ({
                    ...challenge,
                    end_time: new Date(challenge.end_time)
                }));
                setRecentChallenges(challengesWithDate);
            } else {
                console.error('Failed to fetch recent challenges:', response.data.message);
                throw new Error(response.data.message || 'Failed to fetch recent challenges');
            }
        } catch (error) {
            console.error('Error fetching recent challenges:', error);
            throw error;
        }
    };

    const fetchTeams = async () => {
        try {
            console.log('Fetching teams...');
            const response = await axios.get(`${API_BASE_URL}/handlers/team_management.php`);
            console.log('Teams response:', response.data);
            
            if (response.data.status === 200) {
                setTeams(response.data.data || []);
            } else {
                console.error('Failed to fetch teams:', response.data.message);
                throw new Error(response.data.message || 'Failed to fetch teams');
            }
        } catch (err) {
            console.error('Error fetching teams:', err);
            throw err;
        }
    };

    const getTeamLogo = (teamName) => {
        const team = teams.find(team => team.name === teamName);
        return team ? `${API_BASE_URL}/${team.logo}` : '';
    };

    const getGreeting = () => {
        const hour = new Date().getHours();
        if (hour < 12) return 'Good morning';
        if (hour < 18) return 'Good afternoon';
        return 'Good evening';
    };

    const CountdownRenderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            return <span className="status-badge expired">Expired</span>;
        }
        return (
            <span>
                {days}d:{hours}h:{minutes}m:{seconds}s
            </span>
        );
    };

    if (error) {
        return (
            <div className="user-dashboard">
                <div className="error-message">{error}</div>
            </div>
        );
    }

    return (
        <div className="user-dashboard">
            <h1>{greeting}, {user.username || 'User'}!</h1>
            <div className="dashboard-grid">
                <div className="account-balance">
                    <h2>Account Balance: {user.balance || 0} FanCoins</h2>
                    <div className="action-buttons">
                        <Link to="/deposit" className="primary-button">Deposit FanCoins</Link>
                        <Link to="/withdraw" className="secondary-button">Withdraw FanCoins</Link>
                    </div>
                </div>
            </div>

            <div className="recent-bets-container">
                <div className="section-header">
                    <h2>Recent Bets</h2>
                    <Link to="/user/bets" className="view-all-button">
                        View All <FaArrowRight className="view-all-icon" />
                    </Link>
                </div>
                <div className="bets-list">
                    {recentBets.length === 0 ? (
                        <div className="no-data">No recent bets found</div>
                    ) : (
                        recentBets.map(bet => (
                            <div key={bet.bet_id || `bet-${bet.unique_code}`} className="bet-card">
                                <div className="bet-header">
                                    <span className={`bet-status ${bet.bet_status}`}>
                                        {bet.bet_status}
                                    </span>
                                    <span className="bet-reference">
                                        REF: {bet.unique_code?.toUpperCase() || `${bet.bet_id}DNRBKCC`}
                                    </span>
                                </div>
                                
                                <div className="potential-returns">
                                    <div key="win" className="return-item win">Win: {bet.potential_return_win_user1} FC</div>
                                    <div key="draw" className="return-item draw">Draw: {bet.potential_return_draw_user1} FC</div>
                                    <div key="loss" className="return-item loss">Loss: {bet.potential_return_loss_user1} FC</div>
                                </div>

                                <div className="bet-matchup">
                                    <div className="bet-team">
                                        <img src={getTeamLogo(bet.team_a)} alt={bet.team_a} className="team-logo-medium" />
                                        <div className="team-details">
                                            <span className="team-name">{bet.team_a}</span>
                                            <span className="username">
                                                {bet.bet_choice_user1 === 'team_a_win' ? bet.user1_name : bet.user2_name}
                                            </span>
                                            <span className="bet-amount">
                                                {bet.bet_choice_user1 === 'team_a_win' ? bet.amount_user1 : bet.amount_user2} FC
                                            </span>
                                        </div>
                                    </div>

                                    <div className="vs-badge">VS</div>

                                    <div className="bet-team">
                                        <img src={getTeamLogo(bet.team_b)} alt={bet.team_b} className="team-logo-medium" />
                                        <div className="team-details">
                                            <span className="team-name">{bet.team_b}</span>
                                            <span className="username">
                                                {bet.bet_choice_user1 === 'team_b_win' ? bet.user1_name : bet.user2_name}
                                            </span>
                                            <span className="bet-amount">
                                                {bet.bet_choice_user1 === 'team_b_win' ? bet.amount_user1 : bet.amount_user2} FC
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    )}
                </div>
            </div>

            <div className="dashboard-recent-challenges">
                <div className="dashboard-section-header">
                    <h2>Recent Challenges</h2>
                    <Link to="/user/challenges" className="dashboard-view-all-button">
                        View All <FaChevronRight className="view-all-icon" />
                    </Link>
                </div>
                <div className="dashboard-challenges-list">
                    {recentChallenges.length === 0 ? (
                        <div className="dashboard-no-data">No recent challenges found</div>
                    ) : (
                        <div className="dashboard-challenges-grid">
                            {recentChallenges.slice(0, 3).map((challenge) => (
                                <div key={challenge.challenge_id || `challenge-${challenge.id}`} className="dashboard-challenge-card">
                                    <div className="dashboard-challenge-teams">
                                        <div key="team-a" className="dashboard-challenge-team">
                                            <div className="dashboard-team-logo-wrapper">
                                                <img
                                                    src={getTeamLogo(challenge.team_a)}
                                                    alt={challenge.team_a}
                                                    className="dashboard-team-logo"
                                                    onError={(e) => {
                                                        e.target.src = '/default-team-logo.png';
                                                    }}
                                                />
                                            </div>
                                            <span className="dashboard-team-name">{challenge.team_a}</span>
                                        </div>
                                        <div className="dashboard-vs-badge">VS</div>
                                        <div key="team-b" className="dashboard-challenge-team">
                                            <div className="dashboard-team-logo-wrapper">
                                                <img
                                                    src={getTeamLogo(challenge.team_b)}
                                                    alt={challenge.team_b}
                                                    className="dashboard-team-logo"
                                                    onError={(e) => {
                                                        e.target.src = '/default-team-logo.png';
                                                    }}
                                                />
                                            </div>
                                            <span className="dashboard-team-name">{challenge.team_b}</span>
                                        </div>
                                    </div>
                                    <div className="dashboard-challenge-details">
                                        <div className="dashboard-challenge-info">
                                            <div className="dashboard-challenge-stats">
                                                <div className="dashboard-stat-row">
                                                    <span className="dashboard-stat-label">Status:</span>
                                                    <span className={`dashboard-challenge-status dashboard-status-${challenge.status.toLowerCase()}`}>
                                                        {challenge.status}
                                                    </span>
                                                </div>
                                                <div className="dashboard-stat-row">
                                                    <span className="dashboard-stat-label">Goal Advantage:</span>
                                                    <span className="dashboard-stat-value">+{challenge.team_a_goal_advantage} / +{challenge.team_b_goal_advantage}</span>
                                                </div>
                                                <div className="dashboard-stat-row">
                                                    <span className="dashboard-stat-label">Odds:</span>
                                                    <span className="dashboard-stat-value">{challenge.odds_team_a} - {challenge.odds_team_b}</span>
                                                </div>
                                            </div>
                                            <span className="dashboard-challenge-date">
                                                {new Date(challenge.match_date).toLocaleDateString()}
                                            </span>
                                        </div>
                                        <div className="dashboard-challenge-timer">
                                            <Countdown
                                                date={challenge.end_time}
                                                renderer={CountdownRenderer}
                                            />
                                        </div>
                                        <Link 
                                            to={`/user/join-challenge/${challenge.challenge_id}`} 
                                            className="dashboard-place-bet-button"
                                        >
                                            Place Bet
                                        </Link>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default UserDashboard;