import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/league.css';

const SeasonHistory = () => {
    const [seasons, setSeasons] = useState([]);
    const [selectedSeason, setSelectedSeason] = useState(null);
    const [seasonStats, setSeasonStats] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchSeasonHistory = async () => {
            try {
                const response = await axios.get('/backend/handlers/season_history.php');
                setSeasons(response.data.data);
                if (response.data.data.length > 0) {
                    setSelectedSeason(response.data.data[0].season_id);
                }
            } catch (error) {
                console.error('Error fetching season history:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchSeasonHistory();
    }, []);

    useEffect(() => {
        const fetchSeasonStats = async () => {
            if (!selectedSeason) return;

            try {
                const response = await axios.get(`/backend/handlers/season_stats.php?season_id=${selectedSeason}`);
                setSeasonStats(response.data.data);
            } catch (error) {
                console.error('Error fetching season stats:', error);
            }
        };

        fetchSeasonStats();
    }, [selectedSeason]);

    if (loading) {
        return <div className="loading">Loading...</div>;
    }

    return (
        <div className="season-history">
            <header>
                <h1>Season History</h1>
                <select 
                    value={selectedSeason || ''} 
                    onChange={(e) => setSelectedSeason(e.target.value)}
                    className="season-selector"
                >
                    {seasons.map(season => (
                        <option key={season.season_id} value={season.season_id}>
                            {season.season_name} ({new Date(season.start_date).toLocaleDateString()} - 
                            {new Date(season.end_date).toLocaleDateString()})
                        </option>
                    ))}
                </select>
            </header>

            {seasonStats && (
                <div className="season-details">
                    <div className="season-summary">
                        <div className="summary-card">
                            <h3>Your Performance</h3>
                            <div className="stats-grid">
                                <div className="stat-item">
                                    <span className="label">Final Rank</span>
                                    <span className="value">{seasonStats.final_rank}</span>
                                </div>
                                <div className="stat-item">
                                    <span className="label">Total Points</span>
                                    <span className="value">{seasonStats.total_points}</span>
                                </div>
                                <div className="stat-item">
                                    <span className="label">Win Rate</span>
                                    <span className="value">{seasonStats.win_rate}%</span>
                                </div>
                                <div className="stat-item">
                                    <span className="label">Rewards Earned</span>
                                    <span className="value">{seasonStats.rewards_earned}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="season-highlights">
                        <h3>Season Highlights</h3>
                        <div className="highlights-grid">
                            {seasonStats.highlights.map((highlight, index) => (
                                <div key={index} className="highlight-card">
                                    <span className="highlight-date">
                                        {new Date(highlight.date).toLocaleDateString()}
                                    </span>
                                    <p className="highlight-description">{highlight.description}</p>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="season-matches">
                        <h3>Match History</h3>
                        <div className="matches-list">
                            {seasonStats.matches.map(match => (
                                <div key={match.match_id} className="match-item">
                                    <div className="match-date">
                                        {new Date(match.date).toLocaleDateString()}
                                    </div>
                                    <div className="match-details">
                                        <span className={`result ${match.result.toLowerCase()}`}>
                                            {match.result}
                                        </span>
                                        <span className="points">+{match.points} pts</span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SeasonHistory;
