import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FaImage, FaUpload } from 'react-icons/fa';
import '../styles/CreateLeague.css';

const CreateLeague = () => {
    const navigate = useNavigate();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [iconPreview, setIconPreview] = useState(null);
    const [bannerPreview, setBannerPreview] = useState(null);
    const [leagueData, setLeagueData] = useState({
        name: '',
        min_bet_amount: '',
        max_bet_amount: '',
        description: '',
        season_duration: 90,
        league_rules: '',
        reward_description: '',
        status: 'upcoming',
        theme_color: '#007bff',
        icon: null,
        banner: null
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setLeagueData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleFileChange = (e) => {
        const { name, files } = e.target;
        if (files && files[0]) {
            const file = files[0];
            const reader = new FileReader();
            
            reader.onloadend = () => {
                if (name === 'icon') {
                    setIconPreview(reader.result);
                } else if (name === 'banner') {
                    setBannerPreview(reader.result);
                }
            };
            
            reader.readAsDataURL(file);
            setLeagueData(prev => ({
                ...prev,
                [name]: file
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        try {
            const formData = new FormData();
            Object.keys(leagueData).forEach(key => {
                if (key === 'icon' || key === 'banner') {
                    if (leagueData[key]) {
                        formData.append(key, leagueData[key]);
                    }
                } else {
                    formData.append(key, leagueData[key]);
                }
            });

            const response = await axios.post('/backend/handlers/league_management.php', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.data.status === 200 || response.data.status === 201) {
                navigate('/admin/league-management', { 
                    state: { message: 'League created successfully' } 
                });
            } else {
                setError(response.data.message || 'Failed to create league');
            }
        } catch (err) {
            console.error('Error creating league:', err);
            setError('Failed to create league. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="create-league-container">
            <div className="create-league-header">
                <h1>Create New League</h1>
                <p>Set up a new league competition</p>
            </div>

            {error && <div className="error-message">{error}</div>}

            <form onSubmit={handleSubmit} className="create-league-form">
                <div className="form-section media-section">
                    <h2>League Media</h2>
                    <div className="media-preview">
                        <div className="icon-upload">
                            <label htmlFor="icon" className="upload-label">
                                {iconPreview ? (
                                    <img src={iconPreview} alt="League icon preview" className="icon-preview" />
                                ) : (
                                    <div className="upload-placeholder">
                                        <FaImage />
                                        <span>Upload League Icon</span>
                                        <small>Recommended size: 300x300px</small>
                                    </div>
                                )}
                            </label>
                            <input
                                type="file"
                                id="icon"
                                name="icon"
                                accept="image/*"
                                onChange={handleFileChange}
                                className="file-input"
                            />
                        </div>
                        
                        <div className="banner-upload">
                            <label htmlFor="banner" className="upload-label">
                                {bannerPreview ? (
                                    <img src={bannerPreview} alt="League banner preview" className="banner-preview" />
                                ) : (
                                    <div className="upload-placeholder">
                                        <FaUpload />
                                        <span>Upload League Banner</span>
                                        <small>Recommended size: 1200x400px</small>
                                    </div>
                                )}
                            </label>
                            <input
                                type="file"
                                id="banner"
                                name="banner"
                                accept="image/*"
                                onChange={handleFileChange}
                                className="file-input"
                            />
                        </div>
                    </div>
                    
                    <div className="color-picker-section">
                        <label htmlFor="theme_color">Theme Color</label>
                        <input
                            type="color"
                            id="theme_color"
                            name="theme_color"
                            value={leagueData.theme_color}
                            onChange={handleInputChange}
                        />
                        <div 
                            className="color-preview"
                            style={{ 
                                '--selected-color': leagueData.theme_color,
                                background: leagueData.theme_color 
                            }}
                        >
                            Selected Color: {leagueData.theme_color}
                        </div>
                    </div>
                </div>

                <div className="form-section">
                    <h2>Basic Information</h2>
                    <div className="form-group">
                        <label htmlFor="name">League Name *</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={leagueData.name}
                            onChange={handleInputChange}
                            required
                            placeholder="Enter league name"
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="description">Description *</label>
                        <textarea
                            id="description"
                            name="description"
                            value={leagueData.description}
                            onChange={handleInputChange}
                            required
                            placeholder="Describe the league"
                            rows="4"
                        />
                    </div>
                </div>

                <div className="form-section">
                    <h2>Betting Limits</h2>
                    <div className="form-row">
                        <div className="form-group">
                            <label htmlFor="min_bet_amount">Minimum Bet Amount *</label>
                            <input
                                type="number"
                                id="min_bet_amount"
                                name="min_bet_amount"
                                value={leagueData.min_bet_amount}
                                onChange={handleInputChange}
                                required
                                min="0"
                                step="0.01"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="max_bet_amount">Maximum Bet Amount *</label>
                            <input
                                type="number"
                                id="max_bet_amount"
                                name="max_bet_amount"
                                value={leagueData.max_bet_amount}
                                onChange={handleInputChange}
                                required
                                min="0"
                                step="0.01"
                            />
                        </div>
                    </div>
                </div>

                <div className="form-section">
                    <h2>League Settings</h2>
                    <div className="form-group">
                        <label htmlFor="season_duration">Season Duration (days)</label>
                        <input
                            type="number"
                            id="season_duration"
                            name="season_duration"
                            value={leagueData.season_duration}
                            onChange={handleInputChange}
                            min="1"
                            max="365"
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="league_rules">League Rules *</label>
                        <textarea
                            id="league_rules"
                            name="league_rules"
                            value={leagueData.league_rules}
                            onChange={handleInputChange}
                            required
                            placeholder="Enter league rules and guidelines"
                            rows="6"
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="reward_description">Reward Structure *</label>
                        <textarea
                            id="reward_description"
                            name="reward_description"
                            value={leagueData.reward_description}
                            onChange={handleInputChange}
                            required
                            placeholder="Describe the reward structure"
                            rows="4"
                        />
                    </div>
                </div>

                <div className="form-actions">
                    <button 
                        type="button" 
                        className="btn secondary"
                        onClick={() => navigate('/admin/league-management')}
                    >
                        Cancel
                    </button>
                    <button 
                        type="submit" 
                        className="btn primary"
                        disabled={loading}
                    >
                        {loading ? 'Creating...' : 'Create League'}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default CreateLeague;
