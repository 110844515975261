import React, { createContext, useState, useContext, useEffect } from 'react';

const UserContext = createContext();

// Get initial user data from localStorage
const getInitialUserData = () => {
  try {
    const savedData = localStorage.getItem('userData');
    if (savedData) {
      return JSON.parse(savedData);
    }
  } catch (error) {
    console.error('Error reading user data from localStorage:', error);
  }
  return {
    balance: 0,
    points: 0,
    username: '',
  };
};

export function UserProvider({ children }) {
  const [userData, setUserData] = useState(getInitialUserData());

  // Save user data to localStorage whenever it changes
  useEffect(() => {
    try {
      localStorage.setItem('userData', JSON.stringify(userData));
    } catch (error) {
      console.error('Error saving user data to localStorage:', error);
    }
  }, [userData]);

  // Wrapper for setUserData that ensures numbers are properly formatted
  const updateUserData = (newData) => {
    setUserData(prevData => ({
      ...prevData,
      ...newData,
      balance: typeof newData.balance === 'string' ? parseFloat(newData.balance) : newData.balance,
      points: typeof newData.points === 'string' ? parseFloat(newData.points) : newData.points,
    }));
  };

  return (
    <UserContext.Provider value={{ userData, setUserData: updateUserData }}>
      {children}
    </UserContext.Provider>
  );
}

export function useUser() {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
}
