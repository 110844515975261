import React, { useState } from 'react';

function Withdraw() {
  const [amount, setAmount] = useState('');
  const [withdrawalMethod, setWithdrawalMethod] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add withdrawal logic here
  };

  return (
    <div>
      <h1>Withdraw Funds</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Amount:</label>
          <input
            type="number"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            min="0"
          />
        </div>
        <div>
          <label>Withdrawal Method:</label>
          <select
            value={withdrawalMethod}
            onChange={(e) => setWithdrawalMethod(e.target.value)}
          >
            <option value="">Select a withdrawal method</option>
            <option value="bank_transfer">Bank Transfer</option>
            <option value="crypto">Cryptocurrency</option>
          </select>
        </div>
        <button type="submit">Withdraw</button>
      </form>
    </div>
  );
}

export default Withdraw;
