import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import './UserLogin.css';

const API_BASE_URL = '/backend';

function UserLogin() {
    const [credentials, setCredentials] = useState({ usernameOrEmail: '', password: '' });
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    // Redirect if already logged in
    useEffect(() => {
        const userId = localStorage.getItem('userId');
        if (userId) {
            navigate('/user/dashboard');
        }
    }, [navigate]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCredentials(prev => ({ ...prev, [name]: value }));
        if (error) setError('');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setIsLoading(true);

        try {
            const response = await axios.post(
                `${API_BASE_URL}/handlers/user_login.php`,
                credentials
            );

            if (response.data.success) {
                localStorage.setItem('userId', response.data.userId);
                localStorage.setItem('username', response.data.username);
                
                // Get redirect path or default to dashboard
                const redirectPath = sessionStorage.getItem('redirectAfterLogin') || '/user/dashboard';
                sessionStorage.removeItem('redirectAfterLogin');
                navigate(redirectPath);
            } else {
                setError(response.data.message || 'Login failed. Please try again.');
            }
        } catch (err) {
            console.error('Login error:', err);
            if (err.response?.data?.message) {
                setError(err.response.data.message);
            } else if (err.response?.status === 401) {
                setError('Invalid username/email or password');
            } else if (err.response?.status === 400) {
                setError('Please enter both username/email and password');
            } else {
                setError('An error occurred. Please try again later.');
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="login-page">
            <div className="login-container">
                <div className="login-header">
                    <Link to="/" className="brand-logo">FanBet247</Link>
                    <h1>User Login</h1>
                    <p className="login-subtitle">Welcome back! Please login to your account</p>
                </div>
                {error && <div className="error-message">{error}</div>}
                <form onSubmit={handleSubmit} className="login-form">
                    <div className="form-group">
                        <label htmlFor="usernameOrEmail">Username or Email</label>
                        <div className="input-wrapper">
                            <i className="fas fa-user"></i>
                            <input
                                type="text"
                                id="usernameOrEmail"
                                name="usernameOrEmail"
                                value={credentials.usernameOrEmail}
                                onChange={handleInputChange}
                                placeholder="Enter your username or email"
                                required
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <div className="input-wrapper">
                            <i className="fas fa-lock"></i>
                            <input
                                type="password"
                                id="password"
                                name="password"
                                value={credentials.password}
                                onChange={handleInputChange}
                                placeholder="Enter your password"
                                required
                            />
                        </div>
                    </div>
                    <div className="form-options">
                        <Link to="/forgot-password" className="forgot-password">Forgot Password?</Link>
                    </div>
                    <button type="submit" className="login-button" disabled={isLoading}>
                        {isLoading ? 'Logging in...' : 'Login to Your Account'}
                    </button>
                    <div className="login-footer">
                        <p>Don't have an account? <Link to="/register" className="register-link">Register here</Link></p>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default UserLogin;