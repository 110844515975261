import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from '../utils/axiosConfig';
import './JoinChallenge2.css';
import '../styles/TeamLogo.css';

const API_BASE_URL = '/backend';

const JoinChallenge2 = () => {
  const { challengeId, betId, uniqueCode } = useParams();
  const navigate = useNavigate();
  const [challenge, setChallenge] = useState(null);
  const [bet, setBet] = useState(null);
  const [betAmount, setBetAmount] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [currentUsername, setCurrentUsername] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [potentialReturns, setPotentialReturns] = useState({
    win: 0,
    draw: 0,
    loss: 0
  });
  const [teams, setTeams] = useState([]);

  const userId = localStorage.getItem('userId');
  const user1Id = bet?.user1_id;

  const fetchChallenge = useCallback(async () => {
    try {
      console.log('Fetching challenge with ID:', challengeId);
      const response = await axios.get(`${API_BASE_URL}/handlers/get_challenges.php`, {
        params: { id: challengeId }
      });
      console.log('Challenge response:', response.data);
      if (response.data.success) {
        setChallenge(response.data.challenge);
      } else {
        throw new Error(response.data.message || 'Failed to fetch challenge');
      }
    } catch (error) {
      console.error('Error fetching challenge:', error);
      setError('Failed to load challenge details. Please try again.');
    }
  }, [challengeId]);

  const fetchBetDetails = useCallback(async () => {
    try {
      console.log('Fetching bet details:', { betId, uniqueCode });
      const response = await axios.get(`${API_BASE_URL}/handlers/get_bet_details.php`, {
        params: { betId, uniqueCode }
      });
      console.log('Bet details response:', response.data);
      if (response.data.success) {
        setBet(response.data.bet);
        // Set initial bet amount from user1's amount
        setBetAmount(response.data.bet.amount_user1);
      } else {
        throw new Error(response.data.message || 'Failed to fetch bet details');
      }
    } catch (error) {
      console.error('Error fetching bet details:', error);
      setError('Error loading bet details. Please try again.');
    }
  }, [betId, uniqueCode]);

  const fetchTeams = useCallback(async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/handlers/team_management.php`);
      if (response.data.status === 200) {
        setTeams(response.data.data);
      } else {
        console.warn('Team data not in expected format:', response.data);
      }
    } catch (error) {
      console.error('Error fetching teams:', error);
    }
  }, []);

  const fetchCurrentUsername = useCallback(async () => {
    try {
      if (!userId) {
        throw new Error('User ID not found');
      }
      const response = await axios.get(`${API_BASE_URL}/handlers/user_data.php`, {
        params: { userId }
      });
      if (response.data.success) {
        setCurrentUsername(response.data.user.username);
      } else {
        throw new Error(response.data.message || 'Failed to fetch user data');
      }
    } catch (error) {
      console.error('Error fetching current username:', error);
      setError('Error loading user data');
    }
  }, [userId]);

  const getTeamLogo = useCallback((teamName) => {
    const team = teams.find(t => t.name === teamName);
    return team ? `${API_BASE_URL}/${team.logo}` : '/default-team-logo.png';
  }, [teams]);

  const calculatePotentialReturns = useCallback(() => {
    if (!betAmount || !challenge || !bet) return;
    
    try {
      const amount = parseFloat(betAmount);
      if (isNaN(amount)) return;

      let winOdds;
      // Determine odds based on user1's bet choice
      if (bet.bet_choice_user1 === 'team_a_win') {
        winOdds = parseFloat(challenge.odds_team_b);
      } else if (bet.bet_choice_user1 === 'team_b_win') {
        winOdds = parseFloat(challenge.odds_team_a);
      } else {
        winOdds = parseFloat(challenge.odds_draw);
      }

      const drawOdds = parseFloat(challenge.odds_draw) || 0.9;
      const lossOdds = parseFloat(challenge.odds_lost) || 0.2;

      setPotentialReturns({
        win: (amount * winOdds).toFixed(2),
        draw: (amount * drawOdds).toFixed(2),
        loss: (amount * lossOdds).toFixed(2)
      });
    } catch (error) {
      console.error('Error calculating returns:', error);
    }
  }, [betAmount, challenge, bet]);

  const getTeamDisplay = useCallback(() => {
    if (!challenge || !bet) return null;

    // Determine teams based on user1's bet choice
    const leftTeam = bet.bet_choice_user1 === 'team_b_win' ? challenge.team_b : challenge.team_a;
    const leftTeamLogo = bet.bet_choice_user1 === 'team_b_win' ? getTeamLogo(challenge.team_b) : getTeamLogo(challenge.team_a);
    
    const rightTeam = bet.bet_choice_user1 === 'team_b_win' ? challenge.team_a : challenge.team_b;
    const rightTeamLogo = bet.bet_choice_user1 === 'team_b_win' ? getTeamLogo(challenge.team_a) : getTeamLogo(challenge.team_b);

    return (
      <div className="challenge-details">
        <div className="team-info">
          <div className="challenge-team-wrapper">
            <img 
              src={leftTeamLogo}
              alt={leftTeam}
              className="challenge-team-logo"
              onError={(e) => {
                e.target.src = '/default-team-logo.png';
              }}
            />
            <span className="challenge-team-name">{leftTeam}</span>
            <span className="player-name">{bet.user1_name}</span>
            <span className="opponent-pick">Opponent's Pick</span>
          </div>
        </div>

        <div className="vs-container">
          <span className="vs">VS</span>
        </div>

        <div className="team-info">
          <div className="challenge-team-wrapper">
            <img 
              src={rightTeamLogo}
              alt={rightTeam}
              className="challenge-team-logo"
              onError={(e) => {
                e.target.src = '/default-team-logo.png';
              }}
            />
            <span className="challenge-team-name">{rightTeam}</span>
            <span className="player-name">{currentUsername}</span>
          </div>
        </div>
      </div>
    );
  }, [challenge, bet, getTeamLogo, currentUsername]);

  useEffect(() => {
    const initializeComponent = async () => {
      setIsLoading(true);
      setError(null);

      try {
        if (!userId) {
          navigate('/user/login');
          return;
        }

        // Fetch all required data
        await Promise.all([
          fetchChallenge(),
          fetchBetDetails(),
          fetchTeams(),
          fetchCurrentUsername()
        ]);

        setIsLoading(false);
      } catch (error) {
        console.error('Error initializing component:', error);
        setError('Failed to load bet details. Please try again.');
        setIsLoading(false);
      }
    };

    initializeComponent();
  }, [challengeId, betId, uniqueCode, userId, navigate, fetchChallenge, fetchBetDetails, fetchTeams, fetchCurrentUsername]);

  useEffect(() => {
    if (bet && parseInt(user1Id) === parseInt(userId)) {
      setError("You cannot bet against yourself!");
      setTimeout(() => {
        navigate('/user/bets/outgoing');
      }, 3000);
    }
  }, [bet, user1Id, userId, navigate]);

  useEffect(() => {
    calculatePotentialReturns();
  }, [calculatePotentialReturns]);

  const handleJoinBet = async (event) => {
    event.preventDefault();
    setError('');
    setSuccess('');

    try {
      // Validate user is logged in
      if (!userId) {
        throw new Error('Please log in to place a bet');
      }

      // Validate bet amount
      if (!betAmount || isNaN(parseFloat(betAmount))) {
        throw new Error('Please enter a valid bet amount');
      }

      const amount = parseFloat(betAmount);
      let odds;
      let bet_choice_user2;
      
      // Set odds and bet choice based on opposite of User 1's choice
      if (bet.bet_choice_user1 === 'team_a_win') {
        odds = parseFloat(challenge.odds_team_b);
        bet_choice_user2 = 'team_b_win';
      } else if (bet.bet_choice_user1 === 'team_b_win') {
        odds = parseFloat(challenge.odds_team_a);
        bet_choice_user2 = 'team_a_win';
      } else {
        odds = parseFloat(challenge.odds_draw);
        bet_choice_user2 = 'draw';
      }

      // Validate odds calculation
      if (isNaN(odds)) {
        throw new Error('Invalid odds calculation');
      }

      const requestData = {
        betId: parseInt(betId),
        challengeId: parseInt(challenge.challenge_id),
        userId: parseInt(userId),
        uniqueCode,
        amount: amount.toFixed(2),
        amount_user2: amount.toFixed(2),
        bet_choice_user2,
        odds_user2: odds.toFixed(2),
        potential_return_user2: (amount * odds).toFixed(2),
        potential_return_win_user2: (amount * odds).toFixed(2),
        potential_return_draw_user2: (amount * (challenge.odds_draw || 0.9)).toFixed(2),
        potential_return_loss_user2: (amount * (challenge.odds_lost || 0.2)).toFixed(2),
        team1_id: parseInt(challenge.team_a_id),
        team2_id: parseInt(challenge.team_b_id)
      };

      console.log('Sending bet data:', requestData);

      const response = await axios.post(`${API_BASE_URL}/handlers/accept_bet.php`, requestData);
      console.log('Server response:', response.data);

      if (response.data.success) {
        setSuccess('Successfully joined the bet! Redirecting...');
        setBetAmount('');
        
        setTimeout(() => {
          navigate('/user/bets/accepted', { replace: true });
        }, 2000);
      } else {
        throw new Error(response.data.message || 'Failed to join bet');
      }
    } catch (error) {
      console.error('Error joining bet:', error);
      setError(error.response?.data?.message || error.message || 'An error occurred while joining the bet.');
    }
  };

  if (isLoading) {
    return <div className="join-challenge"><p>Loading bet details...</p></div>;
  }

  if (error) {
    return (
      <div className="join-challenge">
        <div className="error-message">{error}</div>
        <button onClick={() => navigate('/user/bets')} className="back-button">
          Back to Bets
        </button>
      </div>
    );
  }

  if (!challenge || !bet) {
    return (
      <div className="join-challenge">
        <div className="error-message">Bet details not found</div>
        <button onClick={() => navigate('/user/bets')} className="back-button">
          Back to Bets
        </button>
      </div>
    );
  }

  return (
    <div className="join-challenge">
      <h1>Accept Bet</h1>
      {error && <div className="error-message">{error}</div>}
      {success && <div className="success-message">{success}</div>}
      
      <div className="centered-layout">
        {getTeamDisplay()}
      </div>

      <div className="match-details-grid">
        <div className="detail-item match-type">
          <span className="label">Match Type</span>
          <span className="value">{challenge.match_type === 'full_time' ? 'Full Time' : 'Half Time'}</span>
        </div>

        <div className="detail-item date">
          <span className="label">Match Date</span>
          <span className="value">{new Date(challenge.match_date).toLocaleString()}</span>
        </div>

        <div className="detail-item odds">
          <span className="label">Your Odds</span>
          <span className="value">{bet.bet_choice_user1 === 'team_a_win' ? challenge.odds_team_b : challenge.odds_team_a}x</span>
        </div>

        <div className="detail-item amount">
          <span className="label">Bet Amount</span>
          <span className="value">{betAmount} FanCoins</span>
        </div>
      </div>

      <div className="returns-grid">
        <div className="return-item win">
          <span className="label">Win Return</span>
          <span className="value">{potentialReturns.win} FanCoins</span>
        </div>
        <div className="return-item draw">
          <span className="label">Draw Return</span>
          <span className="value">{potentialReturns.draw} FanCoins</span>
        </div>
        <div className="return-item loss">
          <span className="label">Loss Return</span>
          <span className="value">{potentialReturns.loss} FanCoins</span>
        </div>
      </div>

      <form className="bet-form" onSubmit={handleJoinBet}>
        <button type="submit" className="submit-button" disabled={!!error}>
          Accept Bet
        </button>
      </form>
    </div>
  );
};

export default JoinChallenge2;