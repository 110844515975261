import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './LeagueManagement.css';
import { FaImage } from 'react-icons/fa';

const API_BASE_URL = '/backend';

function LeagueManagement() {
    const navigate = useNavigate();
    const [leagues, setLeagues] = useState([]);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [loading, setLoading] = useState(true);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [selectedLeague, setSelectedLeague] = useState(null);
    const [newLeague, setNewLeague] = useState({
        name: '',
        min_bet_amount: '',
        max_bet_amount: '',
        description: '',
        season_duration: 90,
        league_rules: '',
        reward_description: '',
        status: 'upcoming'
    });

    const getMediaUrl = (path, type) => {
        if (!path) return null;
        // If path already contains 'uploads', use it as is
        if (path.includes('uploads')) {
            return `${API_BASE_URL}/${path}`;
        }
        // Otherwise, construct the full path
        return `${API_BASE_URL}/uploads/leagues/${type}s/${path}`;
    };

    const fetchLeagues = async () => {
        try {
            setLoading(true);
            setError('');
            const response = await axios.get(`${API_BASE_URL}/handlers/league_management.php`);
            if (response.data.status === 200) {
                setLeagues(response.data.data || []);
            } else {
                setError(response.data.message || 'Failed to fetch leagues');
            }
        } catch (err) {
            console.error('Error fetching leagues:', err);
            setError('Failed to fetch leagues. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchLeagues();
    }, []);

    const handleCreateLeague = async (e) => {
        e.preventDefault();
        try {
            setError('');
            const formData = new FormData();
            Object.keys(newLeague).forEach(key => {
                formData.append(key, newLeague[key]);
            });

            const response = await axios.post(`${API_BASE_URL}/handlers/league_management.php`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.data.status === 200 || response.data.status === 201) {
                setSuccess('League created successfully');
                setShowCreateModal(false);
                fetchLeagues();
                setNewLeague({
                    name: '',
                    min_bet_amount: '',
                    max_bet_amount: '',
                    description: '',
                    season_duration: 90,
                    league_rules: '',
                    reward_description: '',
                    status: 'upcoming'
                });
            } else {
                setError(response.data.message || 'Failed to create league');
            }
        } catch (err) {
            console.error('Error creating league:', err);
            setError('Failed to create league. Please try again.');
        }
    };

    const handleUpdateLeague = async (updatedData) => {
        try {
            setError('');
            setSuccess('');
            
            // For debugging
            console.log('Updated data received:', updatedData);
            
            const formData = new FormData();
            formData.append('action', 'update');
            formData.append('league_id', selectedLeague.league_id);

            // Required fields that must be present
            const requiredFields = ['name', 'min_bet_amount', 'max_bet_amount', 'description'];

            // First, add all existing data from selectedLeague as fallback
            requiredFields.forEach(field => {
                formData.append(field, selectedLeague[field]);
            });

            // Then add all updated fields from the form
            for (let [key, value] of updatedData.entries()) {
                formData.append(key, value);
            }

            // Validate that all required fields are present
            let missingFields = [];
            requiredFields.forEach(field => {
                if (!formData.get(field)) {
                    missingFields.push(field);
                }
            });

            if (missingFields.length > 0) {
                throw new Error(`Missing required fields: ${missingFields.join(', ')}`);
            }

            // For debugging
            console.log('Form data being sent:');
            for (let [key, value] of formData.entries()) {
                console.log(key, ':', value);
            }

            const response = await axios.post(`${API_BASE_URL}/handlers/league_management.php`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.data.status === 200) {
                setSuccess('League updated successfully');
                setShowEditModal(false);
                await fetchLeagues(); // Re-fetch leagues to update the display
            } else {
                setError(response.data.message || 'Failed to update league');
            }
        } catch (err) {
            console.error('Error updating league:', err);
            setError(err.message || 'Failed to update league. Please try again.');
        }
    };

    const handleViewSeasons = (league) => {
        navigate(`/admin/league-management/${league.league_id}/seasons`);
    };

    return (
        <div className="league-management">
            <header className="page-header">
                <div className="header-content">
                    <h1>League Management</h1>
                    <button 
                        className="btn primary create-league-btn"
                        onClick={() => navigate('/admin/league-management/create')}
                    >
                        Create New League
                    </button>
                </div>
                {error && <div className="error-message">{error}</div>}
                {success && <div className="success-message">{success}</div>}
            </header>

            {loading ? (
                <div className="loading">Loading leagues...</div>
            ) : leagues.length === 0 ? (
                <div className="no-leagues">No leagues found. Create your first league!</div>
            ) : (
                <div className="leagues-grid">
                    {leagues.map(league => (
                        <div key={league.league_id} className="league-card">
                            <img 
                                src={getMediaUrl(league.banner_path, 'banner')}
                                alt={`${league.name} banner`}
                                className="league-banner"
                                onError={(e) => {
                                    e.target.onerror = null; 
                                    e.target.style.backgroundColor = league.theme_color || '#f0f0f0';
                                }}
                            />
                            <div className="status" style={{
                                background: league.status === 'active' ? '#e8f5e9' : 
                                           league.status === 'upcoming' ? '#e3f2fd' : '#fafafa',
                                color: league.status === 'active' ? '#2e7d32' :
                                       league.status === 'upcoming' ? '#1565c0' : '#616161'
                            }}>
                                {league.status}
                            </div>
                            <div className="league-header">
                                <img 
                                    src={getMediaUrl(league.icon_path, 'icon')}
                                    alt={`${league.name} icon`}
                                    className="league-icon"
                                    onError={(e) => {
                                        e.target.onerror = null; 
                                        e.target.style.backgroundColor = league.theme_color || '#f0f0f0';
                                        e.target.style.padding = '8px';
                                    }}
                                />
                                <h2>{league.name}</h2>
                            </div>
                            <div className="league-content">
                                <div className="description">
                                    {league.description}
                                </div>
                                <div className="league-info">
                                    <span className="info-label">Min Bet</span>
                                    <span className="info-value">${league.min_bet_amount}</span>
                                </div>
                                <div className="league-info">
                                    <span className="info-label">Max Bet</span>
                                    <span className="info-value">${league.max_bet_amount}</span>
                                </div>
                                <div className="league-info">
                                    <span className="info-label">Duration</span>
                                    <span className="info-value">{league.season_duration} days</span>
                                </div>
                                <div className="league-actions">
                                    <button 
                                        onClick={() => {
                                            setSelectedLeague(league);
                                            setShowEditModal(true);
                                        }}
                                        className="edit-button"
                                    >
                                        Edit League
                                    </button>
                                    <button onClick={() => handleViewSeasons(league)}>
                                        Manage Seasons
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}

            {showCreateModal && (
                <CreateLeagueModal
                    league={newLeague}
                    onChange={setNewLeague}
                    onSubmit={handleCreateLeague}
                    onClose={() => setShowCreateModal(false)}
                />
            )}

            {showEditModal && selectedLeague && (
                <EditLeagueModal
                    league={selectedLeague}
                    onClose={() => {
                        setShowEditModal(false);
                        setSelectedLeague(null);
                    }}
                    onSave={handleUpdateLeague}
                />
            )}
        </div>
    );
}

function CreateLeagueModal({ league, onChange, onSubmit, onClose }) {
    return (
        <div className="modal-overlay">
            <div className="league-modal">
                <button className="close-button" onClick={onClose}>×</button>
                <h2>Create New League</h2>
                <form onSubmit={onSubmit}>
                    <div className="form-group">
                        <label>League Name</label>
                        <input
                            type="text"
                            value={league.name}
                            onChange={(e) => onChange({...league, name: e.target.value})}
                            required
                        />
                    </div>
                    <div className="form-row">
                        <div className="form-group">
                            <label>Minimum Bet (₦)</label>
                            <input
                                type="number"
                                value={league.min_bet_amount}
                                onChange={(e) => onChange({...league, min_bet_amount: e.target.value})}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label>Maximum Bet (₦)</label>
                            <input
                                type="number"
                                value={league.max_bet_amount}
                                onChange={(e) => onChange({...league, max_bet_amount: e.target.value})}
                                required
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <label>Description</label>
                        <textarea
                            value={league.description}
                            onChange={(e) => onChange({...league, description: e.target.value})}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>League Rules</label>
                        <textarea
                            value={league.league_rules}
                            onChange={(e) => onChange({...league, league_rules: e.target.value})}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>Reward Description</label>
                        <textarea
                            value={league.reward_description}
                            onChange={(e) => onChange({...league, reward_description: e.target.value})}
                            required
                        />
                    </div>
                    <div className="form-row">
                        <div className="form-group">
                            <label>League Icon</label>
                            <input
                                type="file"
                                id="league_icon"
                                accept="image/*"
                            />
                        </div>
                        <div className="form-group">
                            <label>League Banner</label>
                            <input
                                type="file"
                                id="league_banner"
                                accept="image/*"
                            />
                        </div>
                    </div>
                    <div className="form-actions">
                        <button type="button" className="cancel-button" onClick={onClose}>Cancel</button>
                        <button type="submit" className="submit-button">Create League</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

const EditLeagueModal = ({ league, onClose, onSave }) => {
    const [formData, setFormData] = useState({
        name: league.name || '',
        description: league.description || '',
        min_bet_amount: league.min_bet_amount || '',
        max_bet_amount: league.max_bet_amount || '',
        season_duration: league.season_duration || 90,
        league_rules: league.league_rules || '',
        reward_description: league.reward_description || '',
        status: league.status || 'upcoming',
        theme_color: league.theme_color || '#007bff'
    });
    const [iconPreview, setIconPreview] = useState(league.icon_url || null);
    const [bannerPreview, setBannerPreview] = useState(league.banner_url || null);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleFileChange = (e) => {
        const { name, files } = e.target;
        if (files && files[0]) {
            const file = files[0];
            const reader = new FileReader();
            
            reader.onloadend = () => {
                if (name === 'icon') {
                    setIconPreview(reader.result);
                } else if (name === 'banner') {
                    setBannerPreview(reader.result);
                }
            };
            
            reader.readAsDataURL(file);
            setFormData(prev => ({
                ...prev,
                [name]: file
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        try {
            // Validate required fields
            const requiredFields = ['name', 'min_bet_amount', 'max_bet_amount', 'description'];
            for (const field of requiredFields) {
                if (!formData[field]) {
                    throw new Error(`${field} is required`);
                }
            }

            const data = new FormData();
            Object.keys(formData).forEach(key => {
                if (formData[key] !== null && formData[key] !== undefined) {
                    data.append(key, formData[key]);
                }
            });

            await onSave(data);
            onClose();
        } catch (err) {
            setError(err.message || 'Failed to update league');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="edit-league-modal" onClick={(e) => e.target.className === 'edit-league-modal' && onClose()}>
            <div className="edit-league-content">
                <div className="edit-league-header">
                    <h2>Edit League</h2>
                    <button className="close-button" onClick={onClose}>&times;</button>
                </div>

                {error && <div className="error-message">{error}</div>}

                <form onSubmit={handleSubmit} className="edit-league-form">
                    <div className="form-section">
                        <h3>League Media</h3>
                        <div className="media-preview-section">
                            <div className="icon-preview-container">
                                <label htmlFor="icon">
                                    {iconPreview ? (
                                        <img src={iconPreview} alt="League icon preview" className="preview-image" />
                                    ) : (
                                        <div className="preview-placeholder">
                                            <FaImage />
                                            <span>Upload League Icon</span>
                                            <small>Recommended: 300x300px</small>
                                        </div>
                                    )}
                                </label>
                                <input
                                    type="file"
                                    id="icon"
                                    name="icon"
                                    onChange={handleFileChange}
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                />
                            </div>
                            
                            <div className="banner-preview-container">
                                <label htmlFor="banner">
                                    {bannerPreview ? (
                                        <img src={bannerPreview} alt="League banner preview" className="preview-image" />
                                    ) : (
                                        <div className="preview-placeholder">
                                            <FaImage />
                                            <span>Upload League Banner</span>
                                            <small>Recommended: 1200x400px</small>
                                        </div>
                                    )}
                                </label>
                                <input
                                    type="file"
                                    id="banner"
                                    name="banner"
                                    onChange={handleFileChange}
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                />
                            </div>
                        </div>

                        <div className="color-preview-section" style={{ '--theme-color': formData.theme_color }}>
                            <label htmlFor="theme_color">Theme Color</label>
                            <input
                                type="color"
                                id="theme_color"
                                name="theme_color"
                                value={formData.theme_color}
                                onChange={handleInputChange}
                                className="color-picker"
                            />
                            <span className="color-value">{formData.theme_color}</span>
                        </div>
                    </div>

                    <div className="form-section">
                        <h3>Basic Information</h3>
                        <div className="form-group">
                            <label htmlFor="name">League Name</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={formData.name}
                                onChange={handleInputChange}
                                required
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="description">Description</label>
                            <textarea
                                id="description"
                                name="description"
                                value={formData.description}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                    </div>

                    <div className="form-section">
                        <h3>League Settings</h3>
                        <div className="form-group">
                            <label htmlFor="min_bet_amount">Minimum Bet Amount</label>
                            <input
                                type="number"
                                id="min_bet_amount"
                                name="min_bet_amount"
                                value={formData.min_bet_amount}
                                onChange={handleInputChange}
                                required
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="max_bet_amount">Maximum Bet Amount</label>
                            <input
                                type="number"
                                id="max_bet_amount"
                                name="max_bet_amount"
                                value={formData.max_bet_amount}
                                onChange={handleInputChange}
                                required
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="season_duration">Season Duration (days)</label>
                            <input
                                type="number"
                                id="season_duration"
                                name="season_duration"
                                value={formData.season_duration}
                                onChange={handleInputChange}
                                min="1"
                                required
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="status">Status</label>
                            <select
                                id="status"
                                name="status"
                                value={formData.status}
                                onChange={handleInputChange}
                            >
                                <option value="upcoming">Upcoming</option>
                                <option value="active">Active</option>
                                <option value="completed">Completed</option>
                            </select>
                        </div>
                    </div>

                    <div className="form-section">
                        <h3>Additional Information</h3>
                        <div className="form-group">
                            <label htmlFor="league_rules">League Rules</label>
                            <textarea
                                id="league_rules"
                                name="league_rules"
                                value={formData.league_rules}
                                onChange={handleInputChange}
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="reward_description">Reward Description</label>
                            <textarea
                                id="reward_description"
                                name="reward_description"
                                value={formData.reward_description}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>

                    <div className="form-actions">
                        <button type="button" onClick={onClose} className="cancel-button" disabled={loading}>
                            Cancel
                        </button>
                        <button type="submit" className="save-button" disabled={loading}>
                            {loading ? 'Saving...' : 'Save Changes'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default LeagueManagement;
