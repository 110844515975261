import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import './OldSidebar.css';

const API_BASE_URL = '/backend';

function OldSidebar({ handleLogout, isCollapsed: propIsCollapsed, onToggle }) {
  const [leagues, setLeagues] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(propIsCollapsed || false);

  useEffect(() => {
    fetchLeagues();
    checkLoginStatus();
  }, []);

  useEffect(() => {
    setIsCollapsed(propIsCollapsed);
  }, [propIsCollapsed]);

  const checkLoginStatus = () => {
    const userId = localStorage.getItem('userId');
    setIsLoggedIn(!!userId);
  };

  const fetchLeagues = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/handlers/get_leagues.php`);
      if (response.data.success) {
        setLeagues(response.data.leagues);
      }
    } catch (error) {
      console.error('Error fetching leagues:', error);
    }
  };

  const getLeagueIcon = (league) => {
    if (league.icon_url) {
      return <img src={league.icon_url} alt={league.name} className="league-icon-img" />;
    } else if (league.icon_path) {
      return <img src={`${API_BASE_URL}/uploads/leagues/icons/${league.icon_path}`} alt={league.name} className="league-icon-img" />;
    }
    return "🏆";
  };

  const topLeagues = leagues.slice(0, 7);

  const handleViewAll = () => {
    if (isLoggedIn) {
      window.location.href = '/league-home';
    } else {
      window.location.href = '/login';
    }
  };

  const handleLogoutClick = () => {
    handleLogout();
    setIsLoggedIn(false);
  };

  const toggleSidebar = () => {
    if (onToggle) {
      onToggle();
    } else {
      setIsCollapsed(!isCollapsed);
    }
  };

  return (
    <div className={`old-sidebar ${isCollapsed ? 'collapsed' : ''}`}>
      <div className="sidebar-header">
        <h2>{isCollapsed ? 'AL' : 'AVAILABLE LEAGUES'}</h2>
      </div>
      
      <nav className="leagues-nav">
        <div className="leagues-section">
          <div className="leagues-list">
            {topLeagues.map((league) => (
              <Link
                key={league.league_id}
                to={isLoggedIn ? `/league/${league.league_id}` : '/login'}
                className="league-item"
                title={league.name}
              >
                <span className="league-icon">{getLeagueIcon(league)}</span>
                {!isCollapsed && (
                  <div className="league-info">
                    <span className="league-name">{league.name}</span>
                    <span className="league-min-bet">Min Bet: ₦{Number(league.min_bet_amount).toLocaleString()}</span>
                  </div>
                )}
              </Link>
            ))}
          </div>
          <button 
            onClick={handleViewAll} 
            className="view-all-btn" 
            title={isLoggedIn ? "View All Leagues" : "Login to View All"}
          >
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              viewBox="0 0 576 512" 
              width="20" 
              height="20" 
              fill="#0F8A42"
              style={{ minWidth: '20px' }}
            >
              <path d="M552 64H448V24c0-13.3-10.7-24-24-24H152c-13.3 0-24 10.7-24 24v40H24C10.7 64 0 74.7 0 88v56c0 35.7 22.5 72.4 61.9 100.7 31.5 22.7 69.8 37.1 110 41.7C203.3 338.5 240 360 240 360v72h-48c-35.3 0-64 20.7-64 56v12c0 6.6 5.4 12 12 12h296c6.6 0 12-5.4 12-12v-12c0-35.3-28.7-56-64-56h-48v-72s36.7-21.5 68.1-73.6c40.3-4.6 78.6-19 110-41.7 39.3-28.3 61.9-65 61.9-100.7V88c0-13.3-10.7-24-24-24zM99.3 192.8C74.9 175.2 64 155.6 64 144v-16h64.2c1 32.6 5.8 61.2 12.8 86.2-15.1-5.2-29.2-12.4-41.7-21.4zM512 144c0 16.1-17.7 36.1-35.3 48.8-12.5 9-26.7 16.2-41.8 21.4 7-25 11.8-53.6 12.8-86.2H512v16z"/>
            </svg>
            {!isCollapsed && (
              <span className="view-all-text">
                {isLoggedIn ? 'View All Leagues' : 'Login to View All'}
              </span>
            )}
          </button>
          <button onClick={toggleSidebar} className="toggle-btn" title={isCollapsed ? "Expand Menu" : "Collapse Menu"}>
            <span className="toggle-icon">{isCollapsed ? '→' : '←'}</span>
            {!isCollapsed && <span className="toggle-text">Collapse Menu</span>}
          </button>
        </div>
      </nav>
    </div>
  );
}

export default OldSidebar;