import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from '../utils/axiosConfig';
import '../styles/league.css';
import { FaTrophy, FaUsers, FaCalendarAlt, FaChartLine, FaStar, FaFire } from 'react-icons/fa';

const MyLeagues = () => {
    const [userLeagues, setUserLeagues] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchUserLeagues = async () => {
            try {
                setLoading(true);
                const userId = localStorage.getItem('userId');
                if (!userId) {
                    setError('User not found');
                    return;
                }

                const response = await axios.get(`/backend/handlers/user_leagues.php?user_id=${userId}`);
                console.log('User leagues response:', response.data);

                if (response.data.status === 200) {
                    setUserLeagues(response.data.data);
                } else {
                    setError(response.data.message || 'Failed to load leagues');
                }
            } catch (err) {
                console.error('Error fetching user leagues:', err);
                setError('Failed to load your leagues. Please try again.');
            } finally {
                setLoading(false);
            }
        };

        fetchUserLeagues();
    }, []);

    if (loading) {
        return (
            <div className="loading-container">
                <div className="loading-spinner">Loading your leagues...</div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="error-container">
                <div className="error-message">{error}</div>
                <Link to="/user/leagues" className="return-link">Return to Leagues</Link>
            </div>
        );
    }

    if (userLeagues.length === 0) {
        return (
            <div className="empty-leagues-container">
                <div className="no-leagues-message">
                    <FaTrophy className="large-icon" />
                    <h2>You haven't joined any leagues yet!</h2>
                    <p>Join a league to start competing and earning rewards.</p>
                    <Link to="/user/leagues" className="join-league-button">Browse Leagues</Link>
                </div>
            </div>
        );
    }

    return (
        <div className="my-leagues-container">
            <div className="my-leagues-header">
                <h1>My Leagues</h1>
                <div className="leagues-stats">
                    <div className="stat-item">
                        <FaTrophy className="stat-icon" />
                        <div className="stat-info">
                            <label>Active Leagues</label>
                            <span>{userLeagues.length}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="leagues-grid">
                {userLeagues.map((league) => (
                    <div key={league.league_id} className="league-card my-league">
                        <div className="league-banner">
                            {league.league_banner ? (
                                <img src={league.league_banner} alt={`${league.name} banner`} />
                            ) : (
                                <div className="default-banner" />
                            )}
                        </div>
                        
                        <div className="league-icon">
                            {league.league_icon ? (
                                <img src={league.league_icon} alt={`${league.name} icon`} />
                            ) : (
                                <FaTrophy />
                            )}
                        </div>

                        <h3>{league.name}</h3>
                        <p>{league.description}</p>

                        <div className="league-stats">
                            <div className="stat-row">
                                <span><FaUsers /> {league.member_count} Members</span>
                                <span><FaChartLine /> Rank #{league.user_rank}</span>
                            </div>
                            <div className="stat-row">
                                <span><FaTrophy /> {league.points} Points</span>
                                <span><FaFire /> {league.streak} Streak</span>
                            </div>
                            <div className="stat-row">
                                <span>Wins: {league.wins}</span>
                                <span>Losses: {league.losses}</span>
                            </div>
                        </div>

                        <div className="betting-limits">
                            <span>Min: {league.min_bet_formatted} FC</span>
                            <span>Max: {league.max_bet_formatted} FC</span>
                        </div>

                        <div className="league-footer">
                            <span className="join-date">
                                <FaCalendarAlt /> Joined: {new Date(league.join_date).toLocaleDateString()}
                            </span>
                            <Link to={`/user/leagues/${league.league_id}`} className="view-league-btn">
                                View League
                            </Link>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default MyLeagues;
