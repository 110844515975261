import React, { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Countdown from 'react-countdown';
import MainLayout from '../components/Layout/MainLayout';
import ErrorAlert from '../components/ErrorAlert';
import { handleError, retryOperation } from '../utils/errorHandler';
import './WelcomeSplash.css';

const API_BASE_URL = '/backend';

// Countdown renderer component
const CountdownRenderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    return <span className="status status--expired">ENDED</span>;
  }

  return (
    <div className="countdown">
      {days > 0 && <span>{days}d</span>}
      {hours > 0 && <span>{hours}h</span>}
      <span>{minutes}m</span>
      <span>{seconds}s</span>
    </div>
  );
};

const getMatchStatus = (startTime, endTime) => {
  try {
    const now = new Date();
    const start = new Date(startTime);
    const end = new Date(endTime);

    if (now < start) {
      return 'upcoming';
    } else if (now >= start && now <= end) {
      return 'live';
    } else {
      return 'expired';
    }
  } catch (error) {
    console.error('Error calculating match status:', error);
    return 'unknown';
  }
};

const EmptyState = ({ message }) => (
  <div className="empty-state">
    <img 
      src="/empty-challenges.png"
      alt="No data" 
      className="empty-state__image"
      onError={(e) => e.target.style.display = 'none'} 
    />
    <p>{message}</p>
    </div>
  );

const WelcomeSplash = () => {
  const [recentChallenges, setRecentChallenges] = useState([]);
  const [recentBets, setRecentBets] = useState([]);
  const [loading, setLoading] = useState({
    challenges: true,
    bets: true
  });
  const [error, setError] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  const sliderImages = [
    process.env.PUBLIC_URL + '/slider/Slider1.png',
    process.env.PUBLIC_URL + '/slider/Slider2.png'
  ];

  const nextSlide = useCallback(() => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % sliderImages.length);
  }, [sliderImages.length]);

  useEffect(() => {
    const timer = setInterval(nextSlide, 5000);
    return () => clearInterval(timer);
  }, [nextSlide]);

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([
        fetchRecentChallenges(),
        fetchRecentBets()
      ]);
    };

    fetchData();
    setIsLoggedIn(!!localStorage.getItem('userId'));
  }, []);

  const fetchRecentChallenges = async () => {
    try {
      setLoading(prev => ({ ...prev, challenges: true }));
      const response = await retryOperation(async () => {
        const result = await axios.get(`${API_BASE_URL}/handlers/recent_challenges.php`);
        if (!result.data.success) {
          throw new Error(result.data.message || 'Failed to fetch recent challenges');
        }
        return result;
      });

        const challenges = response.data.challenges.map(challenge => ({
          ...challenge,
          end_time: new Date(challenge.end_time)
        }));
        setRecentChallenges(challenges);
    } catch (err) {
      const appError = handleError(err, { component: 'WelcomeSplash', operation: 'fetchRecentChallenges' });
      setError(appError);
    } finally {
      setLoading(prev => ({ ...prev, challenges: false }));
    }
  };

  const fetchRecentBets = async () => {
    try {
      setLoading(prev => ({ ...prev, bets: true }));
      const response = await retryOperation(async () => {
        const result = await axios.get(`${API_BASE_URL}/handlers/welcome_recent_bets.php`);
        if (!result.data.success) {
          throw new Error(result.data.message || 'Failed to fetch recent bets');
        }
        return result;
      });

        setRecentBets(response.data.bets || []);
    } catch (err) {
      const appError = handleError(err, { component: 'WelcomeSplash', operation: 'fetchRecentBets' });
      setError(appError);
    } finally {
      setLoading(prev => ({ ...prev, bets: false }));
    }
  };

  const handleBetClick = (challengeId) => {
    if (!isLoggedIn) {
      sessionStorage.setItem('redirectAfterLogin', `/user/join-challenge/${challengeId}`);
      navigate('/login');
    } else {
      navigate(`/user/join-challenge/${challengeId}`);
    }
  };

  const renderChallengesList = () => {
    if (loading.challenges) {
      return <div className="loading">Loading challenges...</div>;
    }

    if (recentChallenges.length === 0) {
      return <EmptyState message="No active challenges at the moment. Check back later!" />;
    }

  return (
                      <div className="challenges-list">
                        <div className="challenge-list-header">
                          <div className="header-teams">Teams</div>
                          <div className="header-odds">
                            <span>Win</span>
                            <span>Draw</span>
            <span>Win</span>
                          </div>
                          <div className="header-action">Action</div>
                          <div className="header-status">Status</div>
                        </div>
                        {recentChallenges.map(challenge => {
                          const matchStatus = getMatchStatus(challenge.start_time, challenge.end_time);
                          
                          return (
                            <div key={challenge.challenge_id} className="challenge-card">
                              <div className="teams-container">
                                  <div className="team">
                                      <img 
                    src={`${API_BASE_URL}/${challenge.team_a_logo}`}
                                        alt={challenge.team_a} 
                    className="team-logo"
                                        onError={(e) => {
                                          e.target.src = '/default-team-logo.png';
                                        }}
                                      />
                                      <span className="team-name">{challenge.team_a}</span>
                                  </div>

                                  <div className="vs-container">
                                    <span className="vs">VS</span>
                  <span className={`match-type ${challenge.match_type}`}>
                    {challenge.match_type === 'full_time' ? 'FULL TIME' : 
                     challenge.match_type === 'half_time' ? 'HALF TIME' : 
                     challenge.match_type === 'FT' ? 'FULL TIME' :
                     challenge.match_type === 'HT' ? 'HALF TIME' : 'FULL TIME'}
                                    </span>
                  <Countdown 
                    date={new Date(challenge.end_time)}
                    renderer={CountdownRenderer}
                  />
                                  </div>

                                  <div className="team">
                                      <img 
                    src={`${API_BASE_URL}/${challenge.team_b_logo}`}
                                        alt={challenge.team_b} 
                    className="team-logo"
                                        onError={(e) => {
                                          e.target.src = '/default-team-logo.png';
                                        }}
                                      />
                                      <span className="team-name">{challenge.team_b}</span>
                                </div>
                              </div>

                              <div className="odds-container">
                <div className="odds-item">
                  <span className="odds-value">{Number(challenge.odds_team_a).toFixed(2)}</span>
                                </div>
                <div className="odds-item">
                  <span className="odds-value">{Number(challenge.odds_draw).toFixed(2)}</span>
                                </div>
                <div className="odds-item">
                  <span className="odds-value">{Number(challenge.odds_team_b).toFixed(2)}</span>
                                </div>
                              </div>

                              <div className="action-container">
                <button 
                  className={`action-button ${isLoggedIn ? 'place-bet' : 'login'}`}
                  onClick={() => handleBetClick(challenge.challenge_id)}
                >
                  {isLoggedIn ? 'Place Bet' : 'Login to Bet'}
                </button>
                              </div>

                              <div className="status-container">
                {matchStatus === 'upcoming' ? (
                                  <Countdown 
                                    date={new Date(challenge.start_time)} 
                                    renderer={CountdownRenderer}
                                  />
                ) : (
                                  <span className={`status-badge ${matchStatus}`}>
                    {matchStatus === 'live' ? 'LIVE' : 'ENDED'}
                                  </span>
                                )}
                              </div>
                            </div>
                          );
                        })}
                                </div>
                              );
  };

  const renderRecentBets = () => {
    if (loading.bets) {
      return <div className="loading">Loading recent bets...</div>;
    }

    if (recentBets.length === 0) {
      return <EmptyState message="No recent bets available. Start betting now!" />;
    }

                            return (
      <div className="recent-bets-grid">
        {recentBets.slice(0, 6).map((bet, index) => (
          <div key={bet.bet_id || index} className="recent-bet-card">
            <div className="bet-header">
              <span className="bet-ref">REF: {bet.unique_code}</span>
              <div className="bet-amount">
                <span>{Number(bet.amount_user1).toFixed(2)} FC</span>
                <span>{Number(bet.amount_user2).toFixed(2)} FC</span>
              </div>
            </div>

            <div className="bet-teams">
              <div className="bet-team">
                <img 
                  src={`${API_BASE_URL}/${bet.team_a_logo}`}
                                        alt={bet.team_a} 
                  className="bet-team-logo"
                                        onError={(e) => {
                                          e.target.src = '/default-team-logo.png';
                                        }}
                                      />
                <span className="bet-team-name">{bet.team_a}</span>
                                  </div>
                                  
              <div className="bet-vs">VS</div>
                                  
              <div className="bet-team">
                                      <img 
                  src={`${API_BASE_URL}/${bet.team_b_logo}`}
                                        alt={bet.team_b} 
                  className="bet-team-logo"
                                        onError={(e) => {
                                          e.target.src = '/default-team-logo.png';
                                        }}
                                      />
                <span className="bet-team-name">{bet.team_b}</span>
                                  </div>
                                </div>
                                
            <div className="bet-odds">
              <div className="bet-odds-item">
                <span className="bet-odds-value">{Number(bet.odds_team_a).toFixed(2)}</span>
                <span className="bet-odds-label">Win</span>
                                  </div>
              <div className="bet-odds-item">
                <span className="bet-odds-value">{Number(bet.odds_draw).toFixed(2)}</span>
                <span className="bet-odds-label">Draw</span>
                                  </div>
              <div className="bet-odds-item">
                <span className="bet-odds-value">{Number(bet.odds_team_b).toFixed(2)}</span>
                <span className="bet-odds-label">Win</span>
                                  </div>
                                </div>

            <div className="bet-users">
              <div className="bet-user">
                <span className="bet-username">{bet.user1_name}</span>
                                  </div>
              <div className="bet-user">
                <span className="bet-username">{bet.user2_name}</span>
                                  </div>
                                </div>
          </div>
        ))}
                              </div>
                            );
  };

  return (
    <MainLayout>
      <div className="welcome">
        <div className="welcome__content">
          {error && <ErrorAlert error={error} onClose={() => setError(null)} />}
          
          {/* Hero Section */}
          <section className="hero">
            <div className="hero__slider">
              {sliderImages.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`Slide ${index + 1}`}
                  className="hero__image"
                  style={{
                    transform: `translateX(${100 * (index - currentSlide)}%)`,
                    display: Math.abs(currentSlide - index) <= 1 ? 'block' : 'none'
                  }}
                  onError={(e) => {
                    e.target.src = process.env.PUBLIC_URL + '/slider/Slider1.png';
                  }}
                />
              ))}
                        </div>
                      </section>

          {/* Live Challenges Section */}
          <section className="section challenges-section">
            <div className="section__header">
              <h2 className="section__title">Live Challenges</h2>
              <div className="section__actions">
                {isLoggedIn ? (
                  <Link to="/user/dashboard" className="section__link">Dashboard</Link>
                ) : (
                  <Link to="/login" className="section__link">Login</Link>
                )}
              </div>
            </div>
            {renderChallengesList()}
          </section>

          {/* Recent Bets Section */}
          <section className="section recent-bets-section">
            <div className="section__header">
              <h2 className="section__title">Recent Bets</h2>
              <Link to="/user/recent-bets" className="section__link">View All</Link>
            </div>
            {renderRecentBets()}
          </section>
        </div>
        </div>
      </MainLayout>
  );
};

export default WelcomeSplash;
