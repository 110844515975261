import React, { useState, useEffect, useRef } from 'react';
import axios from '../utils/axiosConfig';
import './TransactionManagement.css';

function TransactionManagement() {
    const [creditRequests, setCreditRequests] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [showProofModal, setShowProofModal] = useState(false);
    const [selectedProof, setSelectedProof] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 640);
    const [isTabletView, setIsTabletView] = useState(false);
    
    // Sorting and pagination states
    const [sortConfig, setSortConfig] = useState({ key: 'created_at', direction: 'desc' });
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);

    const tableRef = useRef(null);
    const [hasScroll, setHasScroll] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 640);
            setIsTabletView(window.innerWidth > 640 && window.innerWidth <= 1366);
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        fetchCreditRequests();
    }, []);

    useEffect(() => {
        const checkScroll = () => {
            if (tableRef.current) {
                setHasScroll(tableRef.current.scrollWidth > tableRef.current.clientWidth);
            }
        };

        checkScroll();
        window.addEventListener('resize', checkScroll);
        return () => window.removeEventListener('resize', checkScroll);
    }, [creditRequests]);

    // Sorting function
    const sortData = (data, key, direction) => {
        return [...data].sort((a, b) => {
            let aValue = key.split('.').reduce((obj, k) => obj?.[k], a);
            let bValue = key.split('.').reduce((obj, k) => obj?.[k], b);

            // Handle special cases
            if (key === 'amount') {
                aValue = parseFloat(aValue);
                bValue = parseFloat(bValue);
            } else if (typeof aValue === 'string') {
                aValue = aValue.toLowerCase();
                bValue = bValue.toLowerCase();
            }

            if (aValue < bValue) return direction === 'asc' ? -1 : 1;
            if (aValue > bValue) return direction === 'asc' ? 1 : -1;
            return 0;
        });
    };

    // Handle sort
    const handleSort = (key) => {
        setSortConfig(prevConfig => ({
            key,
            direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc'
        }));
    };

    // Get sorted and paginated data
    const getSortedAndPaginatedData = () => {
        const sortedData = sortData(creditRequests, sortConfig.key, sortConfig.direction);
        const startIndex = (currentPage - 1) * itemsPerPage;
        return sortedData.slice(startIndex, startIndex + itemsPerPage);
    };

    // Get sort direction indicator
    const getSortIndicator = (key) => {
        if (sortConfig.key !== key) return '↕';
        return sortConfig.direction === 'asc' ? '↑' : '↓';
    };

    // Pagination controls
    const totalPages = Math.ceil(creditRequests.length / itemsPerPage);
    const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const fetchCreditRequests = async () => {
        try {
            setLoading(true);
            setError('');
            
            const response = await axios.get('/backend/handlers/admin/get_credit_requests.php');
            
            if (response.data.success) {
                setCreditRequests(response.data.requests || []);
            } else {
                throw new Error(response.data.message || 'Failed to fetch credit requests');
            }
        } catch (err) {
            console.error('Error fetching credit requests:', err);
            setError(err.message || 'Failed to load credit requests. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const handleViewProof = (request) => {
        setSelectedProof({
            url: `/backend/handlers/get_proof_image.php?request_id=${request.request_id}&user_id=${request.user_id}`,
            amount: request.amount,
            date: request.created_at,
            status: request.status,
            username: request.username
        });
        setShowProofModal(true);
    };

    const showAlert = (message, isSuccess = true) => {
        if (isSuccess) {
            setSuccessMessage(message);
            setError('');
        } else {
            setError(message);
            setSuccessMessage('');
        }

        setTimeout(() => {
            if (isSuccess) {
                setSuccessMessage('');
            } else {
                setError('');
            }
        }, 3000);
    };

    const handleUpdateStatus = async (requestId, newStatus, username) => {
        try {
            const response = await axios.post('/backend/handlers/admin/update_credit_request.php', {
                request_id: requestId,
                status: newStatus
            });

            if (response.data.success) {
                const action = newStatus === 'approved' ? 'approved' : 'rejected';
                showAlert(`Successfully ${action} credit request for ${username}`);
                fetchCreditRequests();
            } else {
                throw new Error(response.data.message || 'Failed to update request status');
            }
        } catch (err) {
            console.error('Error updating request status:', err);
            showAlert(err.message || 'Failed to update request status. Please try again.', false);
        }
    };

    const formatPaymentMethodDetails = (method) => {
        if (!method || !method.fields) return 'N/A';
        
        try {
            const fields = Array.isArray(method.fields) ? method.fields : JSON.parse(method.fields);
            const mainFields = fields.slice(0, 2); // Show first two fields
            
            return mainFields.map(field => field.fieldValue).join(' - ');
        } catch (err) {
            console.error('Error formatting payment method:', err);
            return method.name || 'N/A';
        }
    };

    const ProofModal = ({ proof, onClose }) => {
        if (!proof) return null;

        return (
            <div className="modal-overlay" onClick={onClose}>
                <div className="proof-modal" onClick={e => e.stopPropagation()}>
                    <button className="close-modal" onClick={onClose}>&times;</button>
                    <div className="proof-details">
                        <h3>Payment Proof - {proof.username}</h3>
                        <p>Amount: ₦{parseFloat(proof.amount).toLocaleString()}</p>
                        <p>Date: {formatDate(proof.date).full}</p>
                        <p>Status: <span className={getStatusBadgeClass(proof.status)}>
                            {proof.status.charAt(0).toUpperCase() + proof.status.slice(1)}
                        </span></p>
                    </div>
                    <div className="proof-image-container">
                        <img src={proof.url} alt="Payment Proof" />
                    </div>
                </div>
            </div>
        );
    };

    const getStatusBadgeClass = (status) => {
        switch (status) {
            case 'approved':
                return 'status-badge success';
            case 'rejected':
                return 'status-badge danger';
            case 'expired':
                return 'status-badge warning';
            default:
                return 'status-badge pending';
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return {
            short: new Intl.DateTimeFormat('en-US', {
                month: 'short',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit'
            }).format(date),
            minimalist: new Intl.DateTimeFormat('en-US', {
                month: 'short',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                hour12: true
            }).format(date),
            full: new Intl.DateTimeFormat('en-US', {
                dateStyle: 'full',
                timeStyle: 'long'
            }).format(date)
        };
    };

    const MobileCard = ({ request, index }) => {
        const formattedDate = formatDate(request.created_at);
        
        return (
            <div className="request-card">
                <div className="card-header">
                    <div className="card-amount">₦{parseFloat(request.amount).toLocaleString()}</div>
                    <div className="card-date" title={formattedDate.full}>{formattedDate.short}</div>
                </div>
                <div className="card-content">
                    <div className="card-field">
                        <span className="card-label">User</span>
                        <span className="card-value">{request.username}</span>
                    </div>
                    <div className="card-field">
                        <span className="card-label">Payment Method</span>
                        <span className="card-value">{request.payment_method?.name}</span>
                    </div>
                    <div className="card-field">
                        <span className="card-label">Status</span>
                        <span className={getStatusBadgeClass(request.status)}>
                            {request.status.charAt(0).toUpperCase() + request.status.slice(1)}
                        </span>
                    </div>
                    {request.expires_at && (
                        <div className="card-field">
                            <span className="card-label">Expires</span>
                            <span className="card-value">{formatDate(request.expires_at).short}</span>
                        </div>
                    )}
                </div>
                <div className="card-actions">
                    <button 
                        className="view-proof-btn"
                        onClick={() => handleViewProof(request)}
                        aria-label="View payment proof"
                    >
                        View Proof
                    </button>
                    {request.status === 'pending' && (
                        <>
                            <button 
                                className="approve-btn"
                                onClick={() => handleUpdateStatus(request.request_id, 'approved', request.username)}
                                aria-label="Approve payment request"
                            >
                                Approve
                            </button>
                            <button 
                                className="reject-btn"
                                onClick={() => handleUpdateStatus(request.request_id, 'rejected', request.username)}
                                aria-label="Reject payment request"
                            >
                                Reject
                            </button>
                        </>
                    )}
                </div>
            </div>
        );
    };

    const TabletCard = ({ request, index }) => {
        const formattedDate = formatDate(request.created_at);
        
        return (
            <div className="tablet-request-card">
                <div className="tablet-card-header">
                    <div className="tablet-card-row">
                        <div className="tablet-card-number">#{startIndex + index + 1}</div>
                        <div className="tablet-card-date" title={formattedDate.full}>
                            {formattedDate.short}
                        </div>
                    </div>
                    <div className="tablet-card-row">
                        <div className="tablet-card-username">{request.username}</div>
                        <div className="tablet-card-amount">₦{parseFloat(request.amount).toLocaleString()}</div>
                    </div>
                </div>
                <div className="tablet-card-content">
                    <div className="tablet-card-method">
                        <div className="payment-method-info">
                            <div className="method-name">{request.payment_method?.name}</div>
                            <div className="method-details">
                                {formatPaymentMethodDetails(request.payment_method)}
                            </div>
                        </div>
                    </div>
                    <div className="tablet-card-status">
                        <span className={getStatusBadgeClass(request.status)}>
                            {request.status.charAt(0).toUpperCase() + request.status.slice(1)}
                        </span>
                    </div>
                    <div className="tablet-card-expires" title={request.expires_at ? formatDate(request.expires_at).full : ''}>
                        Expires: {request.expires_at ? formatDate(request.expires_at).short : 'N/A'}
                    </div>
                </div>
                <div className="tablet-card-actions">
                    <button 
                        className="view-proof-btn"
                        onClick={() => handleViewProof(request)}
                        aria-label="View payment proof"
                    >
                        View Proof
                    </button>
                    {request.status === 'pending' && (
                        <>
                            <button 
                                className="approve-btn"
                                onClick={() => handleUpdateStatus(request.request_id, 'approved', request.username)}
                                aria-label="Approve payment request"
                            >
                                Approve
                            </button>
                            <button 
                                className="reject-btn"
                                onClick={() => handleUpdateStatus(request.request_id, 'rejected', request.username)}
                                aria-label="Reject payment request"
                            >
                                Reject
                            </button>
                        </>
                    )}
                </div>
            </div>
        );
    };

    const TableHeader = () => (
        <thead>
            <tr>
                <th className="row-number">#</th>
                <th onClick={() => handleSort('created_at')} className="sortable date-column">
                    Date & Expires {getSortIndicator('created_at')}
                </th>
                <th onClick={() => handleSort('username')} className="sortable">
                    User {getSortIndicator('username')}
                </th>
                <th onClick={() => handleSort('amount')} className="sortable">
                    Amount {getSortIndicator('amount')}
                </th>
                <th onClick={() => handleSort('payment_method.name')} className="sortable">
                    Payment Method {getSortIndicator('payment_method.name')}
                </th>
                <th onClick={() => handleSort('status')} className="sortable">
                    Status {getSortIndicator('status')}
                </th>
                <th>Proof</th>
                <th>Actions</th>
            </tr>
        </thead>
    );

    const Pagination = () => (
        <div className="pagination">
            <button 
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className="pagination-button"
            >
                Previous
            </button>
            <div className="page-numbers">
                {pageNumbers.map(number => (
                    <button
                        key={number}
                        onClick={() => handlePageChange(number)}
                        className={`pagination-button ${currentPage === number ? 'active' : ''}`}
                    >
                        {number}
                    </button>
                ))}
            </div>
            <button 
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                className="pagination-button"
            >
                Next
            </button>
        </div>
    );

    const ScrollHint = () => (
        hasScroll && !isMobile && (
            <div className="scroll-hint">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd" />
                </svg>
                Scroll horizontally to see more
            </div>
        )
    );

    if (loading) {
        return <div className="loading">Loading...</div>;
    }

    const displayData = getSortedAndPaginatedData();
    const startIndex = (currentPage - 1) * itemsPerPage;

    return (
        <div className="transaction-management-container">
            <h1>Credit Request Management</h1>

            {error && <div className="error-message">{error}</div>}
            {successMessage && <div className="success-message">{successMessage}</div>}

            {creditRequests.length > 0 ? (
                <div className={`credit-requests-table ${hasScroll ? 'has-scroll' : ''}`} ref={tableRef}>
                    <ScrollHint />
                    {isMobile ? (
                        <div className="mobile-cards">
                            {displayData.map((request, index) => (
                                <MobileCard 
                                    key={request.request_id} 
                                    request={request} 
                                    index={startIndex + index + 1}
                                />
                            ))}
                        </div>
                    ) : isTabletView ? (
                        <div className="tablet-cards">
                            {displayData.map((request, index) => (
                                <TabletCard 
                                    key={request.request_id} 
                                    request={request} 
                                    index={index}
                                />
                            ))}
                        </div>
                    ) : (
                        <>
                            <div className="table-container">
                                <table>
                                    <TableHeader />
                                    <tbody>
                                        {displayData.map((request, index) => (
                                            <tr key={request.request_id}>
                                                <td className="row-number">{startIndex + index + 1}</td>
                                                <td className="date-expires" 
                                                    title={`Created: ${formatDate(request.created_at).full}${request.expires_at ? `\nExpires: ${formatDate(request.expires_at).full}` : ''}`}>
                                                    <div className="date-content">
                                                        <div className="date-line">
                                                            <span className="date-label">Date:</span>
                                                            <span className="created-date">{formatDate(request.created_at).minimalist}</span>
                                                        </div>
                                                        {request.expires_at && (
                                                            <div className="expires-line">
                                                                <span className="date-label">Exp:</span>
                                                                <span className="expires-date">{formatDate(request.expires_at).minimalist}</span>
                                                            </div>
                                                        )}
                                                    </div>
                                                </td>
                                                <td className="username-display">{request.username}</td>
                                                <td className="amount">₦{parseFloat(request.amount).toLocaleString()}</td>
                                                <td>
                                                    <div className="payment-method-info">
                                                        <div className="method-name">{request.payment_method?.name}</div>
                                                        <div className="method-details">
                                                            {formatPaymentMethodDetails(request.payment_method)}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <span className={getStatusBadgeClass(request.status)}>
                                                        {request.status.charAt(0).toUpperCase() + request.status.slice(1)}
                                                    </span>
                                                </td>
                                                <td>
                                                    <button 
                                                        className="view-proof-btn"
                                                        onClick={() => handleViewProof(request)}
                                                        aria-label="View payment proof"
                                                    >
                                                        View
                                                    </button>
                                                </td>
                                                <td>
                                                    {request.status === 'pending' && (
                                                        <div className="action-buttons">
                                                            <button 
                                                                className="approve-btn"
                                                                onClick={() => handleUpdateStatus(request.request_id, 'approved', request.username)}
                                                                aria-label="Approve payment request"
                                                            >
                                                                Approve
                                                            </button>
                                                            <button 
                                                                className="reject-btn"
                                                                onClick={() => handleUpdateStatus(request.request_id, 'rejected', request.username)}
                                                                aria-label="Reject payment request"
                                                            >
                                                                Reject
                                                            </button>
                                                        </div>
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <Pagination />
                        </>
                    )}
                </div>
            ) : (
                <div className="no-requests">
                    <p>No credit requests found.</p>
                </div>
            )}

            {showProofModal && (
                <ProofModal 
                    proof={selectedProof} 
                    onClose={() => {
                        setShowProofModal(false);
                        setSelectedProof(null);
                    }}
                />
            )}
        </div>
    );
}

export default TransactionManagement;
