import React, { useState } from 'react';
import { useNavigate, NavLink, useLocation } from 'react-router-dom';
import { 
    FaChevronDown, 
    FaGamepad, 
    FaUsers, 
    FaCog, 
    FaChartBar, 
    FaMoneyBill, 
    FaTrophy,
    FaHome,
    FaUserPlus,
    FaCreditCard,
    FaFutbol,
    FaTasks,
    FaCoins,
    FaCalendarAlt,
    FaUserFriends,
    FaCashRegister,
    FaExchangeAlt,
    FaMedal,
    FaWrench,
    FaChartLine,
    FaShieldAlt,
    FaBars
} from 'react-icons/fa';
import './AdminSidebar.css';

function Sidebar({ isCollapsed, toggleCollapse }) {
    const navigate = useNavigate();
    const location = useLocation();
    
    const [openMenus, setOpenMenus] = useState({
        dashboard: false,
        users: false,
        challenges: false,
        leagues: false,
        finance: false,
        system: false
    });

    const handleLogout = () => {
        localStorage.removeItem('adminId');
        localStorage.removeItem('adminUsername');
        localStorage.removeItem('adminRole');
        navigate('/admin/login');
    };

    const toggleMenu = (menuKey) => {
        if (!isCollapsed) {
            setOpenMenus(prev => ({
                ...prev,
                [menuKey]: !prev[menuKey]
            }));
        }
    };

    const menuStructure = {
        dashboard: {
            title: 'Dashboard',
            icon: <FaChartBar />,
            items: [
                { link: '/admin/dashboard', text: 'Overview', icon: <FaHome /> }
            ]
        },
        challenges: {
            title: 'Challenges',
            icon: <FaGamepad />,
            items: [
                { link: '/admin/challenge-system', text: 'Challenge System', icon: <FaFutbol /> },
                { link: '/admin/challenge-management', text: 'Challenge Management', icon: <FaTasks /> },
                { link: '/admin/credit-challenge', text: 'Credit Challenge', icon: <FaCoins /> },
                { link: '/admin/team-management', text: 'Team Management', icon: <FaShieldAlt /> }
            ]
        },
        users: {
            title: 'Users',
            icon: <FaUsers />,
            items: [
                { link: '/admin/users', text: 'User Management', icon: <FaUsers /> },
                { link: '/admin/add-user', text: 'Add User', icon: <FaUserPlus /> },
                { link: '/admin/credit-user', text: 'Credit User', icon: <FaCreditCard /> }
            ]
        },
        leagues: {
            title: 'Leagues',
            icon: <FaTrophy />,
            items: [
                { link: '/admin/league-management', text: 'League Management', icon: <FaTrophy /> },
                { link: '/admin/league-seasons', text: 'Season Management', icon: <FaCalendarAlt /> },
                { link: '/admin/league-users', text: 'League Users', icon: <FaUserFriends /> }
            ]
        },
        finance: {
            title: 'Finance',
            icon: <FaMoneyBill />,
            items: [
                { link: '/admin/payment-methods', text: 'Payment Methods', icon: <FaCashRegister /> },
                { link: '/admin/transactions', text: 'Transactions', icon: <FaExchangeAlt /> }
            ]
        },
        system: {
            title: 'System',
            icon: <FaCog />,
            items: [
                { link: '/admin/leaderboard', text: 'Leaderboard Management', icon: <FaMedal /> },
                { link: '/admin/settings', text: 'System Settings', icon: <FaWrench /> },
                { link: '/admin/reports', text: 'Reports and Analytics', icon: <FaChartLine /> }
            ]
        }
    };

    return (
        <div className={`admin-sidebar ${isCollapsed ? 'collapsed' : ''}`}>
            <div className="sidebar-header">
                <div className="logo">
                    {!isCollapsed && 'FanBet247'}
                </div>
                <button className="toggle-button" onClick={toggleCollapse}>
                    <FaBars />
                </button>
            </div>
            <nav className="admin-sidebar-nav">
                {Object.entries(menuStructure).map(([key, menu]) => (
                    <div key={key} className="admin-menu-group">
                        <div 
                            className={`admin-menu-header ${location.pathname.includes(key) ? 'active' : ''}`}
                            onClick={() => toggleMenu(key)}
                            title={isCollapsed ? menu.title : ''}
                        >
                            <span className="admin-menu-icon">{menu.icon}</span>
                            {!isCollapsed && (
                                <>
                                    <span className="admin-menu-title">{menu.title}</span>
                                    <FaChevronDown 
                                        className={`admin-menu-arrow ${openMenus[key] ? 'rotated' : ''}`} 
                                    />
                                </>
                            )}
                        </div>
                        {(!isCollapsed || openMenus[key]) && (
                            <div className={`admin-menu-items ${openMenus[key] ? 'open' : ''}`}>
                                {menu.items.map((item, index) => (
                                    <NavLink
                                        key={index}
                                        to={item.link}
                                        className={({ isActive }) => 
                                            `admin-nav-item ${isActive ? 'active' : ''}`
                                        }
                                        title={isCollapsed ? item.text : ''}
                                        end
                                    >
                                        <span className="admin-nav-item-icon">{item.icon}</span>
                                        {!isCollapsed && (
                                            <span className="admin-nav-item-text">{item.text}</span>
                                        )}
                                    </NavLink>
                                ))}
                            </div>
                        )}
                    </div>
                ))}
            </nav>
        </div>
    );
}

export default Sidebar;