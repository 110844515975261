import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from '../utils/axiosConfig';
import './JoinChallenge.css';
import '../styles/TeamLogo.css';

const API_BASE_URL = '/backend';

function JoinChallenge() {
  const { challengeId } = useParams();
  const navigate = useNavigate();
  const [challenge, setChallenge] = useState(null);
  const [selectedOutcome, setSelectedOutcome] = useState('');
  const [betAmount, setBetAmount] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [teams, setTeams] = useState([]);
  const [potentialReturns, setPotentialReturns] = useState({
    win: 0,
    draw: challenge?.odds_draw || 0,
    loss: challenge?.odds_lost || 0
  });

  // Improved teams fetching with retry
  const fetchTeams = useCallback(async (retryCount = 0) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/handlers/team_management.php`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('userToken')}`,
          'Content-Type': 'application/json'
        }
      });
      if (response.data.status === 200) {
        setTeams(response.data.data);
      }
    } catch (err) {
      console.error('Error fetching teams:', err);
      if (retryCount < 2) {
        setTimeout(() => fetchTeams(retryCount + 1), 1000);
      }
    }
  }, []);

  // Improved challenge fetching with retry and better response handling
  const fetchChallengeDetails = useCallback(async (retryCount = 0) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/handlers/get_challenges.php`, {
        params: { id: challengeId },
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('userToken')}`,
          'Content-Type': 'application/json'
        }
      });
      
      if (response.data.success) {
        if (response.data.challenge) {
          setChallenge(response.data.challenge);
          setErrorMessage(null);
          return;
        }
      }
      
      // If we reach here, no challenge was found
      if (retryCount < 2) {
        setTimeout(() => fetchChallengeDetails(retryCount + 1), 1000);
      } else {
        setErrorMessage('Challenge not found. Please try again.');
      }
    } catch (error) {
      console.error('Error fetching challenge details:', error);
      const errorMessage = error.response?.data?.message || 'Failed to load challenge details. Please refresh the page.';
      setErrorMessage(errorMessage);
      if (retryCount < 2) {
        setTimeout(() => fetchChallengeDetails(retryCount + 1), 1000);
      }
    }
  }, [challengeId]);

  const calculatePotentialReturns = useCallback(() => {
    if (!betAmount || !challenge) return;
    const amount = parseFloat(betAmount);
    let winOdds = 0;
    
    try {
      if (selectedOutcome === 'team_a_win') {
        winOdds = parseFloat(challenge.odds_team_a) || 0;
      } else if (selectedOutcome === 'team_b_win') {
        winOdds = parseFloat(challenge.odds_team_b) || 0;
      } else if (selectedOutcome === 'draw') {
        winOdds = parseFloat(challenge.odds_draw) || 0;
      } else {
        return;
      }

      const drawOdds = parseFloat(challenge.odds_draw) || 0.9;
      const lossOdds = parseFloat(challenge.odds_lost) || 0.2;

      setPotentialReturns({
        win: (amount * winOdds).toFixed(2),
        draw: (amount * drawOdds).toFixed(2),
        loss: (amount * lossOdds).toFixed(2),
      });
    } catch (err) {
      console.error('Error calculating returns:', err);
    }
  }, [betAmount, selectedOutcome, challenge]);

  useEffect(() => {
    if (challengeId) {
      fetchChallengeDetails();
      fetchTeams();
    }
  }, [fetchChallengeDetails, fetchTeams, challengeId]);

  useEffect(() => {
    calculatePotentialReturns();
  }, [calculatePotentialReturns]);

  const getTeamLogo = useCallback((teamName) => {
    const team = teams.find(t => t.name === teamName);
    return team ? `${API_BASE_URL}/${team.logo}` : '';
  }, [teams]);

  const handleSubmitBet = async (event) => {
    event.preventDefault();
    setErrorMessage(null);

    if (!selectedOutcome || !betAmount) {
      setErrorMessage('Please select an outcome and enter a bet amount.');
      return;
    }

    try {
      const userId = localStorage.getItem('userId');
      let odds;
      if (selectedOutcome === 'team_a_win') {
        odds = challenge.odds_team_a;
      } else if (selectedOutcome === 'team_b_win') {
        odds = challenge.odds_team_b;
      } else if (selectedOutcome === 'draw') {
        odds = challenge.odds_draw;
      }
      
      const amount = parseFloat(betAmount);
      const winAmount = amount * odds;
      const drawAmount = amount * (challenge.odds_draw || 0.9);
      const lossAmount = amount * (challenge.odds_lost || 0.2);

      const response = await axios.post(`${API_BASE_URL}/handlers/place_bet.php`, {
        challengeId,
        userId,
        outcome: selectedOutcome,
        amount: betAmount,
        odds,
        potential_return_user1: winAmount,
        potential_return_win_user1: winAmount,
        potential_return_draw_user1: drawAmount,
        potential_return_loss_user1: lossAmount
      }, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('userToken')}`,
          'Content-Type': 'application/json'
        }
      });

      if (response.data.success) {
        const betId = response.data.bet_id;
        setSuccessMessage('Bet placed successfully! Redirecting to your bets...');
        // Clear form
        setSelectedOutcome('');
        setBetAmount('');
        
        // Use the correct route path and ensure state updates are complete
        const timer = setTimeout(() => {
          navigate('/user/bets/outgoing', { 
            state: { newBetId: betId },
            replace: true  // Replace current history entry
          });
        }, 4000);
        
        // Cleanup timer if component unmounts
        return () => clearTimeout(timer);
      } else {
        throw new Error(response.data.message || 'Failed to place bet');
      }
    } catch (error) {
      console.error('Error placing bet:', error);
      const errorMessage = error.response?.data?.message || 'An error occurred while placing your bet.';
      setErrorMessage(errorMessage);
    }
  };

  if (!challenge && !errorMessage) {
    return <div className="join-challenge"><p>Loading challenge details...</p></div>;
  }

  return (
    <div className="join-challenge">
      <h1>Join Challenge</h1>
      {errorMessage && <div className="error-message">{errorMessage}</div>}
      {successMessage && <div className="success-message">{successMessage}</div>}
      {challenge && (
        <div className="centered-layout">
          <div className="challenge-details">
            <div className="team-info">
              <div className="challenge-team-wrapper">
                <img
                  src={getTeamLogo(challenge.team_a)}
                  alt={challenge.team_a}
                  className="challenge-team-logo"
                  onError={(e) => {
                    e.target.src = '/default-team-logo.png';
                  }}
                />
                <span className="challenge-team-name">{challenge.team_a}</span>
              </div>
            </div>
            
            <div className="vs-container">
              <span className="vs">VS</span>
            </div>
            
            <div className="team-info">
              <div className="challenge-team-wrapper">
                <img
                  src={getTeamLogo(challenge.team_b)}
                  alt={challenge.team_b}
                  className="challenge-team-logo"
                  onError={(e) => {
                    e.target.src = '/default-team-logo.png';
                  }}
                />
                <span className="challenge-team-name">{challenge.team_b}</span>
              </div>
            </div>
          </div>

          <div className="match-details-grid">
            <div className="detail-item match-type">
              <div className="label">Match Type</div>
              <div className="value">{challenge.match_type === 'full_time' ? 'Full Time' : 'Half Time'}</div>
            </div>
            
            <div className="detail-item odds">
              <div className="label">{challenge.team_a} Odds</div>
              <div className="value">{challenge.odds_team_a}</div>
            </div>
            
            <div className="detail-item odds">
              <div className="label">{challenge.team_b} Odds</div>
              <div className="value">{challenge.odds_team_b}</div>
            </div>
            
            <div className="detail-item odds">
              <div className="label">Draw Odds</div>
              <div className="value">{challenge.odds_draw}</div>
            </div>
            
            <div className="detail-item odds">
              <div className="label">Loss Odds</div>
              <div className="value">{challenge.odds_lost}</div>
            </div>
            
            <div className="detail-item">
              <div className="label">Goal Advantage</div>
              <div className="value">{challenge.team_a_goal_advantage} - {challenge.team_b_goal_advantage}</div>
            </div>
            
            <div className="detail-item date">
              <div className="label">Match Date</div>
              <div className="value">{new Date(challenge.match_date).toLocaleString()}</div>
            </div>
          </div>

          <form className="bet-form" onSubmit={handleSubmitBet}>
            <div>
              <label htmlFor="outcome">Select Outcome:</label>
              <select id="outcome" value={selectedOutcome} onChange={(e) => setSelectedOutcome(e.target.value)}>
                <option value="">-- Select --</option>
                <option value="team_a_win">Home Win ({challenge.team_a})</option>
                <option value="team_b_win">Away Win ({challenge.team_b})</option>
                <option value="draw">Draw</option>
              </select>
            </div>
            <div>
              <label htmlFor="amount">Bet Amount (FanCoins):</label>
              <input
                type="number"
                id="amount"
                value={betAmount}
                onChange={(e) => setBetAmount(e.target.value)}
              />
            </div>
            {betAmount && selectedOutcome && (
              <div className="potential-returns">
                <h3>Expected Returns</h3>
                <div className="returns-grid">
                  <div className="return-item win">
                    <div className="label">Win</div>
                    <div className="value-container">
                      <div className="value">{Number(potentialReturns.win).toFixed(2)}</div>
                      <div className="currency">FanCoins</div>
                    </div>
                  </div>
                  <div className="return-item draw">
                    <div className="label">Draw</div>
                    <div className="value-container">
                      <div className="value">{Number(potentialReturns.draw).toFixed(2)}</div>
                      <div className="currency">FanCoins</div>
                    </div>
                  </div>
                  <div className="return-item loss">
                    <div className="label">Loss Return</div>
                    <div className="value-container">
                      <div className="value">{Number(potentialReturns.loss).toFixed(2)}</div>
                      <div className="currency">FanCoins</div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <button type="submit">Place Bet</button>
          </form>
        </div>
      )}
    </div>
  );
}

export default JoinChallenge;