import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import OldSidebar from '../OldSidebar';
import Header from '../Header';
import Footer from '../Footer';
import ScrollToTop from '../ScrollToTop';
import axios from 'axios';
import './MainLayout.css';

const API_BASE_URL = '/backend';

function MainLayout({ children }) {
  const [userName, setUserName] = useState(null);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(window.innerWidth <= 1440);
  const [screenSize, setScreenSize] = useState(getScreenSize());
  const navigate = useNavigate();

  function getScreenSize() {
    const width = window.innerWidth;
    if (width <= 768) return 'mobile';
    if (width <= 1366) return '13inch';
    if (width <= 1440) return '14inch';
    return 'large';
  }

  useEffect(() => {
    const userId = localStorage.getItem('userId');
    if (userId) {
      fetchUserName(userId);
    }

    const handleResize = () => {
      const newScreenSize = getScreenSize();
      setScreenSize(newScreenSize);
      if (newScreenSize === '14inch' || newScreenSize === '13inch') {
        setIsSidebarCollapsed(true);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const fetchUserName = async (userId) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/handlers/get_username.php?user_id=${userId}`);
      if (response.data.success) {
        setUserName(response.data.username);
      }
    } catch (error) {
      console.error("Error fetching username:", error);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('userId');
    setUserName(null);
    navigate('/');
  };

  const toggleSidebar = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };

  return (
    <div className={`main-layout ${screenSize}`}>
      <OldSidebar
        handleLogout={handleLogout}
        isCollapsed={isSidebarCollapsed}
        onToggle={toggleSidebar}
        screenSize={screenSize}
      />
      <div className={`layout-content ${isSidebarCollapsed ? 'sidebar-collapsed' : ''}`}>
        <Header
          userName={userName}
          handleLogout={handleLogout}
          isSidebarCollapsed={isSidebarCollapsed}
          toggleSidebar={toggleSidebar}
          screenSize={screenSize}
        />
        <main className="layout-main">
          <div className="content-container">
            {children}
          </div>
          <Footer />
        </main>
      </div>
      <ScrollToTop />
    </div>
  );
}

export default MainLayout;