import axios from 'axios';

// Set default config for all axios requests
axios.defaults.withCredentials = true;  // Enable credentials for all requests
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

// Add request interceptor to add auth token
axios.interceptors.request.use(
    (config) => {
        // Don't add token if Authorization header is already set
        if (!config.headers.Authorization) {
            const token = localStorage.getItem('userToken');
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
                console.log('Added auth token to request:', config.url);
            } else {
                console.log('No auth token found for request:', config.url);
            }
        } else {
            console.log('Using custom Authorization header:', config.url);
        }
        return config;
    },
    (error) => {
        console.error('Request interceptor error:', error);
        return Promise.reject(error);
    }
);

// Add response interceptor to handle auth errors
axios.interceptors.response.use(
    (response) => response,
    (error) => {
        console.error('Response error:', error.response?.status, error.response?.data);
        if (error.response?.status === 401 && !error.config.url.includes('join_league.php')) {
            console.log('Unauthorized access, redirecting to login');
            // Clear auth data and redirect to login
            localStorage.removeItem('userToken');
            localStorage.removeItem('userId');
            window.location.href = '/login';
        }
        return Promise.reject(error);
    }
);

export default axios;
