import React, { createContext, useContext, useState, useCallback } from 'react';
import ErrorAlert from '../components/ErrorAlert';
import { handleError, retryOperation } from '../utils/errorHandler';

const ErrorContext = createContext(null);

export const useError = () => {
  const context = useContext(ErrorContext);
  if (!context) {
    throw new Error('useError must be used within an ErrorProvider');
  }
  return context;
};

export const ErrorProvider = ({ children }) => {
  const [errors, setErrors] = useState([]);

  const addError = useCallback((error) => {
    const appError = error.__proto__.constructor.name === 'AppError' 
      ? error 
      : handleError(error);
    
    setErrors(prev => [...prev, { id: Date.now(), error: appError }]);

    // Auto-remove error after 5 seconds if it's not critical
    if (appError.severity !== 'critical') {
      setTimeout(() => {
        removeError(appError.id);
      }, 5000);
    }
  }, []);

  const removeError = useCallback((errorId) => {
    setErrors(prev => prev.filter(e => e.id !== errorId));
  }, []);

  const clearErrors = useCallback(() => {
    setErrors([]);
  }, []);

  // Wrapper for async operations with error handling
  const withErrorHandling = useCallback(async (operation, context = {}) => {
    try {
      return await retryOperation(operation);
    } catch (error) {
      addError(handleError(error, context));
      throw error; // Re-throw to allow local handling if needed
    }
  }, [addError]);

  return (
    <ErrorContext.Provider value={{ addError, removeError, clearErrors, withErrorHandling }}>
      {children}
      <div className="error-alerts-container">
        {errors.map(({ id, error }) => (
          <ErrorAlert
            key={id}
            error={error}
            onClose={() => removeError(id)}
          />
        ))}
      </div>
    </ErrorContext.Provider>
  );
}; 