import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './utils/axiosConfig';  // Import axios configuration
import { UserProvider } from './context/UserContext';
import { ErrorProvider } from './contexts/ErrorContext';

// Layouts
import AdminLayout from './components/AdminLayout';
import UserLayout from './components/UserLayout';

// Components
import Messages from './components/Messages/Messages';

// Public Pages
import WelcomeSplash from './pages/WelcomeSplash';
import AdminLoginPage from './pages/AdminLoginPage';
import UserLogin from './pages/UserLogin';
import UserRegistration from './pages/UserRegistration';

// Admin Pages
import AdminDashboard from './pages/AdminDashboard';
import ChallengeSystem from './pages/ChallengeSystem';
import UserManagement from './pages/UserManagement';
import BetManagement from './pages/BetManagement';
import TransactionManagement from './pages/TransactionManagement';
import LeaderboardManagement from './pages/LeaderboardManagement';
import SystemSettings from './pages/SystemSettings';
import ReportsAnalytics from './pages/ReportsAnalytics';
import AddUser from './pages/AddUser';
import PaymentMethods from './pages/PaymentMethods';
import CreditUser from './pages/CreditUser';
import DebitUser from './pages/DebitUser';
import TeamManagement from './pages/TeamManagement';
import ChallengeManagement from './pages/ChallengeManagement';
import CreditChallenge from './pages/CreditChallenge';
import LeagueManagement from './pages/LeagueManagement';
import LeagueSeasonManagement from './pages/LeagueSeasonManagement';
import CreateLeague from './pages/CreateLeague';
import LeagueDetails from './pages/LeagueDetails';
import LeagueUserManagement from './pages/LeagueUserManagement';

// User Pages
import UserDashboard from './pages/UserDashboard';
import JoinChallenge from './pages/JoinChallenge';
import JoinChallenge2 from './pages/JoinChallenge2';
import ViewBets from './pages/ViewBets';
import IncomingBets from './pages/IncomingBets';
import Profile from './pages/Profile';
import AcceptedBets from './pages/AcceptedBets';
import PaymentHistory from './pages/PaymentHistory';
import Leaderboard from './pages/Leaderboard';
import ChangePassword from './pages/ChangePassword';
import Deposit from './pages/Deposit';
import Withdraw from './pages/Withdraw';
import Friends from './pages/Friends';
import FriendRequests from './pages/FriendRequests';
import LeagueHome from './pages/LeagueHome';
import LeagueSelection from './pages/LeagueSelection';
import UserAchievements from './pages/UserAchievements';
import SeasonHistory from './pages/SeasonHistory';
import MyLeagues from './pages/MyLeagues';
import Transfer from './pages/Transfer';
import CreditWallet from './pages/CreditWallet';
import CreditHistory from './pages/CreditHistory';
import Challenges from './pages/Challenges';
import RecentBets from './pages/RecentBets';

// Protected Route Component
const ProtectedRoute = ({ children }) => {
  const isAuthenticated = localStorage.getItem('userId');
  
  if (!isAuthenticated) {
    // Save the current path for redirect after login
    const currentPath = window.location.pathname;
    if (currentPath !== '/login') {
      sessionStorage.setItem('redirectAfterLogin', currentPath);
    }
    return <Navigate to="/login" replace />;
  }
  
  return children;
};

function App() {
  return (
    <UserProvider>
      <ErrorProvider>
        <div className="App">
          <Router>
            <Routes>
              {/* Public Routes */}
              <Route path="/" element={<WelcomeSplash />} />
              <Route path="/login" element={<UserLogin />} />
              <Route path="/register" element={<UserRegistration />} />
              <Route path="/admin/login" element={<AdminLoginPage />} />

              {/* Admin Routes */}
              <Route path="/admin" element={<AdminLayout />}>
                <Route index element={<AdminDashboard />} />
                <Route path="dashboard" element={<AdminDashboard />} />
                <Route path="challenge-system" element={<ChallengeSystem />} />
                <Route path="challenge-management" element={<ChallengeManagement />} />
                <Route path="credit-challenge" element={<CreditChallenge />} />
                <Route path="team-management" element={<TeamManagement />} />
                {/* League Management Routes */}
                <Route path="league-management" element={<LeagueManagement />} />
                <Route path="league-management/create" element={<CreateLeague />} />
                <Route path="league-seasons" element={<LeagueSeasonManagement />} />
                <Route path="league-divisions" element={<LeagueManagement />} />
                <Route path="league-rewards" element={<LeagueManagement />} />
                <Route path="league-management/:leagueId/seasons" element={<LeagueSeasonManagement />} />
                <Route path="league-users" element={<LeagueUserManagement />} />
                {/* Other Admin Routes */}
                <Route path="users" element={<UserManagement />} />
                <Route path="add-user" element={<AddUser />} />
                <Route path="credit-user" element={<CreditUser />} />
                <Route path="debit-user" element={<DebitUser />} />
                <Route path="payment-methods" element={<PaymentMethods />} />
                <Route path="bets" element={<BetManagement />} />
                <Route path="transactions" element={<TransactionManagement />} />
                <Route path="leaderboard" element={<LeaderboardManagement />} />
                <Route path="settings" element={<SystemSettings />} />
                <Route path="reports" element={<ReportsAnalytics />} />
              </Route>

              {/* User Routes */}
              <Route
                path="/user"
                element={
                  <ProtectedRoute>
                    <UserLayout />
                  </ProtectedRoute>
                }
              >
                <Route index element={<UserDashboard />} />
                <Route path="dashboard" element={<UserDashboard />} />
                <Route path="bets" element={<ViewBets />} />
                <Route path="bets/outgoing" element={<ViewBets />} />
                <Route path="bets/incoming" element={<IncomingBets />} />
                <Route path="bets/accepted" element={<AcceptedBets />} />
                <Route path="payment-history" element={<PaymentHistory />} />
                <Route path="leaderboard" element={<Leaderboard />} />
                <Route path="profile" element={<Profile />} />
                <Route path="profile/:username" element={<Profile />} />
                <Route path="change-password" element={<ChangePassword />} />
                <Route path="deposit" element={<Deposit />} />
                <Route path="withdraw" element={<Withdraw />} />
                <Route path="friends" element={<Friends />} />
                <Route path="friend-requests" element={<FriendRequests />} />
                <Route path="join-challenge/:challengeId" element={<JoinChallenge />} />
                <Route path="join-challenge2/:challengeId/:betId/:uniqueCode/:user1Id" element={<JoinChallenge2 />} />
                <Route path="messages" element={<Messages />} />
                <Route path="challenges" element={<Challenges />} />
                <Route path="recent-bets" element={<RecentBets />} />
                {/* League Routes */}
                <Route path="leagues" element={<LeagueHome />} />
                <Route path="my-leagues" element={<MyLeagues />} />
                <Route path="leagues/:leagueId" element={<LeagueDetails />} />
                <Route path="leagues/achievements" element={<UserAchievements />} />
                <Route path="leagues/seasons" element={<SeasonHistory />} />
                {/* Legacy League Routes - Keep for backward compatibility */}
                <Route path="league" element={<Navigate to="/user/leagues" replace />} />
                <Route path="league/:leagueId/selection" element={<Navigate to="/user/leagues/:leagueId" replace />} />
                <Route path="league/:leagueId/leaderboard" element={<Navigate to="/user/leagues/:leagueId/leaderboard" replace />} />
                <Route path="achievements" element={<Navigate to="/user/leagues/achievements" replace />} />
                <Route path="season-history" element={<Navigate to="/user/leagues/seasons" replace />} />
                <Route path="transfer" element={<Transfer />} />
                <Route path="wallet" element={<CreditWallet />} />
                <Route path="credit-history" element={<CreditHistory />} />
              </Route>

              {/* Catch all route - redirect to dashboard if authenticated, otherwise to login */}
              <Route
                path="*"
                element={
                  localStorage.getItem('userId') && localStorage.getItem('userToken') ? (
                    <Navigate to="/user/dashboard" replace />
                  ) : (
                    <Navigate to="/login" replace />
                  )
                }
              />
            </Routes>
          </Router>
        </div>
      </ErrorProvider>
    </UserProvider>
  );
}

export default App;
