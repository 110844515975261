import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ChallengeSystem.css';
const API_BASE_URL = '/backend';

function ChallengeSystem() {
  const [challenges, setChallenges] = useState([]);
  const [teams, setTeams] = useState([]);
  const [newChallenge, setNewChallenge] = useState({
    team1: '',
    team2: '',
    odds1: 1,
    odds2: 1,
    goalAdvantage1: 0,
    goalAdvantage2: 0,
    startTime: '',
    endTime: '',
    matchTime: '',
    matchType: 'full_time',
    oddsDraw: 0.8,
    oddsLost: 0.2,
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    fetchTeams();
  }, []);

  const fetchTeams = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/handlers/team_management.php`);
      setTeams(response.data.data);
    } catch (err) {
      console.error("Error fetching teams:", err);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewChallenge(prev => ({
      ...prev,
      [name]: value 
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    if (!newChallenge.team1 || !newChallenge.team2 || !newChallenge.odds1 || !newChallenge.odds2) {
      setError('Team names and odds are required.');
      return;
    }

    try {
      const formData = new FormData();
      const team1Data = teams.find(t => t.name === newChallenge.team1);
      const team2Data = teams.find(t => t.name === newChallenge.team2);

      formData.append('team1', newChallenge.team1);
      formData.append('team2', newChallenge.team2);
      formData.append('odds1', newChallenge.odds1);
      formData.append('odds2', newChallenge.odds2);
      formData.append('goalAdvantage1', newChallenge.goalAdvantage1);
      formData.append('goalAdvantage2', newChallenge.goalAdvantage2);
      formData.append('startTime', newChallenge.startTime);
      formData.append('endTime', newChallenge.endTime);
      formData.append('matchTime', newChallenge.matchTime);
      formData.append('matchType', newChallenge.matchType);
      formData.append('oddsDraw', newChallenge.oddsDraw);
      formData.append('oddsLost', newChallenge.oddsLost);
      formData.append('logo1', team1Data ? `${API_BASE_URL}/${team1Data.logo}` : '');
      formData.append('logo2', team2Data ? `${API_BASE_URL}/${team2Data.logo}` : '');

      const response = await axios.post(`${API_BASE_URL}/handlers/create_challenge.php`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });

      if (response.data.success) {
        setSuccess('Challenge created successfully!');
        setNewChallenge({
          team1: '',
          team2: '',
          odds1: 1,
          odds2: 1,
          goalAdvantage1: 0,
          goalAdvantage2: 0,
          startTime: '',
          endTime: '',
          matchTime: '',
          matchType: 'full_time',
          oddsDraw: 0.8,
          oddsLost: 0.2,
        });
        setTimeout(() => {
          setSuccess('');
        }, 3000);
      } else {
        setError(response.data.message || 'Failed to create challenge');
        setTimeout(() => {
          setError('');
        }, 3000);
      }
    } catch (err) {
      setError('Failed to create challenge');
    }
  };

  const handleDiscard = () => {
    setNewChallenge({
      team1: '', team2: '', odds1: 1, odds2: 1, 
      goalAdvantage1: 0, goalAdvantage2: 0,
      startTime: '', endTime: '', matchTime: '',
      matchType: 'full_time',
      oddsDraw: 0.8,
      oddsLost: 0.2,
    });
    setError('');
    setSuccess('');
  };

  return (
    <div className="challenge-system">
      <h1>Create a New Challenge</h1>
      <div className="header-actions">
        <button onClick={handleSubmit}>Save Challenge</button>
        <button onClick={handleDiscard}>Discard Changes</button>
      </div>
      {error && <div className="error-message">{error}</div>}
      {success && <div className="success-message">{success}</div>}
      <form onSubmit={handleSubmit} className="challenge-form">
        <div className="match-settings">
          <div className="match-type-section">
            <h3>Match Settings</h3>
            <div className="form-group">
              <label htmlFor="matchType">Match Type:</label>
              <select
                id="matchType"
                name="matchType"
                value={newChallenge.matchType}
                onChange={handleInputChange}
                required
              >
                <option value="full_time">Full Time</option>
                <option value="half_time">Half Time</option>
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="oddsDraw">Draw Odds:</label>
              <input
                type="number"
                id="oddsDraw"
                name="oddsDraw"
                value={newChallenge.oddsDraw}
                onChange={handleInputChange}
                step="0.1"
                min="0"
              />
              <p className="odds-explanation">Default: 0.8</p>
            </div>
            <div className="form-group">
              <label htmlFor="oddsLost">Lost Odds:</label>
              <input
                type="number"
                id="oddsLost"
                name="oddsLost"
                value={newChallenge.oddsLost}
                onChange={handleInputChange}
                step="0.1"
                min="0"
              />
              <p className="odds-explanation">Default: 0.2</p>
            </div>
          </div>
        </div>

        <div className="team-container">
          <div className="team-section team1">
            <h3>Team 1</h3>
            <label htmlFor="team1">Select Team 1:</label>
            <select id="team1" name="team1" value={newChallenge.team1} onChange={handleInputChange} required>
              <option value="">Select a team</option>
              {teams.map((team) => (
                <option key={team.id} value={team.name}>
                  {team.name}
                </option>
              ))}
            </select>

            {newChallenge.team1 && (
              <div className="logo-container">
                <img
                  src={`${API_BASE_URL}/${teams.find(t => t.name === newChallenge.team1)?.logo}`}
                  alt="Team 1 Logo"
                  className="logo-preview"
                />
              </div>
            )}

            <label htmlFor="odds1">Odds for Team 1:</label>
            <input
              type="number"
              id="odds1"
              name="odds1"
              value={newChallenge.odds1}
              onChange={handleInputChange}
              required
              step="0.01"
              min="1"
            />
            <p className="odds-explanation">
              User's bet x {newChallenge.odds1} = Potential winnings
            </p>

            <label htmlFor="goalAdvantage1">Goal Advantage for Team 1:</label>
            <input type="number" id="goalAdvantage1" name="goalAdvantage1" value={newChallenge.goalAdvantage1} onChange={handleInputChange} />
          </div>

          <div className="team-section team2">
            <h3>Team 2</h3>
            <label htmlFor="team2">Select Team 2:</label>
            <select id="team2" name="team2" value={newChallenge.team2} onChange={handleInputChange} required>
              <option value="">Select a team</option>
              {teams.map((team) => (
                <option key={team.id} value={team.name}>
                  {team.name}
                </option>
              ))}
            </select>

            {newChallenge.team2 && (
              <div className="logo-container">
                <img
                  src={`${API_BASE_URL}/${teams.find(t => t.name === newChallenge.team2)?.logo}`}
                  alt="Team 2 Logo"
                  className="logo-preview"
                />
              </div>
            )}

            <label htmlFor="odds2">Odds for Team 2:</label>
            <input
              type="number"
              id="odds2"
              name="odds2"
              value={newChallenge.odds2}
              onChange={handleInputChange}
              required
              step="0.01"
              min="1"
            />
            <p className="odds-explanation">
              User's bet x {newChallenge.odds2} = Potential winnings
            </p>

            <label htmlFor="goalAdvantage2">Goal Advantage for Team 2:</label>
            <input type="number" id="goalAdvantage2" name="goalAdvantage2" value={newChallenge.goalAdvantage2} onChange={handleInputChange} />
          </div>
        </div>

        <div className="time-section">
          <label htmlFor="startTime">Challenge Start Time:</label>
          <input type="datetime-local" id="startTime" name="startTime" value={newChallenge.startTime} onChange={handleInputChange} required />
        
          <label htmlFor="endTime">Challenge End Time:</label>
          <input type="datetime-local" id="endTime" name="endTime" value={newChallenge.endTime} onChange={handleInputChange} required />
        
          <label htmlFor="matchTime">Actual Match Time:</label>
          <input type="datetime-local" id="matchTime" name="matchTime" value={newChallenge.matchTime} onChange={handleInputChange} required />
        </div>
      </form>

      <div className="challenge-preview">
        <h3>Challenge Preview</h3>
        <div className="preview-teams-container">
          <div className="preview-team">
            {newChallenge.team1 && (
              <>
                <div className="logo-container">
                  <img
                    src={`${API_BASE_URL}/${teams.find(t => t.name === newChallenge.team1)?.logo}`}
                    alt="Team 1 Logo"
                    className="logo-preview"
                  />
                </div>
                <div className="preview-team-name">{newChallenge.team1}</div>
                <div className="preview-odds">Odds: {newChallenge.odds1}</div>
              </>
            )}
          </div>
          
          <div className="preview-vs">VS</div>
          
          <div className="preview-team">
            {newChallenge.team2 && (
              <>
                <div className="logo-container">
                  <img
                    src={`${API_BASE_URL}/${teams.find(t => t.name === newChallenge.team2)?.logo}`}
                    alt="Team 2 Logo"
                    className="logo-preview"
                  />
                </div>
                <div className="preview-team-name">{newChallenge.team2}</div>
                <div className="preview-odds">Odds: {newChallenge.odds2}</div>
              </>
            )}
          </div>
        </div>

        <div className="preview-time-details">
          <p>
            <span>Start Time</span>
            {newChallenge.startTime}
          </p>
          <p>
            <span>End Time</span>
            {newChallenge.endTime}
          </p>
          <p>
            <span>Match Time</span>
            {newChallenge.matchTime}
          </p>
          <p>
            <span>Match Type</span>
            {newChallenge.matchType.replace('_', ' ').toUpperCase()}
          </p>
        </div>
      </div>
    </div>
  );
}

export default ChallengeSystem;
