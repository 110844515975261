import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import './UserRegistration.css';

const API_BASE_URL = '/backend';

function UserRegistration() {
    const [teams, setTeams] = useState([]);
    const [newUser, setNewUser] = useState({
        username: '',
        full_name: '',
        email: '',
        password: '',
        favorite_team: ''
    });
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        fetchTeams();
    }, []);

    const fetchTeams = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/handlers/team_management.php`);
            setTeams(response.data.data || []);
        } catch (err) {
            setError('Failed to fetch teams');
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewUser(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess('');

        try {
            const response = await axios.post(`${API_BASE_URL}/handlers/user_registration.php`, newUser);
            if (response.data.success) {
                setSuccess('Registration successful! Redirecting to login...');
                setTimeout(() => navigate('/login'), 3000);
            } else {
                setError(response.data.message || 'Registration failed');
            }
        } catch (err) {
            setError('An error occurred during registration');
        }
    };

    return (
        <div className="user-registration">
            <div className="user-registration-container">
                <div className="user-registration-header">
                    <Link to="/" className="brand-logo">FanBet247</Link>
                    <h1>Create Account</h1>
                    <p className="user-registration-subtitle">Join FanBet247 today</p>
                </div>

                <form onSubmit={handleSubmit} className="user-registration-form">
                    <div className="form-group">
                        <label htmlFor="username">Username</label>
                        <div className="input-wrapper">
                            <i className="fas fa-user"></i>
                            <input
                                id="username"
                                type="text"
                                name="username"
                                value={newUser.username}
                                onChange={handleInputChange}
                                placeholder="Choose a username"
                                required
                            />
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="full_name">Full Name</label>
                        <div className="input-wrapper">
                            <i className="fas fa-id-card"></i>
                            <input
                                id="full_name"
                                type="text"
                                name="full_name"
                                value={newUser.full_name}
                                onChange={handleInputChange}
                                placeholder="Enter your full name"
                                required
                            />
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <div className="input-wrapper">
                            <i className="fas fa-envelope"></i>
                            <input
                                id="email"
                                type="email"
                                name="email"
                                value={newUser.email}
                                onChange={handleInputChange}
                                placeholder="Enter your email"
                                required
                            />
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <div className="input-wrapper">
                            <i className="fas fa-lock"></i>
                            <input
                                id="password"
                                type="password"
                                name="password"
                                value={newUser.password}
                                onChange={handleInputChange}
                                placeholder="Choose a password"
                                required
                            />
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="favorite_team">Favorite Team</label>
                        <div className="input-wrapper">
                            <i className="fas fa-futbol"></i>
                            <select
                                id="favorite_team"
                                name="favorite_team"
                                value={newUser.favorite_team}
                                onChange={handleInputChange}
                                required
                            >
                                <option value="">Select Favorite Team</option>
                                {teams.map(team => (
                                    <option key={team.id} value={team.name}>{team.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>

                    {error && <div className="error-message">{error}</div>}
                    {success && <div className="success-message">{success}</div>}

                    <button type="submit" className="login-button">
                        Create Account
                    </button>

                    <div className="user-registration-footer">
                        <p>Already have an account? <Link to="/login" className="login-link">Login here</Link></p>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default UserRegistration;
