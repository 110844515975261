import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

function Header({ userName, handleLogout }) {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 20);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToFooter = (e) => {
    e.preventDefault();
    const content = document.querySelector('.layout-content, .content');
    const footer = document.querySelector('.main-footer');
    
    if (footer && content) {
      const footerPosition = footer.offsetTop;
      content.scrollTo({
        top: footerPosition,
        behavior: 'smooth'
      });
    }
    setIsMobileMenuOpen(false);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const isLoggedIn = !!localStorage.getItem('userId');

  return (
    <header className={`main-header ${isScrolled ? 'scrolled' : ''} ${isMobileMenuOpen ? 'menu-open' : ''}`}>
      <div className="header-container">
        <div className="header-left">
          <Link to="/" className="logo-link">
            FanBet247
          </Link>
          <button 
            className="mobile-menu-btn"
            onClick={toggleMobileMenu}
            aria-label="Toggle menu"
            aria-expanded={isMobileMenuOpen}
          >
            <span className="menu-icon"></span>
          </button>
        </div>

        <nav className={`header-nav ${isMobileMenuOpen ? 'active' : ''}`}>
          <Link to="/" onClick={() => setIsMobileMenuOpen(false)}>Home</Link>
          <Link to="/live-challenges" onClick={() => setIsMobileMenuOpen(false)}>Live</Link>
          <Link to={isLoggedIn ? "/league-home" : "/login"} onClick={() => setIsMobileMenuOpen(false)}>Leagues</Link>
          <Link to="/leaderboard" onClick={() => setIsMobileMenuOpen(false)}>Leaders</Link>
          <Link to="/about" onClick={() => setIsMobileMenuOpen(false)}>About</Link>
          <button onClick={(e) => { scrollToFooter(e); setIsMobileMenuOpen(false); }} className="nav-link">
            Contact
          </button>
        </nav>

        <div className="header-right">
          {userName ? (
            <div className="user-menu">
              <div className="user-info">
                <span className="user-icon">
                  <i className="fas fa-user-circle"></i>
                </span>
                <span className="username">{userName}</span>
              </div>
              <Link to="/dashboard" className="header-btn dashboard-btn">
                <i className="fas fa-tachometer-alt"></i>
                <span>Dashboard</span>
              </Link>
              <button onClick={handleLogout} className="header-btn logout-btn">
                <i className="fas fa-sign-out-alt"></i>
                <span>Logout</span>
              </button>
            </div>
          ) : (
            <div className="auth-buttons">
              <Link to="/login" className="header-btn login-btn">Login</Link>
              <Link to="/register" className="header-btn register-btn">Register</Link>
            </div>
          )}
        </div>
      </div>
    </header>
  );
}

export default Header;