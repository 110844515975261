import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import axios from 'axios';
import AlertContainer from '../components/AlertContainer';
import './CreditChallenge.css';

const API_BASE_URL = '/backend';

const initialFormState = {
    selectedChallenge: '',
    teamAScore: 0,
    teamBScore: 0,
    selectedWinner: '',
    selectedLoser: '',
    newStatus: 'Settled',
    oddsTeamA: 0,
    oddsTeamB: 0,
    oddsDraw: 0,
    oddsLost: 0
};

function CreditChallenge() {
    const [challenges, setChallenges] = useState([]);
    const [formState, setFormState] = useState(initialFormState);
    const [loading, setLoading] = useState(false);
    const [betsPreview, setBetsPreview] = useState(null);
    const [alerts, setAlerts] = useState([]);
    const [leagueDetails, setLeagueDetails] = useState(null);
    const [payoutPreviews, setPayoutPreviews] = useState({});

    const addAlert = (message, type = 'success', duration = 3000) => {
        const id = Date.now();
        setAlerts(prev => [...prev, { id, message, type, duration }]);
    };

    const removeAlert = (id) => {
        setAlerts(prev => prev.filter(alert => alert.id !== id));
    };

    const fetchOpenChallenges = useCallback(async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/handlers/challenge_management.php`, {
                params: { status: 'Closed' }
            });
            
            if (response.data.success) {
                const closedChallenges = response.data.challenges
                    .filter(c => c.status === 'Closed')
                    .map(challenge => ({
                        ...challenge,
                        match_date: new Date(challenge.match_date).toLocaleDateString(),
                        isLeague: Boolean(challenge.league_id)
                    }));
                setChallenges(closedChallenges);
            } else {
                addAlert('Failed to fetch challenges', 'error');
            }
        } catch (err) {
            console.error('Error fetching challenges:', err);
            addAlert('Failed to fetch challenges', 'error');
        }
    }, []);

    const fetchBetsPreview = useCallback(async (challengeId) => {
        if (!challengeId) return;
        
        try {
            const response = await axios.get(`${API_BASE_URL}/handlers/get_challenge_bets.php`, {
                params: { challenge_id: challengeId }
            });
            
            if (response.data.success) {
                setBetsPreview(response.data.bets);
                // Fetch league details for involved users
                const uniqueUserIds = new Set(
                    response.data.bets.flatMap(bet => [bet.user1_id, bet.user2_id])
                );
                await fetchLeagueDetails(Array.from(uniqueUserIds));
            }
        } catch (err) {
            console.error('Error fetching bets preview:', err);
            addAlert('Failed to load bet details', 'error');
        }
    }, []);

    const fetchLeagueDetails = async (userIds) => {
        try {
            const response = await axios.get(`${API_BASE_URL}/handlers/league_details.php`, {
                params: { user_ids: userIds.join(',') }
            });
            
            if (response.data.success) {
                setLeagueDetails(response.data.leagueDetails);
            }
        } catch (err) {
            console.error('Error fetching league details:', err);
            addAlert('Failed to load league information', 'error');
        }
    };

    useEffect(() => {
        fetchOpenChallenges();
    }, [fetchOpenChallenges]);

    const selectedChallenge = useMemo(() => 
        challenges.find(c => c.challenge_id === parseInt(formState.selectedChallenge)),
        [challenges, formState.selectedChallenge]
    );

    useEffect(() => {
        if (selectedChallenge) {
            setFormState(prev => ({
                ...prev,
                oddsTeamA: selectedChallenge.odds_team_a || 0,
                oddsTeamB: selectedChallenge.odds_team_b || 0,
                oddsDraw: selectedChallenge.odds_draw || 0,
                oddsLost: selectedChallenge.odds_lost || 0
            }));
            fetchBetsPreview(selectedChallenge.challenge_id);
        } else {
            setBetsPreview(null);
        }
    }, [selectedChallenge, fetchBetsPreview]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormState(prev => ({ 
            ...prev, 
            [name]: name.includes('Score') || name.includes('odds') ? parseFloat(value) || 0 : value 
        }));
    };

    const resetForm = () => {
        setFormState(initialFormState);
        setBetsPreview(null);
    };

    const handleCompleteChallenge = async (e) => {
        e.preventDefault();
        
        if (!formState.selectedWinner) {
            addAlert('Please select a winner', 'error');
            return;
        }

        if (formState.selectedWinner !== 'draw' && !formState.selectedLoser) {
            addAlert('Please select a loser', 'error');
            return;
        }

        if (formState.selectedWinner !== 'draw' && formState.selectedWinner === formState.selectedLoser) {
            addAlert('Winner and loser cannot be the same team', 'error');
            return;
        }

        setLoading(true);
        
        try {
            const formData = new FormData();
            formData.append('challenge_id', formState.selectedChallenge);
            formData.append('team_a_score', formState.teamAScore);
            formData.append('team_b_score', formState.teamBScore);
            formData.append('winner', formState.selectedWinner);
            formData.append('loser', formState.selectedLoser);
            formData.append('new_status', formState.newStatus);

            const response = await axios.post(
                `${API_BASE_URL}/handlers/complete_challenge.php`,
                formData,
                { headers: { 'Content-Type': 'multipart/form-data' } }
            );

            if (response.data.success) {
                addAlert('Challenge settled successfully!');
                fetchOpenChallenges();
                resetForm();
            } else {
                addAlert(response.data.message || 'Failed to complete challenge', 'error');
            }
        } catch (err) {
            const errorMsg = err.response?.data?.message || 'Failed to complete challenge';
            addAlert(errorMsg, 'error');
        } finally {
            setLoading(false);
        }
    };

    const calculatePotentialPayout = (bet, choice) => {
        if (!formState.selectedWinner) return null;
        
        // For draw outcome
        if (formState.selectedWinner === 'draw') {
            if (choice === 'draw') {
                return bet.potential_returns_user1.draw;
            } else {
                return (bet.amount_user1 * selectedChallenge.odds_lost).toFixed(2);
            }
        }

        // For win/loss outcomes
        const isWinning = formState.selectedWinner === choice;
        if (isWinning) {
            switch (choice) {
                case 'team_a_win':
                case 'team_b_win':
                    return bet.potential_returns_user1.win;
                default:
                    return null;
            }
        } else {
            return (bet.amount_user1 * selectedChallenge.odds_lost).toFixed(2);
        }
    };

    const calculateGoalAdvantage = (teamAScore, teamBScore) => {
        const difference = teamAScore - teamBScore;
        if (difference > 0) {
            return { value: `+${difference}`, class: 'goal-advantage-positive' };
        } else if (difference < 0) {
            return { value: difference.toString(), class: 'goal-advantage-negative' };
        }
        return { value: '0', class: 'goal-advantage-neutral' };
    };

    const renderLeaguePanel = () => {
        if (!leagueDetails || !selectedChallenge?.isLeague) return null;

        return (
            <section className="league-info-panel">
                <div className="heading-container">
                    <h2 className="page-heading">League Information</h2>
                </div>
                <div className="league-info-header">
                    <img 
                        src={leagueDetails.league_logo || '/default-league-logo.png'} 
                        alt="League Logo" 
                        className="league-logo"
                    />
                    <h3 className="league-name">{leagueDetails.league_name}</h3>
                </div>
                <div className="league-stats">
                    <div className="stat-item">
                        <div className="stat-label">Wins</div>
                        <div className="stat-value">{leagueDetails.wins || 0}</div>
                    </div>
                    <div className="stat-item">
                        <div className="stat-label">Draws</div>
                        <div className="stat-value">{leagueDetails.draws || 0}</div>
                    </div>
                    <div className="stat-item">
                        <div className="stat-label">Losses</div>
                        <div className="stat-value">{leagueDetails.losses || 0}</div>
                    </div>
                </div>
                <div className="league-position">
                    <div className="stat-label">Current Position</div>
                    <div className="stat-value">{leagueDetails.position || 'N/A'}</div>
                </div>
            </section>
        );
    };

    const renderScoreDisplay = () => {
        if (!selectedChallenge) return null;

        return (
            <div className="score-display">
                <div className="team-score-container">
                    <div className="team-name">{selectedChallenge.team_a}</div>
                    <input
                        type="number"
                        name="teamAScore"
                        value={formState.teamAScore}
                        onChange={handleInputChange}
                        min="0"
                        className="score-input"
                    />
                </div>
                <div className="vs-indicator">VS</div>
                <div className="team-score-container">
                    <div className="team-name">{selectedChallenge.team_b}</div>
                    <input
                        type="number"
                        name="teamBScore"
                        value={formState.teamBScore}
                        onChange={handleInputChange}
                        min="0"
                        className="score-input"
                    />
                </div>
            </div>
        );
    };

    return (
        <div className="credit-challenge-container">
            {/* League Information Panel - Conditionally rendered */}
            {renderLeaguePanel()}

            <div className="credit-challenge-content">
                {/* Challenge Form Section */}
                <section className="challenge-form-section">
                    <div className="heading-container">
                        <h2 className="page-heading">Settle Challenge</h2>
                    </div>
                    
                    <form onSubmit={handleCompleteChallenge} className="form-content">
                        <div className="form-group">
                            <label className="form-label">Select Challenge:</label>
                            <select
                                name="selectedChallenge"
                                value={formState.selectedChallenge}
                                onChange={handleInputChange}
                                className="form-select"
                            >
                                <option value="">Select a challenge...</option>
                                {challenges.map(challenge => (
                                    <option key={challenge.challenge_id} value={challenge.challenge_id}>
                                        {challenge.team_a} vs {challenge.team_b} 
                                        {challenge.isLeague ? ' (League Match)' : ''} - 
                                        {challenge.match_date}
                                    </option>
                                ))}
                            </select>
                        </div>

                        {selectedChallenge && (
                            <>
                                {renderScoreDisplay()}

                                <div className="dropdown-section">
                                    <div className="form-group">
                                        <label className="form-label">Select Winner:</label>
                                        <select
                                            name="selectedWinner"
                                            value={formState.selectedWinner}
                                            onChange={handleInputChange}
                                            className="form-select"
                                        >
                                            <option value="">Select Winner</option>
                                            <option value="team_a_win">{selectedChallenge.team_a}</option>
                                            <option value="team_b_win">{selectedChallenge.team_b}</option>
                                            <option value="draw">Draw</option>
                                        </select>
                                    </div>

                                    <div className="form-group">
                                        <label className="form-label">Select Loser:</label>
                                        <select
                                            name="selectedLoser"
                                            value={formState.selectedLoser}
                                            onChange={handleInputChange}
                                            className="form-select"
                                            disabled={formState.selectedWinner === 'draw'}
                                        >
                                            <option value="">Select Loser</option>
                                            {formState.selectedWinner !== 'team_a_win' && (
                                                <option value="team_a_win">{selectedChallenge.team_a}</option>
                                            )}
                                            {formState.selectedWinner !== 'team_b_win' && (
                                                <option value="team_b_win">{selectedChallenge.team_b}</option>
                                            )}
                                        </select>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label className="form-label">Challenge Status:</label>
                                    <select
                                        name="newStatus"
                                        value={formState.newStatus}
                                        onChange={handleInputChange}
                                        className="form-select"
                                    >
                                        <option value="Settled">Settled</option>
                                        <option value="Completed">Completed</option>
                                        <option value="Cancelled">Cancelled</option>
                                    </select>
                                </div>

                                <button 
                                    type="submit" 
                                    className={`submit-button ${loading ? 'loading' : ''}`}
                                    disabled={loading}
                                >
                                    {loading ? 'Processing...' : 'Settle Challenge'}
                                </button>
                            </>
                        )}
                    </form>
                </section>

                {/* Live Preview Section */}
                <section className="live-preview">
                    <div className="heading-container">
                        <h2 className="page-heading">Live Preview</h2>
                    </div>
                    
                    {betsPreview && betsPreview.length > 0 ? (
                        <div className="bets-list" role="list">
                            {betsPreview.map((bet, index) => {
                                const user1Payout = calculatePotentialPayout(bet, bet.bet_choice_user1);
                                const user2Payout = calculatePotentialPayout(bet, bet.bet_choice_user2);
                                const user1PayoutClass = user1Payout > bet.amount_user1 ? 'winning' : 'losing';
                                const user2PayoutClass = user2Payout > bet.amount_user2 ? 'winning' : 'losing';
                                const goalAdvantage = calculateGoalAdvantage(formState.teamAScore, formState.teamBScore);

                                return (
                                    <div key={bet.bet_id || index} className="bet-item" role="listitem">
                                        <div className="score-summary">
                                            <div className="admin-score">
                                                <span className="admin-score-label">Final Score:</span>
                                                <span className="admin-score-value">
                                                    {formState.teamAScore} - {formState.teamBScore}
                                                </span>
                                            </div>
                                            <div className={`goal-advantage ${goalAdvantage.class}`}>
                                                <span>Goal Advantage:</span>
                                                <span>{goalAdvantage.value}</span>
                                            </div>
                                        </div>

                                        <div className="bet-users-container">
                                            <div className={`bet-user ${user1PayoutClass}`}>
                                                <div className="bet-user-header">
                                                    <div className="user-avatar" aria-hidden="true">
                                                        {bet.user1_name.charAt(0).toUpperCase()}
                                                    </div>
                                                    <span className="user-name">{bet.user1_name}</span>
                                                </div>
                                                <div className="bet-details">
                                                    <span className="bet-choice">
                                                        Choice: {bet.bet_choice_user1}
                                                    </span>
                                                    <span className="bet-amount">
                                                        Stake: {bet.amount_user1}
                                                    </span>
                                                </div>
                                                <div className="payout-preview">
                                                    <span className="payout-label">Payout:</span>
                                                    <span className="payout-value">{user1Payout}</span>
                                                </div>
                                            </div>

                                            <div className={`bet-user ${user2PayoutClass}`}>
                                                <div className="bet-user-header">
                                                    <div className="user-avatar" aria-hidden="true">
                                                        {bet.user2_name.charAt(0).toUpperCase()}
                                                    </div>
                                                    <span className="user-name">{bet.user2_name}</span>
                                                </div>
                                                <div className="bet-details">
                                                    <span className="bet-choice">
                                                        Choice: {bet.bet_choice_user2}
                                                    </span>
                                                    <span className="bet-amount">
                                                        Stake: {bet.amount_user2}
                                                    </span>
                                                </div>
                                                <div className="payout-preview">
                                                    <span className="payout-label">Payout:</span>
                                                    <span className="payout-value">{user2Payout}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    ) : (
                        <div className="no-bets-message" role="status">
                            {selectedChallenge ? 'No bets found for this challenge' : 'Select a challenge to view bets'}
                        </div>
                    )}
                </section>
            </div>
            <AlertContainer alerts={alerts} removeAlert={removeAlert} />
        </div>
    );
}

export default CreditChallenge;
