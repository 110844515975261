import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Countdown from 'react-countdown';
import './ChallengeManagement.css';

const API_BASE_URL = '/backend';

function ChallengeManagement() {
    const [challenges, setChallenges] = useState([]);
    const [teams, setTeams] = useState([]);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [showEditModal, setShowEditModal] = useState(false);
    const [showPreviewModal, setShowPreviewModal] = useState(false);
    const [selectedChallenge, setSelectedChallenge] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [editingChallenge, setEditingChallenge] = useState({
        team_a: '',
        team_b: '',
        odds_team_a: 1.80,
        odds_team_b: 1.80,
        start_time: '',
        end_time: '',
        match_date: '',
        status: 'Open'
    });
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [challengeToDelete, setChallengeToDelete] = useState(null);

    const ITEMS_PER_PAGE = 10;

    useEffect(() => {
        fetchChallenges();
        fetchTeams();

        // Set up more frequent challenge status check (every 5 seconds)
        const statusCheckInterval = setInterval(() => {
            checkChallengeStatus();
        }, 5000); // Check every 5 seconds

        return () => clearInterval(statusCheckInterval);
    }, []);

    const fetchTeams = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/handlers/team_management.php`);
            if (response.data.status === 200) {
                setTeams(response.data.data);
            }
        } catch (err) {
            console.error('Error fetching teams:', err);
        }
    };

    const fetchChallenges = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/handlers/challenge_management.php`);
            if (response.data.success) {
                setChallenges(response.data.challenges);
            }
        } catch (err) {
            setError('Failed to fetch challenges');
        }
    };

    const checkChallengeStatus = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/handlers/check_challenge_status.php`);
            if (response.data.remaining_expired > 0) {
                console.log(`Found ${response.data.remaining_expired} expired challenges that need closing`);
            }
            if (response.data.affected_rows > 0) {
                console.log(`Closed ${response.data.affected_rows} expired challenges`);
                fetchChallenges(); // Refresh only if changes were made
            }
        } catch (err) {
            console.error('Error checking challenge status:', err);
        }
    };

    const getTeamLogo = (teamName) => {
        const team = teams.find(team => team.name === teamName);
        return team ? `${API_BASE_URL}/${team.logo}` : '';
    };

    const handleEdit = (challenge) => {
        setEditingChallenge(challenge);
        setShowEditModal(true);
    };

    const handleUpdate = async () => {
        try {
            const response = await axios.post(
                `${API_BASE_URL}/handlers/challenge_management.php`,
                editingChallenge
            );
            
            if (response.data.success) {
                setSuccess('Challenge updated successfully!');
                fetchChallenges();
                setShowEditModal(false);
            } else {
                setError(response.data.message || 'Failed to update challenge');
            }
        } catch (err) {
            setError('Failed to update challenge');
            console.error('Update error:', err);
        }
    };

    const handleDeleteClick = (challenge) => {
        setChallengeToDelete(challenge);
        setShowDeleteModal(true);
    };

    const handleDeleteConfirm = async () => {
        if (!challengeToDelete) return;

        try {
            const response = await axios.delete(
                `${API_BASE_URL}/handlers/challenge_management.php?challenge_id=${challengeToDelete.challenge_id}`
            );
            
            if (response.data.success) {
                setSuccess('Challenge deleted successfully!');
                fetchChallenges();
            } else {
                setError(response.data.message || 'Failed to delete challenge');
            }
        } catch (err) {
            setError('Failed to delete challenge');
            console.error('Delete error:', err);
        }
        setShowDeleteModal(false);
        setChallengeToDelete(null);
    };

    const isExpired = (challenge) => {
        return new Date(challenge.end_time) < new Date() || challenge.status === 'Expired';
    };

    const totalPages = Math.ceil(challenges.length / ITEMS_PER_PAGE);
    const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
    const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;
    const currentChallenges = challenges.slice(indexOfFirstItem, indexOfLastItem);

    return (
        <div className="challenge-management">
            <h1>Challenge Management</h1>
            {error && <div className="error-message">{error}</div>}
            {success && <div className="success-message">{success}</div>}
            
            <div className="challenges-list">
                <div className="table-container">
                    <table className="challenge-management-table">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Matchup</th>
                                <th>Time/Date</th>
                                <th>Status</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentChallenges.map((challenge, index) => (
                                <tr key={challenge.challenge_id}>
                                    <td className="index-column">{indexOfFirstItem + index + 1}</td>
                                    <td>
                                        <div className="matchup-grid">
                                            <div className="team-block">
                                                <img src={getTeamLogo(challenge.team_a)} 
                                                     alt={challenge.team_a} 
                                                     className="team-logo" />
                                                <div className="team-name">{challenge.team_a}</div>
                                                <div className="team-odds">Odds: {challenge.odds_team_a}</div>
                                            </div>
                                            
                                            <div className="vs-center">VS</div>
                                            
                                            <div className="team-block">
                                                <img src={getTeamLogo(challenge.team_b)} 
                                                     alt={challenge.team_b} 
                                                     className="team-logo" />
                                                <div className="team-name">{challenge.team_b}</div>
                                                <div className="team-odds">Odds: {challenge.odds_team_b}</div>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="time-cell">
                                        <div className="match-time-display">
                                            <div className="match-time">
                                                Match: {new Date(challenge.match_date).toLocaleString('en-US', {
                                                    month: 'short',
                                                    day: 'numeric',
                                                    hour: 'numeric',
                                                    minute: '2-digit',
                                                    hour12: true
                                                })}
                                            </div>
                                            <div className="end-time">
                                                Time Left: <Countdown 
                                                    date={new Date(challenge.end_time)}
                                                    renderer={({ days, hours, minutes, seconds, completed }) => 
                                                        <CountdownRenderer 
                                                            days={days} 
                                                            hours={hours} 
                                                            minutes={minutes} 
                                                            seconds={seconds} 
                                                            completed={completed}
                                                            date={challenge.end_time}
                                                        />
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </td>
                                    <td className="status-cell">
                                        <span className={`status-badge ${challenge.status}`}>
                                            {challenge.status}
                                        </span>
                                    </td>
                                    <td>
                                        <div className="action-buttons">
                                            <button 
                                                className="btn btn-preview"
                                                onClick={() => {
                                                    setSelectedChallenge(challenge);
                                                    setShowPreviewModal(true);
                                                }}>
                                                Preview
                                            </button>
                                            <button 
                                                className="btn btn-edit"
                                                onClick={() => handleEdit(challenge)}>
                                                Edit
                                            </button>
                                            {isExpired(challenge) && (
                                                <button 
                                                    className="btn btn-delete"
                                                    onClick={() => handleDeleteClick(challenge)}>
                                                    Delete
                                                </button>
                                            )}
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="pagination">
                    <button 
                        onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                        disabled={currentPage === 1}
                    >
                        Previous
                    </button>
                    <span className="page-info">
                        Page {currentPage} of {totalPages}
                    </span>
                    <button 
                        onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                        disabled={currentPage === totalPages}
                    >
                        Next
                    </button>
                </div>
            </div>

            {showPreviewModal && selectedChallenge && (
                <PreviewModal 
                    challenge={selectedChallenge}
                    onClose={() => setShowPreviewModal(false)}
                    teams={teams}
                    getTeamLogo={getTeamLogo}
                />
            )}

            {showEditModal && (
                <EditModal 
                    challenge={editingChallenge}
                    onClose={() => setShowEditModal(false)}
                    onUpdate={handleUpdate}
                    onChange={(field, value) => setEditingChallenge(prev => ({
                        ...prev,
                        [field]: value
                    }))}
                />
            )}

            {showDeleteModal && challengeToDelete && (
                <DeleteConfirmationModal
                    challenge={challengeToDelete}
                    onConfirm={handleDeleteConfirm}
                    onCancel={() => {
                        setShowDeleteModal(false);
                        setChallengeToDelete(null);
                    }}
                />
            )}
        </div>
    );
}

const CountdownRenderer = ({ days, hours, minutes, seconds, completed, date }) => {
    if (completed) {
        return <span className="countdown-expired">Expired</span>;
    }
    
    return (
        <div className="countdown-container">
            <div className="countdown-time">
                {days > 0 ? `${days}d ` : ''}{hours}h {minutes}m {seconds}s
            </div>
        </div>
    );
};

const PreviewModal = ({ challenge, onClose, teams, getTeamLogo }) => (
    <div className="modal-overlay">
        <div className="preview-modal">
            <button className="close-button" onClick={onClose}>×</button>
            <h2>Challenge Preview</h2>
            <div className="match-type-section">
                <span className="match-type-badge">
                    {challenge.match_type === 'full_time' ? 'Full Time' : 'Half Time'}
                </span>
            </div>
            <div className="match-preview-display">
                <div className="match-team-preview left-team">
                    <img src={getTeamLogo(challenge.team_a)} alt={challenge.team_a} className="team-logo-large" />
                    <h3 className="team-name">{challenge.team_a}</h3>
                    <p className="team-odds">Win: {challenge.odds_team_a}</p>
                </div>
                <div className="match-vs-preview">
                    <span className="vs-text">VS</span>
                    <div className="match-odds">
                        <p className="draw-odds">Draw: {challenge.odds_draw || '0.8'}</p>
                        <p className="lost-odds">Lost: {challenge.odds_lost || '0.2'}</p>
                    </div>
                </div>
                <div className="match-team-preview right-team">
                    <img src={getTeamLogo(challenge.team_b)} alt={challenge.team_b} className="team-logo-large" />
                    <h3 className="team-name">{challenge.team_b}</h3>
                    <p className="team-odds">Win: {challenge.odds_team_b}</p>
                </div>
            </div>
            <div className="match-details">
                <div className="detail-row">
                    <div className="detail-item">
                        <span className="label">Start Time</span>
                        <span className="value">{new Date(challenge.start_time).toLocaleString()}</span>
                    </div>
                    <div className="detail-item">
                        <span className="label">End Time</span>
                        <span className="value">{new Date(challenge.end_time).toLocaleString()}</span>
                    </div>
                </div>
                <div className="detail-row">
                    <div className="detail-item">
                        <span className="label">Match Date</span>
                        <span className="value">{new Date(challenge.match_date).toLocaleString()}</span>
                    </div>
                    <div className="detail-item">
                        <span className="label">Status</span>
                        <span className="value status-badge">{challenge.status}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

const EditModal = ({ challenge, onClose, onUpdate, onChange }) => (
    <div className="modal-overlay">
        <div className="edit-modal">
            <button className="close-button" onClick={onClose}>×</button>
            <h2>Edit Challenge</h2>
            <div className="form-content">
                <div className="form-group">
                    <label>Match Type</label>
                    <select
                        value={challenge.match_type || 'full_time'}
                        onChange={(e) => onChange('match_type', e.target.value)}
                    >
                        <option value="full_time">Full Time</option>
                        <option value="half_time">Half Time</option>
                    </select>
                </div>
                <div className="form-group">
                    <label>Odds Team A</label>
                    <input 
                        type="number" 
                        step="0.01"
                        value={challenge.odds_team_a}
                        onChange={(e) => onChange('odds_team_a', e.target.value)}
                    />
                </div>
                <div className="form-group">
                    <label>Odds Team B</label>
                    <input 
                        type="number"
                        step="0.01"
                        value={challenge.odds_team_b}
                        onChange={(e) => onChange('odds_team_b', e.target.value)}
                    />
                </div>
                <div className="form-group">
                    <label>Draw Odds</label>
                    <input 
                        type="number"
                        step="0.01"
                        value={challenge.odds_draw || 0.8}
                        onChange={(e) => onChange('odds_draw', e.target.value)}
                    />
                </div>
                <div className="form-group">
                    <label>Lost Odds</label>
                    <input 
                        type="number"
                        step="0.01"
                        value={challenge.odds_lost || 0.2}
                        onChange={(e) => onChange('odds_lost', e.target.value)}
                    />
                </div>
                <div className="form-group">
                    <label>Status</label>
                    <select 
                        value={challenge.status}
                        onChange={(e) => onChange('status', e.target.value)}
                    >
                        <option value="Open">Open</option>
                        <option value="Closed">Closed</option>
                        <option value="Settled">Settled</option>
                    </select>
                </div>
                <div className="form-group">
                    <label>Start Time</label>
                    <input 
                        type="datetime-local"
                        value={challenge.start_time.slice(0, 16)}
                        onChange={(e) => onChange('start_time', e.target.value)}
                    />
                </div>
                <div className="form-group">
                    <label>End Time</label>
                    <input 
                        type="datetime-local"
                        value={challenge.end_time.slice(0, 16)}
                        onChange={(e) => onChange('end_time', e.target.value)}
                    />
                </div>
                <div className="form-group">
                    <label>Match Date</label>
                    <input 
                        type="datetime-local"
                        value={challenge.match_date.slice(0, 16)}
                        onChange={(e) => onChange('match_date', e.target.value)}
                    />
                </div>
            </div>
            <div className="form-actions">
                <button className="cancel-button" onClick={onClose}>Cancel</button>
                <button className="save-button" onClick={onUpdate}>Save Changes</button>
            </div>
        </div>
    </div>
);

const DeleteConfirmationModal = ({ challenge, onConfirm, onCancel }) => (
    <div className="modal-overlay">
        <div className="delete-confirmation-modal">
            <h2>Delete Challenge</h2>
            <div className="modal-content">
                <p>Are you sure you want to delete this expired challenge?</p>
                <div className="challenge-preview">
                    <div className="teams">
                        <span>{challenge.team_a}</span>
                        <span className="vs">vs</span>
                        <span>{challenge.team_b}</span>
                    </div>
                    <div className="match-date">
                        Match Date: {new Date(challenge.match_date).toLocaleString()}
                    </div>
                </div>
            </div>
            <div className="modal-actions">
                <button className="cancel-button" onClick={onCancel}>Cancel</button>
                <button className="confirm-delete-button" onClick={onConfirm}>Delete Challenge</button>
            </div>
        </div>
    </div>
);

export default ChallengeManagement;