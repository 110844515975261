import React, { useState, useEffect } from 'react';
import axios from '../utils/axiosConfig';
import { useNavigate } from 'react-router-dom';
import './CreditHistory.css';

function CreditHistory() {
    const [creditRequests, setCreditRequests] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [showProofModal, setShowProofModal] = useState(false);
    const [selectedProof, setSelectedProof] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const [viewportSize, setViewportSize] = useState('desktop');
    const navigate = useNavigate();
    const currentUserId = localStorage.getItem('userId');

    useEffect(() => {
        if (!currentUserId) {
            navigate('/login');
            return;
        }
        fetchCreditRequests();
    }, [navigate, currentUserId]);

    useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            if (width <= 767) {
                setViewportSize('mobile');
            } else {
                setViewportSize('desktop');
            }
        };
        
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const fetchCreditRequests = async () => {
        try {
            setLoading(true);
            setError('');
            
            const response = await axios.get('/backend/handlers/get_credit_requests.php', {
                params: { user_id: currentUserId }
            });
            
            if (response.data.success) {
                setCreditRequests(response.data.requests || []);
            } else {
                throw new Error(response.data.message || 'Failed to fetch credit requests');
            }
        } catch (err) {
            console.error('Error fetching credit requests:', err);
            setError(err.message || 'Failed to load credit requests. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const handleViewProof = (request) => {
        setSelectedProof({
            url: `/backend/handlers/get_proof_image.php?request_id=${request.request_id}&user_id=${currentUserId}`,
            amount: request.amount,
            date: request.created_at,
            status: request.status,
            paymentMethod: request.payment_method?.name || 'N/A'
        });
        setShowProofModal(true);
    };

    const getTotalApprovedAmount = () => {
        return creditRequests
            .filter(request => request.status === 'approved')
            .reduce((total, request) => total + parseFloat(request.amount), 0);
    };

    const getTotalPendingAmount = () => {
        return creditRequests
            .filter(request => request.status === 'pending')
            .reduce((total, request) => total + parseFloat(request.amount), 0);
    };

    // Pagination logic
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = creditRequests.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(creditRequests.length / itemsPerPage);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const Pagination = () => {
        return (
            <div className="pagination">
                <button 
                    onClick={() => paginate(currentPage - 1)} 
                    disabled={currentPage === 1}
                    className="pagination-btn"
                    aria-label="Previous page"
                >
                    ←
                </button>
                <span className="page-info">
                    Page {currentPage} of {totalPages}
                </span>
                <button 
                    onClick={() => paginate(currentPage + 1)} 
                    disabled={currentPage === totalPages}
                    className="pagination-btn"
                    aria-label="Next page"
                >
                    →
                </button>
            </div>
        );
    };

    const ProofModal = ({ proof, onClose }) => {
        if (!proof) return null;

        return (
            <div className="modal-overlay" onClick={onClose}>
                <div className="proof-modal" onClick={e => e.stopPropagation()}>
                    <button className="close-modal" onClick={onClose} aria-label="Close modal">×</button>
                    <div className="proof-details">
                        <h3>Payment Proof Details</h3>
                        <div className="proof-info">
                            <div className="proof-info-item">
                                <span className="proof-label">Amount:</span>
                                <span className="proof-value">₦{parseFloat(proof.amount).toLocaleString()}</span>
                            </div>
                            <div className="proof-info-item">
                                <span className="proof-label">Date:</span>
                                <span className="proof-value">{formatDate(proof.date).full}</span>
                            </div>
                            <div className="proof-info-item">
                                <span className="proof-label">Payment Method:</span>
                                <span className="proof-value">{proof.paymentMethod}</span>
                            </div>
                            <div className="proof-info-item">
                                <span className="proof-label">Status:</span>
                                <span className={`status-badge ${getStatusBadgeClass(proof.status)}`}>
                                    {proof.status.charAt(0).toUpperCase() + proof.status.slice(1)}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="proof-image-container">
                        <img src={proof.url} alt="Payment Proof" />
                    </div>
                </div>
            </div>
        );
    };

    const getStatusBadgeClass = (status) => {
        switch (status.toLowerCase()) {
            case 'approved':
                return 'status-badge-success';
            case 'rejected':
                return 'status-badge-danger';
            case 'expired':
                return 'status-badge-warning';
            default:
                return 'status-badge-pending';
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return {
            short: new Intl.DateTimeFormat('en-US', {
                month: 'short',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit'
            }).format(date),
            minimalist: new Intl.DateTimeFormat('en-US', {
                month: 'short',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                hour12: true
            }).format(date),
            full: new Intl.DateTimeFormat('en-US', {
                dateStyle: 'full',
                timeStyle: 'long'
            }).format(date)
        };
    };

    const MobileCard = ({ request }) => (
        <div className="mobile-card">
            <div className="mobile-card-header">
                <div className="mobile-card-amount">₦{parseFloat(request.amount).toLocaleString()}</div>
                <div className="mobile-card-date" title={formatDate(request.created_at).full}>
                    {formatDate(request.created_at).short}
                </div>
            </div>
            <div className="mobile-card-content">
                <div className="mobile-card-field">
                    <span className="mobile-card-label">Payment Method</span>
                    <span className="mobile-card-value">{request.payment_method?.name || 'N/A'}</span>
                </div>
                <div className="mobile-card-field">
                    <span className="mobile-card-label">Status</span>
                    <span className={`status-badge ${getStatusBadgeClass(request.status)}`}>
                        {request.status.charAt(0).toUpperCase() + request.status.slice(1)}
                    </span>
                </div>
                <button 
                    className="view-proof-btn"
                    onClick={() => handleViewProof(request)}
                    aria-label="View payment proof"
                >
                    View Proof
                </button>
            </div>
        </div>
    );

    if (loading) {
        return <div className="loading-container">
            <div className="loading-spinner"></div>
            <span>Loading credit history...</span>
        </div>;
    }

    return (
        <div className="credit-history-container">
            <div className="credit-history-header">
                <h1>Credit History</h1>
                <button onClick={() => navigate('/user/wallet')} className="new-request-btn">
                    + New Credit Request
                </button>
            </div>

            {error && <div className="error-message" role="alert">{error}</div>}

            <div className="credit-summary">
                <div className="summary-card approved">
                    <h3>Total Approved</h3>
                    <div className="amount">₦{getTotalApprovedAmount().toLocaleString()}</div>
                </div>
                <div className="summary-card pending">
                    <h3>Pending Credits</h3>
                    <div className="amount">₦{getTotalPendingAmount().toLocaleString()}</div>
                </div>
            </div>

            {creditRequests.length > 0 ? (
                <div className="credit-requests-section">
                    <div className="credit-requests-table">
                        {viewportSize === 'mobile' ? (
                            <div className="mobile-cards">
                                {currentItems.map((request) => (
                                    <MobileCard key={request.request_id} request={request} />
                                ))}
                            </div>
                        ) : (
                            <table className={`desktop-table ${viewportSize}`}>
                                <thead>
                                    <tr>
                                        <th className="date-header">Date</th>
                                        <th className="amount-header">Amount</th>
                                        <th className="payment-method-header">Payment Method</th>
                                        <th className="status-header">Status</th>
                                        <th className="actions-header">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentItems.map((request) => (
                                        <tr key={request.request_id}>
                                            <td className="date-cell">
                                                <div className="date-content">
                                                    <div className="date-line" title={formatDate(request.created_at).full}>
                                                        {formatDate(request.created_at).minimalist}
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="amount-cell">₦{parseFloat(request.amount).toLocaleString()}</td>
                                            <td className="payment-method-cell">
                                                {request.payment_method?.name || 'N/A'}
                                            </td>
                                            <td className="status-cell">
                                                <span className={`status-badge ${getStatusBadgeClass(request.status)}`}>
                                                    {request.status.charAt(0).toUpperCase() + request.status.slice(1)}
                                                </span>
                                            </td>
                                            <td className="actions-cell">
                                                <button 
                                                    className="view-proof-btn"
                                                    onClick={() => handleViewProof(request)}
                                                    aria-label="View payment proof"
                                                >
                                                    View Proof
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}
                    </div>
                    {totalPages > 1 && <Pagination />}
                </div>
            ) : (
                <div className="no-requests">
                    <div className="no-data-message">
                        <span role="img" aria-label="Empty state">📜</span>
                        <p>No credit requests found</p>
                        <p className="sub-text">Start by making your first credit request</p>
                    </div>
                </div>
            )}

            {showProofModal && (
                <ProofModal 
                    proof={selectedProof} 
                    onClose={() => {
                        setShowProofModal(false);
                        setSelectedProof(null);
                    }}
                />
            )}
        </div>
    );
}

export default CreditHistory; 