import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FaUserMinus, FaEnvelope, FaSearch, FaExclamationCircle, FaUserFriends, FaTrophy, FaStar, FaUserPlus, FaUserCircle } from 'react-icons/fa';
import './Friends.css';

const API_BASE_URL = '/backend';

function Friends() {
    const [friends, setFriends] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [showMessageModal, setShowMessageModal] = useState(false);
    const [selectedFriend, setSelectedFriend] = useState(null);
    const [messageText, setMessageText] = useState('');
    const [messageStatus, setMessageStatus] = useState({ show: false, type: '', message: '' });
    const userId = localStorage.getItem('userId');
    const [searchQuery, setSearchQuery] = useState('');
    const [searchType, setSearchType] = useState('friends'); // 'friends' or 'new'
    const [newFriendResults, setNewFriendResults] = useState([]);
    const [teams, setTeams] = useState([]);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [friendToUnfriend, setFriendToUnfriend] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        fetchFriends();
        fetchTeams();
    }, []);

    const fetchFriends = async () => {
        try {
            setLoading(true);
            console.log('Fetching friends for user:', userId);
            const response = await axios.get(`${API_BASE_URL}/handlers/friends.php?user_id=${userId}`);
            console.log('Friends response:', response.data);
            if (response.data.success) {
                setFriends(response.data.friends || []);
            } else {
                setError(response.data.message || 'Failed to fetch friends');
            }
        } catch (error) {
            console.error('Error fetching friends:', error);
            setError(error.response?.data?.message || 'An error occurred while fetching friends');
        } finally {
            setLoading(false);
        }
    };

    const fetchTeams = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/handlers/team_management.php`);
            if (response.data.status === 200) {
                setTeams(response.data.data);
            }
        } catch (error) {
            console.error('Error fetching teams:', error);
        }
    };

    const getTeamLogo = (teamName) => {
        const team = teams.find(team => team.name === teamName);
        return team ? `${API_BASE_URL}/${team.logo}` : '';
    };

    const handleUnfriend = async (friend) => {
        setFriendToUnfriend(friend);
        setShowConfirmModal(true);
    };

    const confirmUnfriend = async () => {
        try {
            const response = await axios.post(`${API_BASE_URL}/handlers/friends.php`, {
                action: 'unfriend',
                user_id: userId,
                friend_id: friendToUnfriend.id
            });

            if (response.data.success) {
                setFriends(friends.filter(friend => friend.id !== friendToUnfriend.id));
                setMessageStatus({
                    show: true,
                    type: 'success',
                    message: 'Friend removed successfully'
                });
            } else {
                setMessageStatus({
                    show: true,
                    type: 'error',
                    message: 'Failed to remove friend'
                });
            }
        } catch (error) {
            setMessageStatus({
                show: true,
                type: 'error',
                message: 'An error occurred'
            });
            console.error('Error:', error);
        } finally {
            setShowConfirmModal(false);
            setFriendToUnfriend(null);
        }
    };

    const handleSendMessage = async () => {
        if (!messageText.trim()) {
            setMessageStatus({
                show: true,
                type: 'error',
                message: 'Please enter a message'
            });
            return;
        }

        try {
            const response = await axios.post(`${API_BASE_URL}/handlers/send_message.php`, {
                sender_id: userId,
                receiver_id: selectedFriend.id,
                message: messageText
            });

            if (response.data.success) {
                setMessageStatus({
                    show: true,
                    type: 'success',
                    message: 'Message sent successfully'
                });
                setMessageText('');
                setShowMessageModal(false);
            } else {
                setMessageStatus({
                    show: true,
                    type: 'error',
                    message: response.data.message || 'Failed to send message'
                });
            }
        } catch (error) {
            console.error('Error sending message:', error);
            setMessageStatus({
                show: true,
                type: 'error',
                message: error.response?.data?.message || 'An error occurred while sending the message'
            });
        }
    };

    const handleFriendSearch = () => {
        if (!searchQuery.trim()) {
            setMessageStatus({
                show: true,
                type: 'error',
                message: 'Please enter a username to search your friends list'
            });
            return;
        }
        
        const filteredFriends = friends.filter(friend => 
            friend.username.toLowerCase().includes(searchQuery.toLowerCase())
        );

        if (filteredFriends.length === 0) {
            setMessageStatus({
                show: true,
                type: 'info',
                message: `No friends found with username "${searchQuery}". Try using "Find New Friends" to search for new users.`
            });
            // Keep showing existing friends
            setFriends(friends);
        } else {
            setFriends(filteredFriends);
            setMessageStatus({
                show: true,
                type: 'success',
                message: `Found ${filteredFriends.length} friend${filteredFriends.length > 1 ? 's' : ''}`
            });
        }
    };

    const handleNewFriendSearch = async () => {
        if (!searchQuery.trim()) {
            setMessageStatus({
                show: true,
                type: 'error',
                message: 'Please enter a username to search for new friends'
            });
            return;
        }

        try {
            const response = await axios.get(`${API_BASE_URL}/handlers/search_users.php?query=${searchQuery}`);
            if (response.data.success) {
                const currentUserId = localStorage.getItem('userId');
                // Filter out current user and existing friends
                const filteredUsers = response.data.users.filter(user => 
                    user.user_id !== currentUserId && 
                    !friends.some(friend => friend.id === user.user_id)
                );

                if (filteredUsers.length === 0) {
                    setMessageStatus({
                        show: true,
                        type: 'info',
                        message: `No new users found with username "${searchQuery}". Try a different username or check your spelling.`
                    });
                    setNewFriendResults([]);
                } else {
                    setNewFriendResults(filteredUsers);
                    setMessageStatus({
                        show: true,
                        type: 'success',
                        message: `Found ${filteredUsers.length} potential new friend${filteredUsers.length > 1 ? 's' : ''}`
                    });
                }
            }
        } catch (error) {
            setMessageStatus({
                show: true,
                type: 'error',
                message: 'Error searching for users. Please try again.'
            });
            console.error('Error searching users:', error);
        }
    };

    const sendFriendRequest = async (userId) => {
        try {
            const response = await axios.post(`${API_BASE_URL}/handlers/add_friend.php`, {
                user_id: localStorage.getItem('userId'),
                friend_id: userId,
                action: 'request'
            });

            if (response.data.success) {
                setMessageStatus({
                    show: true,
                    type: 'success',
                    message: 'Friend request sent successfully!'
                });
                // Remove user from search results
                setNewFriendResults(prev => prev.filter(user => user.user_id !== userId));
            }
        } catch (error) {
            setMessageStatus({
                show: true,
                type: 'error',
                message: 'Failed to send friend request'
            });
        }
    };

    const handleViewProfile = (username) => {
        navigate(`/user/profile/${username}`);
    };

    const handleChallenge = (friend) => {
        // Handle challenge logic
        console.log('Challenge friend:', friend);
        // You can implement the challenge functionality here
    };

    const renderFriendsList = () => {
        if (loading) {
            return <div>Loading...</div>;
        }

        if (error) {
            return <div className="message-status error">{error}</div>;
        }

        if (friends.length === 0) {
            return <div className="message-status info">No friends found.</div>;
        }

        return (
            <div className="friends-grid">
                {friends.map((friend) => (
                    <div key={friend.id} className="friend-card">
                        <div className="friend-banner">
                            <div className="friend-avatar">
                                {friend.favorite_team ? (
                                    <img src={getTeamLogo(friend.favorite_team)} alt={friend.favorite_team} className="team-avatar" />
                                ) : (
                                    <div className="avatar-placeholder">{friend.username.charAt(0).toUpperCase()}</div>
                                )}
                            </div>
                            <div className="friend-info">
                                <h3 onClick={() => handleViewProfile(friend.username)} style={{ cursor: 'pointer' }}>
                                    {friend.username}
                                </h3>
                                {friend.favorite_team && <span>{friend.favorite_team}</span>}
                            </div>
                        </div>
                        
                        <div className="friend-stats">
                            <div className="stat-item">
                                <span className="stat-label">Total Bets</span>
                                <span className="stat-value">{friend.total_bets}</span>
                            </div>
                            <div className="stat-item">
                                <span className="stat-label">Win Rate</span>
                                <span className="stat-value">{friend.win_rate}%</span>
                            </div>
                            <div className="stat-item">
                                <span className="stat-label">Score</span>
                                <span className="stat-value">{friend.leaderboard_score || 0}</span>
                            </div>
                        </div>

                        <div className="friend-actions">
                            <button 
                                className="message-btn"
                                onClick={() => {
                                    setSelectedFriend(friend);
                                    setShowMessageModal(true);
                                }}
                            >
                                Message
                            </button>
                            <button 
                                className="unfriend-btn"
                                onClick={() => handleUnfriend(friend)}
                            >
                                <FaUserMinus /> Unfriend
                            </button>
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    return (
        <div className="friends-container">
            <div className="friends-header">
                <div className="friends-title-section">
                    <h1>My Friends</h1>
                    <span className="friends-count">{friends.length} Friends</span>
                </div>
                
                <div className="friends-search-section">
                    <div className="search-type-toggle">
                        <button 
                            className={`toggle-btn ${searchType === 'friends' ? 'active' : ''}`}
                            onClick={() => {
                                setSearchType('friends');
                                setSearchQuery('');
                                setNewFriendResults([]);
                                setMessageStatus({ show: false });
                            }}
                        >
                            Search My Friends
                        </button>
                        <button 
                            className={`toggle-btn ${searchType === 'new' ? 'active' : ''}`}
                            onClick={() => {
                                setSearchType('new');
                                setSearchQuery('');
                                setMessageStatus({ show: false });
                            }}
                        >
                            Find New Friends
                        </button>
                    </div>
                    
                    <div className="search-instructions">
                        {searchType === 'friends' ? (
                            <p>Search through your existing friends list</p>
                        ) : (
                            <p>Search for new users to add as friends</p>
                        )}
                    </div>
                    
                    <div className="search-wrapper">
                        <input
                            type="text"
                            placeholder={searchType === 'friends' ? 
                                "Enter friend's username..." : 
                                "Search for new friends by username..."
                            }
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    searchType === 'friends' ? handleFriendSearch() : handleNewFriendSearch();
                                }
                            }}
                        />
                        <button onClick={searchType === 'friends' ? handleFriendSearch : handleNewFriendSearch}>
                            <FaSearch /> Search
                        </button>
                    </div>
                </div>
            </div>

            {messageStatus.show && (
                <div className={`alert alert-${messageStatus.type}`}>
                    {messageStatus.message}
                </div>
            )}
            
            {renderFriendsList()}

            {showMessageModal && selectedFriend && (
                <div className="modal-overlay">
                    <div className="message-modal">
                        <button className="close-button" onClick={() => setShowMessageModal(false)}>×</button>
                        
                        <div className="modal-user-preview">
                            <div className="modal-user-avatar">
                                <img 
                                    src={getTeamLogo(selectedFriend.favorite_team)} 
                                    alt={selectedFriend.favorite_team}
                                />
                            </div>
                            <div className="modal-user-info">
                                <h3>{selectedFriend.username}</h3>
                                <span className="modal-team-name">{selectedFriend.favorite_team}</span>
                            </div>
                            <div className="modal-user-stats">
                                <div className="modal-stat">
                                    <FaTrophy className="modal-stat-icon" />
                                    <div className="modal-stat-details">
                                        <label>Total Wins</label>
                                        <span>{selectedFriend.wins || 0}</span>
                                    </div>
                                </div>
                                <div className="modal-stat">
                                    <FaStar className="modal-stat-icon" />
                                    <div className="modal-stat-details">
                                        <label>Points</label>
                                        <span>{selectedFriend.leaderboard_score || 0}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-user-stats-grid">
                                <div className="modal-stat-grid-item">
                                    <label>Wins</label>
                                    <span>{selectedFriend.wins || 0}</span>
                                </div>
                                <div className="modal-stat-grid-item">
                                    <label>Draws</label>
                                    <span>{selectedFriend.draws || 0}</span>
                                </div>
                                <div className="modal-stat-grid-item">
                                    <label>Losses</label>
                                    <span>{selectedFriend.losses || 0}</span>
                                </div>
                            </div>
                        </div>

                        <div className="message-input-section">
                            <div className="message-input-header">
                                <label>Send Message to {selectedFriend.username}</label>
                            </div>
                            <div className="message-textarea-container">
                                <textarea
                                    className="message-textarea"
                                    value={messageText}
                                    onChange={(e) => setMessageText(e.target.value)}
                                    placeholder="Type your message here..."
                                    rows="6"
                                />
                            </div>
                            <div className="message-modal-footer">
                                <button 
                                    className="modal-btn cancel-btn"
                                    onClick={() => {
                                        setShowMessageModal(false);
                                        setMessageText('');
                                    }}
                                >
                                    Cancel
                                </button>
                                <button 
                                    className="modal-btn send-btn"
                                    onClick={handleSendMessage}
                                    disabled={!messageText.trim()}
                                >
                                    <FaEnvelope className="send-icon" />
                                    Send Message
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {searchType === 'new' && newFriendResults.length > 0 && (
                <div className="new-friends-results">
                    <h3>Search Results</h3>
                    <div className="search-results-grid">
                        {newFriendResults.map(user => (
                            <div key={user.user_id} className="friend-card">
                                <div className="friend-header">
                                    <div className="friend-avatar">
                                        <img 
                                            src={getTeamLogo(user.favorite_team)} 
                                            alt={user.favorite_team}
                                        />
                                    </div>
                                    <div className="friend-basic-info">
                                        <h3>{user.username}</h3>
                                        <span className="favorite-team">{user.favorite_team}</span>
                                    </div>
                                </div>
                                
                                <div className="friend-stats-grid">
                                    <div className="stat-item">
                                        <label>Total Bets</label>
                                        <span>{user.total_bets || 0}</span>
                                    </div>
                                    <div className="stat-item">
                                        <label>Win Rate</label>
                                        <span>{user.win_rate || 0}%</span>
                                    </div>
                                    <div className="stat-item">
                                        <label>Score</label>
                                        <span>{user.leaderboard_score || 0}</span>
                                    </div>
                                </div>
                                
                                <button 
                                    className="add-friend-btn"
                                    onClick={() => sendFriendRequest(user.user_id)}
                                >
                                    <FaUserPlus /> Send Friend Request
                                </button>
                            </div>
                        ))}
                    </div>
                </div>
            )}

            {showConfirmModal && friendToUnfriend && (
                <div className="modal-overlay">
                    <div className="confirm-modal">
                        <div className="confirm-content">
                            <div className="confirm-icon">
                                <FaUserMinus />
                            </div>
                            <h3>Unfriend Confirmation</h3>
                            <p>Are you sure you want to unfriend <span className="highlight">{friendToUnfriend.username}</span>?</p>
                            <div className="confirm-actions">
                                <button 
                                    className="cancel-btn"
                                    onClick={() => {
                                        setShowConfirmModal(false);
                                        setFriendToUnfriend(null);
                                    }}
                                >
                                    Cancel
                                </button>
                                <button 
                                    className="confirm-btn"
                                    onClick={confirmUnfriend}
                                >
                                    Confirm Unfriend
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Friends;
