import React, { useState, useEffect } from 'react';
import axios from '../utils/axiosConfig';
import { FaUsers, FaChartLine, FaTrash } from 'react-icons/fa';
import './LeagueUserManagement.css';

function LeagueUserManagement() {
    const [leagues, setLeagues] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [selectedLeague, setSelectedLeague] = useState(null);
    const [showLeaderboard, setShowLeaderboard] = useState(false);
    const [leagueUsers, setLeagueUsers] = useState([]);
    const [loadingUsers, setLoadingUsers] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [usersPerPage] = useState(10);

    useEffect(() => {
        fetchLeagues();
    }, []);

    const fetchLeagues = async () => {
        try {
            setLoading(true);
            setError('');
            const response = await axios.get('/backend/handlers/league_management.php');
            if (response.data.status === 200) {
                setLeagues(response.data.data || []);
            } else {
                setError(response.data.message || 'Failed to fetch leagues');
            }
        } catch (err) {
            console.error('Error fetching leagues:', err);
            setError('Failed to fetch leagues. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const fetchLeagueUsers = async (leagueId) => {
        try {
            setLoadingUsers(true);
            setError('');
            const response = await axios.get(`/backend/handlers/admin/get_league_users.php?league_id=${leagueId}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('adminToken')}`
                }
            });
            console.log('League users response:', response.data); // Debug log
            if (response.data.status === 200) {
                setLeagueUsers(response.data.data || []);
                setSelectedLeague(leagues.find(l => l.league_id === leagueId));
                setShowLeaderboard(true);
                setCurrentPage(1); // Reset to first page when loading new users
            } else {
                setError(response.data.message || 'Failed to fetch league users');
            }
        } catch (err) {
            console.error('Error fetching league users:', err);
            setError('Failed to fetch league users: ' + (err.response?.data?.message || err.message));
        } finally {
            setLoadingUsers(false);
        }
    };

    const handleDeleteUser = async (userId, leagueId) => {
        if (!window.confirm('Are you sure you want to remove this user from the league? Their registration fee will be refunded.')) {
            return;
        }

        try {
            setError('');
            const response = await axios.post('/backend/handlers/admin/remove_league_user.php', {
                user_id: userId,
                league_id: leagueId
            });

            if (response.data.status === 200) {
                setSuccess('User successfully removed from the league');
                await fetchLeagueUsers(leagueId);
            } else {
                setError(response.data.message || 'Failed to remove user from league');
            }
        } catch (err) {
            console.error('Error removing user:', err);
            setError('Failed to remove user. Please try again.');
        }
    };

    // Get current users for pagination
    const indexOfLastUser = currentPage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;
    const currentUsers = leagueUsers.slice(indexOfFirstUser, indexOfLastUser);
    const totalPages = Math.ceil(leagueUsers.length / usersPerPage);

    // Change page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const Pagination = () => {
        return (
            <div className="pagination">
                <button 
                    onClick={() => paginate(currentPage - 1)} 
                    disabled={currentPage === 1}
                    className="pagination-button"
                >
                    Previous
                </button>
                <span className="page-info">
                    Page {currentPage} of {totalPages} ({leagueUsers.length} total users)
                </span>
                <button 
                    onClick={() => paginate(currentPage + 1)} 
                    disabled={currentPage === totalPages}
                    className="pagination-button"
                >
                    Next
                </button>
            </div>
        );
    };

    const LeaderboardModal = () => {
        return (
            <div className="modal-overlay" onClick={() => setShowLeaderboard(false)}>
                <div className="leaderboard-modal" onClick={e => e.stopPropagation()}>
                    <div className="modal-header">
                        <h2>League Users</h2>
                        <button className="close-modal" onClick={() => setShowLeaderboard(false)}>&times;</button>
                    </div>
                    <div className="leaderboard-table">
                        <table>
                            <thead>
                                <tr className="table-header">
                                    <th>Rank</th>
                                    <th>User</th>
                                    <th>Points</th>
                                    <th>W/D/L</th>
                                    <th>Deposit</th>
                                    <th>Join Date</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {leagueUsers.map((user, index) => (
                                    <tr key={user.user_id} className="table-row">
                                        <td>{index + 1}</td>
                                        <td>{user.username}</td>
                                        <td className="points">{user.points || 0}</td>
                                        <td className="stats">
                                            <span>{user.wins || 0}</span>/
                                            <span>{user.draws || 0}</span>/
                                            <span>{user.losses || 0}</span>
                                        </td>
                                        <td className="deposit">FC{parseFloat(user.deposit_amount || 0).toLocaleString()}</td>
                                        <td>{new Date(user.join_date).toLocaleDateString()}</td>
                                        <td>
                                            <button 
                                                className="delete-btn"
                                                onClick={() => handleDeleteUser(user.user_id, selectedLeague)}
                                            >
                                                Delete
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    };

    if (loading) {
        return <div className="loading">Loading leagues...</div>;
    }

    return (
        <div className="league-user-management">
            <header className="page-header">
                <h1>League User Management</h1>
                {error && <div className="error-message">{error}</div>}
                {success && <div className="success-message">{success}</div>}
            </header>

            {loading ? (
                <div className="loading">Loading...</div>
            ) : (
                <div className="leagues-table">
                    <table>
                        <thead>
                            <tr className="table-header">
                                <th>League</th>
                                <th>Description</th>
                                <th>Members</th>
                                <th>Bet Range</th>
                                <th>Status</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {leagues.map(league => (
                                <tr key={league.league_id} className="table-row">
                                    <td>
                                        <div className="name">
                                            {league.name}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="league-description">
                                            {league.description || 'No description available'}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="members">
                                            <FaUsers />
                                            {league.member_count || 0}
                                        </div>
                                    </td>
                                    <td className="bet-range">
                                        <div className="bet-range-container">
                                            <div>FC{parseFloat(league.min_bet_amount).toLocaleString()}</div>
                                            <div>FC{parseFloat(league.max_bet_amount).toLocaleString()}</div>
                                        </div>
                                    </td>
                                    <td>
                                        <span className={`status-badge ${league.status.toLowerCase()}`}>
                                            {league.status}
                                        </span>
                                    </td>
                                    <td>
                                        <button 
                                            className="view-users-btn"
                                            onClick={() => fetchLeagueUsers(league.league_id)}
                                        >
                                            View Users
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}

            {showLeaderboard && <LeaderboardModal />}
            
            <Pagination />
        </div>
    );
}

export default LeagueUserManagement; 