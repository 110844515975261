import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import { FaCoins, FaDice, FaTrophy, FaChartLine, FaUsers, FaEnvelope, FaStar, FaUserPlus } from 'react-icons/fa';
import './Profile.css';

const API_BASE_URL = '/backend';

function Profile() {
    const { username } = useParams();
    const [profile, setProfile] = useState({
        username: '',
        email: '',
        favorite_team: '',
        balance: 0,
        total_bets: 0,
        win_rate: 0,
        leaderboard_score: 0
    });
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [searchError, setSearchError] = useState('');
    const [teams, setTeams] = useState([]);
    const [showBetDetailsModal, setShowBetDetailsModal] = useState(false);
    const [selectedBet, setSelectedBet] = useState(null);
    const [showMessageModal, setShowMessageModal] = useState(false);
    const [selectedFriend, setSelectedFriend] = useState(null);
    const [messageText, setMessageText] = useState('');
    const [messageStatus, setMessageStatus] = useState({ show: false, type: '', message: '' });

    const getGreeting = () => {
        const hour = new Date().getHours();
        if (hour < 12) return 'Good Morning';
        if (hour < 18) return 'Good Afternoon';
        return 'Good Evening';
    };

    useEffect(() => {
        const currentUsername = username || localStorage.getItem('username');
        if (currentUsername) {
            fetchData(currentUsername);
            fetchTeams();
        }
    }, [username]);

    const fetchData = async (username) => {
        try {
            const response = await axios.get(`${API_BASE_URL}/handlers/profile.php?username=${username}`);
            if (response.data.success) {
                setProfile(response.data.profile);
            }
        } catch (error) {
            console.error('Error fetching profile:', error);
        }
    };

    const fetchTeams = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/handlers/team_management.php`);
            if (response.data.status === 200) {
                setTeams(response.data.data);
            }
        } catch (error) {
            console.error('Error fetching teams:', error);
        }
    };

    const handleShowBetDetails = (bet) => {
        setSelectedBet(bet);
        setShowBetDetailsModal(true);
    };

    const getTeamLogo = (teamName) => {
        const team = teams.find(team => team.name === teamName);
        return team ? `${API_BASE_URL}/${team.logo}` : '';
    };

    const handleSearch = async () => {
        try {
            setSearchError('');
            if (!searchQuery.trim()) {
                setSearchError('Please enter a username to search');
                return;
            }
            const response = await axios.get(`${API_BASE_URL}/handlers/search_users.php?query=${searchQuery}`);
            if (response.data.success && response.data.users.length > 0) {
                const currentUserId = localStorage.getItem('userId');
                const filteredUsers = response.data.users.filter(user => user.user_id !== currentUserId);
                setSearchResults(filteredUsers);
                if (filteredUsers.length === 0) {
                    setSearchError('No users found matching your search');
                }
            } else {
                setSearchResults([]);
                setSearchError('No users found matching your search');
            }
        } catch (error) {
            setSearchResults([]);
            setSearchError('Error searching for users');
        }
    };

    const addFriend = async (friendId) => {
        try {
            const userId = localStorage.getItem('userId');
            const response = await axios.post(`${API_BASE_URL}/handlers/add_friend.php`, {
                user_id: userId,
                friend_id: friendId,
                action: 'request'
            });

            if (response.data.success) {
                setSearchResults([]);
                setSearchQuery('');
                setMessageStatus({
                    show: true,
                    type: 'success',
                    message: 'Friend request sent successfully!'
                });
                setTimeout(() => {
                    setMessageStatus({ show: false, type: '', message: '' });
                }, 3000);
            } else {
                setSearchError(response.data.message);
            }
        } catch (error) {
            setSearchError('Failed to send friend request');
        }
    };

    const handleSendMessage = (friend) => {
        if (friend.username === localStorage.getItem('username')) {
            return;
        }
        setSelectedFriend(friend);
        setShowMessageModal(true);
    };

    const sendMessage = async () => {
        try {
            const userId = localStorage.getItem('userId');
            const response = await axios.post(`${API_BASE_URL}/handlers/send_message.php`, {
                sender_id: userId,
                receiver_id: selectedFriend.user_id,
                message: messageText
            });

            if (response.data.success) {
                setMessageText('');
                setShowMessageModal(false);
                setMessageStatus({
                    show: true,
                    type: 'success',
                    message: 'Message sent successfully!'
                });
                setTimeout(() => {
                    setMessageStatus({ show: false, type: '', message: '' });
                }, 3000);
            } else {
                setMessageStatus({
                    show: true,
                    type: 'error',
                    message: 'Failed to send message: ' + response.data.message
                });
            }
        } catch (error) {
            console.error('Error sending message:', error);
            setMessageStatus({
                show: true,
                type: 'error',
                message: 'Failed to send message. Please try again.'
            });
        }
    };

    return (
        <div className="profile-container">
            {messageStatus.show && (
                <div className={`message-status ${messageStatus.type}`}>
                    {messageStatus.message}
                </div>
            )}
            <div className="profile-header">
                <div className="profile-banner">
                    <div className="profile-avatar">
                        <img
                            src={getTeamLogo(profile.favorite_team)}
                            alt={profile.favorite_team}
                            className="team-avatar"
                        />
                    </div>
                    <div className="profile-info-header">
                        <h1 className="welcome-text">{getGreeting()}, {profile.username}</h1>
                        <span className="member-since">Member since 2024</span>
                        {username && username !== localStorage.getItem('username') && (
                            <button
                                className="banner-message-btn"
                                onClick={() => handleSendMessage(profile)}
                            >
                                <FaEnvelope className="msg-icon" /> Send Message
                            </button>
                        )}
                    </div>
                </div>

                <div className="stats-container">
                    <div className="stats-grid">
                        <div className="stat-card bets">
                            <FaDice className="stat-icon" />
                            <div className="stat-info">
                                <h3>Total Bets</h3>
                                <span className="stat-value">{profile.total_bets || 0}</span>
                            </div>
                        </div>
                        <div className="stat-card winrate">
                            <FaTrophy className="stat-icon" />
                            <div className="stat-info">
                                <h3>Win Rate</h3>
                                <span className="stat-value">{profile.win_rate || 0}%</span>
                            </div>
                        </div>
                        <div className="stat-card score">
                            <FaChartLine className="stat-icon" />
                            <div className="stat-info">
                                <h3>Rank</h3>
                                <span className="stat-value">#{profile.leaderboard_position || 0}</span>
                            </div>
                        </div>
                        <div className="stat-card points">
                            <FaStar className="stat-icon" />
                            <div className="stat-info">
                                <h3>Points</h3>
                                <span className="stat-value">{profile.leaderboard_score || 0}</span>
                            </div>
                        </div>
                        <div className="stat-card friends">
                            <FaUsers className="stat-icon" />
                            <div className="stat-info">
                                <h3>Friends</h3>
                                <span className="stat-value">{profile.friends?.length || 0}</span>
                            </div>
                        </div>
                        <div className="stat-card requests">
                            <FaUserPlus className="stat-icon" />
                            <div className="stat-info">
                                <h3>Requests</h3>
                                <span className="stat-value">{profile.friend_requests || 0}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="search-section card">
                <h2>Find Friends</h2>
                <div className="search-box">
                    <input
                        type="text"
                        placeholder="Search users..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <button onClick={handleSearch}>Search</button>
                </div>
                {searchError && <div className="search-error">{searchError}</div>}
                {searchResults.length > 0 && (
                    <div className="search-results">
                        {searchResults.map(user => (
                            <div key={user.user_id} className="user-search-card">
                                <div className="user-search-info">
                                    <div className="user-primary-info">
                                        <span className="username">{user.username}</span>
                                        <div className="team-info">
                                            <img src={getTeamLogo(user.favorite_team)} alt={user.favorite_team} className="team-logo-tiny" />
                                            <span className="favorite-team">{user.favorite_team}</span>
                                        </div>
                                    </div>
                                    <div className="user-stats">
                                        <span>Total Bets: {user.total_bets || 0}</span>
                                        <span>Win Rate: {user.win_rate || 0}%</span>
                                        <span>Score: {user.leaderboard_score || 0}</span>
                                    </div>
                                </div>
                                <button onClick={() => addFriend(user.user_id)} className="add-friend-btn">
                                    <FaUserPlus /> Send Friend Request
                                </button>
                            </div>
                        ))}
                    </div>
                )}
            </div>
            <div className="profile-recent-bets-section card">
                <h2>Recent Bets</h2>
                <div className="profile-bets-table-container">
                    <table className="profile-recent-bets-table">
                        <thead>
                            <tr>
                                <th>Reference</th>
                                <th>Teams</th>
                                <th>Amount</th>
                                <th>Status</th>
                                <th>Returns</th>
                            </tr>
                        </thead>
                        <tbody>
                            {profile.recent_bets && profile.recent_bets.map((bet, index) => (
                                <tr key={bet.bet_id} onClick={() => handleShowBetDetails(bet)}>
                                    <td>
                                        <span className="profile-recent-bet-ref">
                                            {bet.unique_code?.toUpperCase() || `${bet.bet_id}DNRBKCC`}
                                        </span>
                                    </td>
                                    <td>
                                        <div className="profile-recent-team-cell">
                                            <div className="profile-recent-team-selector">
                                                <div className="profile-recent-team-logo">
                                                    <img
                                                        src={getTeamLogo(bet.team_a)}
                                                        alt={bet.team_a}
                                                        onError={(e) => {
                                                            e.target.src = '/default-team-logo.png';
                                                        }}
                                                    />
                                                </div>
                                                <span className="profile-recent-team-name">{bet.team_a}</span>
                                                <span className="profile-recent-username">{bet.user1_name}</span>
                                            </div>
                                            <span className="profile-recent-vs">VS</span>
                                            <div className="profile-recent-team-selector">
                                                <div className="profile-recent-team-logo">
                                                    <img
                                                        src={getTeamLogo(bet.team_b)}
                                                        alt={bet.team_b}
                                                        onError={(e) => {
                                                            e.target.src = '/default-team-logo.png';
                                                        }}
                                                    />
                                                </div>
                                                <span className="profile-recent-team-name">{bet.team_b}</span>
                                                <span className="profile-recent-username">{bet.user2_name}</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <span className="profile-recent-bet-amount">{bet.amount_user1}</span>
                                    </td>
                                    <td>
                                        <span className={`profile-recent-bet-status ${bet.bet_status.toLowerCase()}`}>
                                            {bet.bet_status}
                                        </span>
                                    </td>
                                    <td>
                                        <div className="profile-recent-returns">
                                            <div className="profile-recent-return-item">
                                                <span className="profile-recent-return-label">Win:</span>
                                                <span className="profile-recent-return-value">{bet.potential_return_win_user1} FC</span>
                                            </div>
                                            <div className="profile-recent-return-item">
                                                <span className="profile-recent-return-label">Draw:</span>
                                                <span className="profile-recent-return-value">{bet.potential_return_draw_user1} FC</span>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="friends-section">
                <div className="section-header">
                    <h2>Friends ({profile.friends?.length || 0})</h2>
                    <div className="search-box">
                        <input
                            type="text"
                            placeholder="Search users..."
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                        <button onClick={handleSearch}>Search</button>
                    </div>
                </div>

                <div className="friends-grid">
                    {profile.friends?.map(friend => (
                        <div key={friend.user_id} className="friend-card">
                            <div className="friend-header">
                                <div className="friend-avatar">
                                    <img
                                        src={getTeamLogo(friend.favorite_team)}
                                        alt={friend.favorite_team}
                                    />
                                </div>
                                <div className="friend-basic-info">
                                    <Link to={`/user/profile/${friend.username}`}>
                                        <h3>{friend.username}</h3>
                                    </Link>
                                    <span className="favorite-team">{friend.favorite_team}</span>
                                </div>
                            </div>

                            <div className="friend-stats-grid">
                                <div className="stat-item">
                                    <label>Total Bets</label>
                                    <span>{friend.total_bets}</span>
                                </div>
                                <div className="stat-item">
                                    <label>Win Rate</label>
                                    <span>{friend.win_rate}%</span>
                                </div>
                                <div className="stat-item">
                                    <label>Score</label>
                                    <span>{friend.leaderboard_score}</span>
                                </div>
                            </div>

                            <button
                                className="message-btn"
                                onClick={() => handleSendMessage(friend)}
                            >
                                Send Message
                            </button>
                        </div>
                    ))}
                </div>
            </div>
            {showBetDetailsModal && selectedBet && (
                <div className="modal-overlay">
                    <div className="bet-details-modal">
                        <button className="close-button" onClick={() => setShowBetDetailsModal(false)}>×</button>
                        <div className="modal-left">
                            <h3 className="reference-title">
                                Bet Reference: {selectedBet.unique_code?.toUpperCase() || `${selectedBet.bet_id}DNRBKCC`}
                            </h3>

                            <div className="teams-match">
                                <div className={`team-card user1`}>
                                    <img src={getTeamLogo(selectedBet.team_a)} alt={selectedBet.team_a} />
                                    <div className="team-name">{selectedBet.team_a}</div>
                                    <div className="team-odds user1">Odds: {selectedBet.odds_team_a}</div>
                                    <div className="team-user">{selectedBet.user1_name}</div>
                                </div>

                                <div className="vs-badge">VS</div>

                                <div className={`team-card user2`}>
                                    <img src={getTeamLogo(selectedBet.team_b)} alt={selectedBet.team_b} />
                                    <div className="team-name">{selectedBet.team_b}</div>
                                    <div className="team-odds user2">Odds: {selectedBet.odds_team_b}</div>
                                    <div className="team-user">{selectedBet.user2_name}</div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-right">
                            <div className="status-badge-large" data-status={selectedBet.bet_status}>
                                {selectedBet.bet_status.toUpperCase()}
                            </div>

                            <div className="bet-details-grid">
                                <div className="detail-item amount">
                                    <div className="detail-label">Your Bet Amount</div>
                                    <div className="detail-value amount">{selectedBet.amount_user2} FanCoins</div>
                                </div>
                                <div className="detail-item return">
                                    <div className="detail-label">Your Potential Return</div>
                                    <div className="detail-value return">{selectedBet.potential_return_win_user2} FanCoins</div>
                                </div>
                                <div className="detail-item">
                                    <div className="detail-label">Created At</div>
                                    <div className="detail-value">{new Date(selectedBet.created_at).toLocaleString()}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {showMessageModal && selectedFriend && (
                <div className="modal-overlay">
                    <div className="message-modal">
                        <button className="close-button" onClick={() => setShowMessageModal(false)}>×</button>

                        <div className="modal-user-preview">
                            <div className="modal-user-avatar">
                                <img
                                    src={getTeamLogo(selectedFriend.favorite_team)}
                                    alt={selectedFriend.favorite_team}
                                />
                            </div>
                            <div className="modal-user-info">
                                <h3>{selectedFriend.username}</h3>
                                <span className="modal-team-name">{selectedFriend.favorite_team}</span>
                            </div>
                            <div className="modal-user-stats">
                                <div className="modal-stat">
                                    <FaTrophy className="modal-stat-icon" />
                                    <div className="modal-stat-details">
                                        <label>Total Wins</label>
                                        <span>{selectedFriend.wins || 0}</span>
                                    </div>
                                </div>
                                <div className="modal-stat">
                                    <FaStar className="modal-stat-icon" />
                                    <div className="modal-stat-details">
                                        <label>Points</label>
                                        <span>{selectedFriend.leaderboard_score || 0}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-user-stats-grid">
                                <div className="modal-stat-grid-item">
                                    <label>Wins</label>
                                    <span>{selectedFriend.wins || 0}</span>
                                </div>
                                <div className="modal-stat-grid-item">
                                    <label>Draws</label>
                                    <span>{selectedFriend.draws || 0}</span>
                                </div>
                                <div className="modal-stat-grid-item">
                                    <label>Losses</label>
                                    <span>{selectedFriend.losses || 0}</span>
                                </div>
                            </div>
                        </div>

                        <div className="message-input-section">
                            <div className="message-input-header">
                                <label>Send Message to {selectedFriend.username}</label>
                            </div>
                            <div className="message-textarea-container">
                                <textarea
                                    className="message-textarea"
                                    value={messageText}
                                    onChange={(e) => setMessageText(e.target.value)}
                                    placeholder="Type your message here..."
                                    rows="6"
                                />
                            </div>
                            <div className="message-modal-footer">
                                <button
                                    className="modal-btn cancel-btn"
                                    onClick={() => {
                                        setShowMessageModal(false);
                                        setMessageText('');
                                    }}
                                >
                                    Cancel
                                </button>
                                <button
                                    className="modal-btn send-btn"
                                    onClick={sendMessage}
                                    disabled={!messageText.trim()}
                                >
                                    <FaEnvelope className="send-icon" />
                                    Send Message
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Profile;