import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './ViewBets.css';

const API_BASE_URL = '/backend';

function AcceptedBets() {
  const [acceptedBets, setAcceptedBets] = useState([]);
  const [teams, setTeams] = useState([]);
  const [showBetDetailsModal, setShowBetDetailsModal] = useState(false);
  const [selectedBet, setSelectedBet] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage] = useState(20);
  const userId = localStorage.getItem('userId');
  const navigate = useNavigate();

  const fetchBets = useCallback(async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/handlers/get_accepted_bets.php?userId=${userId}&page=${currentPage}&limit=${itemsPerPage}`);
      if (response.data.success) {
        setAcceptedBets(response.data.bets || []);
        setTotalPages(response.data.pagination.totalPages);
      } else {
        setError('Failed to fetch accepted bets');
        console.error("Error fetching bets:", response.data.message || "Unknown error");
      }
    } catch (error) {
      console.error('Error fetching bets:', error);
      setError('An error occurred while fetching accepted bets.');
    } finally {
      setLoading(false);
    }
  }, [userId, currentPage, itemsPerPage]);

  const fetchTeams = useCallback(async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/handlers/team_management.php`);
      if (response.data.status === 200) {
        setTeams(response.data.data || []);
      } else {
        setError('Failed to fetch teams');
        console.error('Failed to fetch teams:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching teams:', error);
      setError('An error occurred while fetching teams.');
    }
  }, []);

  useEffect(() => {
    if (!userId) {
      navigate('/user/login');
    } else {
      fetchBets();
      fetchTeams();
    }
  }, [userId, fetchBets, fetchTeams, navigate]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const renderPagination = () => {
    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={`pagination-button ${currentPage === i ? 'active' : ''}`}
        >
          {i}
        </button>
      );
    }
    return (
      <div className="pagination">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="pagination-button"
        >
          Previous
        </button>
        {pages}
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="pagination-button"
        >
          Next
        </button>
      </div>
    );
  };

  const getTeamLogo = (teamName) => {
    const team = teams.find(team => team.name === teamName);
    return team ? `${API_BASE_URL}/${team.logo}` : '';
  };

  const getReference = (bet) => {
    return (bet.unique_code || `${bet.bet_id}DNRBKCC`).toUpperCase();
  };

  const handleShowBetDetails = (bet) => {
    setSelectedBet(bet);
    setShowBetDetailsModal(true);
  };

  const calculateOdds = (bet) => {
    const totalPot = parseFloat(bet.amount_user2) * 2;
    const winReturn = parseFloat(bet.potential_return_win_user2);
    const lossReturn = parseFloat(bet.potential_return_loss_user2);
    const drawReturn = parseFloat(bet.potential_return_draw_user2);
    
    const winOdds = (winReturn / totalPot * 100).toFixed(1);
    const lossOdds = (lossReturn / totalPot * 100).toFixed(1);
    const drawOdds = (drawReturn / totalPot * 100).toFixed(1);
    
    return {
      win: { odds: winOdds, return: winReturn },
      loss: { odds: lossOdds, return: lossReturn },
      draw: { odds: drawOdds, return: drawReturn }
    };
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    });
  };

  const getUserStatus = (bet) => {
    if (bet.bet_status.toLowerCase() === 'joined' || bet.bet_status.toLowerCase() === 'completed') {
      return 'Opponent';
    }
    return 'Waiting for Opponent';
  };

  return (
    <div className="view-bets-container">
      <div className="title-section">
        <h2>Accepted Bets</h2>
        <div className="title-line"></div>
      </div>

      {/* Desktop Table View */}
      <div className="table-responsive">
        <table className="bets-table rounded-table">
          <thead>
            <tr>
              <th>#</th>
              <th>Ref</th>
              <th colSpan="3" className="teams-header compact">Teams</th>
              <th>Amount</th>
              <th>Return</th>
              <th>Status</th>
              <th>Match&nbsp;Date</th>
            </tr>
          </thead>
          <tbody>
            {acceptedBets.map((bet, index) => (
              <tr key={bet.bet_id}>
                <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                <td>
                  <span className="reference" onClick={() => handleShowBetDetails(bet)}>
                    {getReference(bet)}
                  </span>
                </td>
                <td className="team-cell compact">
                  <div className="team-info">
                    <img src={getTeamLogo(bet.team_a)} alt={bet.team_a} className="team-logo" />
                    <span>{bet.team_a}</span>
                    {bet.bet_choice_user2 === 'team_a_win' && <span className="pick-badge">Your Pick</span>}
                  </div>
                </td>
                <td className="vs-cell compact">
                  <div className="vs-indicator">VS</div>
                </td>
                <td className="team-cell compact">
                  <div className="team-info">
                    <img src={getTeamLogo(bet.team_b)} alt={bet.team_b} className="team-logo" />
                    <span>{bet.team_b}</span>
                    {bet.bet_choice_user2 === 'team_b_win' && <span className="pick-badge">Your Pick</span>}
                  </div>
                </td>
                <td className="amount-cell">
                  {bet.amount_user2} <span className="currency">FanCoins</span>
                </td>
                <td className="return-cell">
                  {bet.potential_return_win_user2} <span className="currency">FanCoins</span>
                </td>
                <td>
                  <div className="status-container">
                    <div className={`status-badge ${bet.bet_status}`}>
                      <span className="status-dot"></span>
                      <span className="status-text">
                        {bet.bet_status === 'joined' && 'Joined'}
                        {bet.bet_status === 'completed' && 'Completed'}
                      </span>
                    </div>
                  </div>
                </td>
                <td className="date-cell">
                  <div className="date-display">
                    <div className="date-line">{new Date(bet.match_date).toLocaleDateString()}</div>
                    <div className="time-line">{new Date(bet.match_date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Mobile Card View */}
      <div className="mobile-bets-grid">
        {acceptedBets.map((bet) => (
          <div 
            key={bet.bet_id}
            className="mobile-bet-card"
          >
            <div className="mobile-bet-header">
              <span className="mobile-bet-ref" onClick={() => handleShowBetDetails(bet)}>
                {getReference(bet)}
              </span>
              <div className={`status-badge ${bet.bet_status.toLowerCase()}`}>
                <span className="status-dot"></span>
                {bet.bet_status === 'joined' ? 'BET MATCHED' : bet.bet_status.toUpperCase()}
              </div>
            </div>

            <div className="mobile-users-section">
              <div className="mobile-user-info">
                <span className="mobile-user-name">{bet.username}</span>
                <span className="mobile-user-status creator">Creator</span>
              </div>
              <div className="mobile-vs-divider">VS</div>
              <div className="mobile-user-info">
                <span className="mobile-user-name">{bet.username2}</span>
                <span className="mobile-user-status opponent">Opponent</span>
              </div>
            </div>

            <div className="mobile-teams-container">
              <div className="mobile-team">
                <img 
                  src={getTeamLogo(bet.team_a)}
                  alt={bet.team_a}
                  className="mobile-team-logo"
                />
                <span className="mobile-team-name">{bet.team_a}</span>
                {bet.bet_choice_user2 === 'team_a_win' && (
                  <span className="mobile-pick-badge">Your Pick</span>
                )}
              </div>
              <div className="mobile-vs">VS</div>
              <div className="mobile-team">
                <img 
                  src={getTeamLogo(bet.team_b)}
                  alt={bet.team_b}
                  className="mobile-team-logo"
                />
                <span className="mobile-team-name">{bet.team_b}</span>
                {bet.bet_choice_user2 === 'team_b_win' && (
                  <span className="mobile-pick-badge">Your Pick</span>
                )}
              </div>
            </div>

            <div className="mobile-bet-details">
              <div className="mobile-detail-item">
                <span className="mobile-detail-label">Amount</span>
                <span className="mobile-detail-value">{bet.amount_user2} FanCoins</span>
              </div>
              <div className="mobile-detail-item">
                <span className="mobile-detail-label">Potential Return</span>
                <span className="mobile-detail-value win">+{bet.potential_return_win_user2} FanCoins</span>
              </div>
              <div className="mobile-detail-item full-width">
                <span className="mobile-detail-label">Match Date</span>
                <span className="mobile-detail-value">
                  <div className="mobile-date-display">
                    <div className="mobile-date-line">{new Date(bet.match_date).toLocaleDateString()}</div>
                    <div className="mobile-time-line">{new Date(bet.match_date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</div>
                  </div>
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Desktop Modal */}
      {window.innerWidth > 768 && showBetDetailsModal && selectedBet && (
        <div className="modal-overlay" onClick={() => setShowBetDetailsModal(false)}>
          <div className="bet-details-modal" onClick={e => e.stopPropagation()}>
            <button className="close-button" onClick={() => setShowBetDetailsModal(false)}>×</button>
            
            <div className="modal-left">
              <div className="modal-header">
                <h3 className="reference-title">Bet Reference: {getReference(selectedBet)}</h3>
                <div className="status-badges">
                  <div className={`status-badge-large ${selectedBet.bet_status}`}>
                    {selectedBet.bet_status === 'joined' && 'BET MATCHED'}
                    {selectedBet.bet_status === 'completed' && 'COMPLETED'}
                  </div>
                  <div className={`match-type-badge-large ${selectedBet.match_type}`}>
                    {selectedBet.match_type === 'half_time' ? 'HALF TIME' : 'FULL TIME'}
                  </div>
                </div>
              </div>
              
              <div className="teams-match">
                <div className="team-card">
                  {selectedBet.bet_choice_user2 === 'team_a_win' && (
                    <div className="selected-badge">Selected</div>
                  )}
                  <img src={getTeamLogo(selectedBet.team_a)} alt={selectedBet.team_a} />
                  <div className="team-name">{selectedBet.team_a}</div>
                  <div className="team-username">{selectedBet.username}</div>
                  <div className="team-odds">{selectedBet.odds_team_a}x</div>
                </div>
                
                <div className="vs-badge">VS</div>
                
                <div className="team-card">
                  {selectedBet.bet_choice_user2 === 'team_b_win' && (
                    <div className="selected-badge">Selected</div>
                  )}
                  <img src={getTeamLogo(selectedBet.team_b)} alt={selectedBet.team_b} />
                  <div className="team-name">{selectedBet.team_b}</div>
                  <div className="team-username">{selectedBet.username2}</div>
                  <div className="team-odds">{selectedBet.odds_team_b}x</div>
                </div>
              </div>

              <div className="match-details-grid">
                <div className="details-section schedule-section">
                  <div className="section-title">MATCH SCHEDULE</div>
                  <div className="schedule-grid">
                    <div className="schedule-item">
                      <span className="schedule-label">MATCH DATE</span>
                      <span className="schedule-value">{new Date(selectedBet.match_date).toLocaleString()}</span>
                    </div>
                    <div className="schedule-item">
                      <span className="schedule-label">START TIME</span>
                      <span className="schedule-value">{new Date(selectedBet.start_time).toLocaleTimeString()}</span>
                    </div>
                    <div className="schedule-item">
                      <span className="schedule-label">END TIME</span>
                      <span className="schedule-value">{new Date(selectedBet.end_time).toLocaleTimeString()}</span>
                    </div>
                    <div className="schedule-item">
                      <span className="schedule-label">CHALLENGE CREATED</span>
                      <span className="schedule-value">{new Date(selectedBet.challenge_date).toLocaleString()}</span>
                    </div>
                  </div>
                </div>

                <div className="details-section odds-section">
                  <div className="section-title">ODDS INFORMATION</div>
                  <div className="odds-grid">
                    <div className="odds-item">
                      <span className="odds-label">{selectedBet.team_a} WIN</span>
                      <span className="odds-value">{selectedBet.odds_team_a}x</span>
                    </div>
                    <div className="odds-item">
                      <span className="odds-label">{selectedBet.team_b} WIN</span>
                      <span className="odds-value">{selectedBet.odds_team_b}x</span>
                    </div>
                    <div className="odds-item">
                      <span className="odds-label">YOUR ODDS</span>
                      <span className="odds-value">{selectedBet.bet_choice_user2 === 'team_a_win' ? selectedBet.odds_team_a : selectedBet.odds_team_b}x</span>
                    </div>
                    <div className="odds-item">
                      <span className="odds-label">POTENTIAL MULTIPLIER</span>
                      <span className="odds-value">{(selectedBet.potential_return_win_user2 / selectedBet.amount_user2).toFixed(2)}x</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <div className="modal-right">
              <div className="details-section">
                <div className="section-title">BET STATUS</div>
                <div className="detail-row">
                  <span className="detail-label">CREATOR</span>
                  <span className="detail-value created-by">{selectedBet.username}</span>
                </div>
                <div className="detail-row">
                  <span className="detail-label">OPPONENT</span>
                  <span className="detail-value status-value">{selectedBet.username2}</span>
                </div>
              </div>

              <div className="details-section">
                <div className="section-title">FINANCIAL DETAILS</div>
                <div className="detail-row">
                  <span className="detail-label">YOUR BET</span>
                  <span className="detail-value amount">{selectedBet.amount_user2} FanCoins</span>
                </div>
                <div className="detail-row">
                  <span className="detail-label">POTENTIAL WIN</span>
                  <span className="detail-value return">{selectedBet.potential_return_win_user2} FanCoins</span>
                </div>
                <div className="detail-row">
                  <span className="detail-label">POTENTIAL LOSS</span>
                  <span className="detail-value amount">{selectedBet.potential_return_loss_user2} FanCoins</span>
                </div>
                <div className="detail-row">
                  <span className="detail-label">DRAW OUTCOME</span>
                  <span className="detail-value">{selectedBet.potential_return_draw_user2} FanCoins</span>
                </div>
                <div className="detail-row">
                  <span className="detail-label">CREATOR BET</span>
                  <span className="detail-value amount">{selectedBet.amount_user1} FanCoins</span>
                </div>
                <div className="detail-row">
                  <span className="detail-label">CREATOR WIN</span>
                  <span className="detail-value return">{selectedBet.potential_return_win_user1} FanCoins</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Mobile Modal */}
      {window.innerWidth <= 768 && showBetDetailsModal && selectedBet && (
        <div className="modal-overlay" onClick={() => setShowBetDetailsModal(false)}>
          <div className="mobile-modal-content" onClick={e => e.stopPropagation()}>
            <div className="mobile-modal-header">
              <h3>Bet Details</h3>
              <button className="mobile-modal-close" onClick={() => setShowBetDetailsModal(false)}>×</button>
            </div>

            <div className="mobile-modal-body">
              <div className="mobile-ref-section">
                <div className="mobile-ref-number">
                  <span className="mobile-ref-label">Reference Number</span>
                  <span className="mobile-ref-value">{getReference(selectedBet)}</span>
                </div>
                <div className="mobile-status-badges">
                  <span className={`mobile-status-badge ${selectedBet.bet_status.toLowerCase()}`}>
                    {selectedBet.bet_status === 'joined' && 'BET MATCHED'}
                    {selectedBet.bet_status === 'completed' && 'COMPLETED'}
                  </span>
                  <span className="mobile-status-badge mobile-match-type">
                    {selectedBet.match_type === 'half_time' ? 'HALF TIME' : 'FULL TIME'}
                  </span>
                </div>
              </div>

              <div className="mobile-users-section">
                <div className="mobile-user-info">
                  <span className="mobile-user-name">{selectedBet.username}</span>
                  <span className="mobile-user-status creator">Creator</span>
                </div>
                <div className="mobile-vs-divider">VS</div>
                <div className="mobile-user-info">
                  <span className="mobile-user-name">{selectedBet.username2}</span>
                  <span className="mobile-user-status opponent">Opponent</span>
                </div>
              </div>

              <div className="mobile-teams-container">
                <div className="mobile-team">
                  <img 
                    src={getTeamLogo(selectedBet.team_a)}
                    alt={selectedBet.team_a}
                    className="mobile-team-logo"
                  />
                  <span>{selectedBet.team_a}</span>
                </div>
                <div className="mobile-vs">VS</div>
                <div className="mobile-team">
                  <img 
                    src={getTeamLogo(selectedBet.team_b)}
                    alt={selectedBet.team_b}
                    className="mobile-team-logo"
                  />
                  <span>{selectedBet.team_b}</span>
                </div>
              </div>

              <div className="mobile-section-title">ODDS INFORMATION</div>
              <div className="mobile-odds-grid">
                <div className="mobile-odds-item">
                  <span className="mobile-odds-label">{selectedBet.team_a} WIN</span>
                  <span className="mobile-odds-value">{selectedBet.odds_team_a}x</span>
                </div>
                <div className="mobile-odds-item">
                  <span className="mobile-odds-label">{selectedBet.team_b} WIN</span>
                  <span className="mobile-odds-value">{selectedBet.odds_team_b}x</span>
                </div>
                <div className="mobile-odds-item">
                  <span className="mobile-odds-label">YOUR ODDS</span>
                  <span className="mobile-odds-value">{selectedBet.bet_choice_user2 === 'team_a_win' ? selectedBet.odds_team_a : selectedBet.odds_team_b}x</span>
                </div>
                <div className="mobile-odds-item">
                  <span className="mobile-odds-label">MULTIPLIER</span>
                  <span className="mobile-odds-value">{(selectedBet.potential_return_win_user2 / selectedBet.amount_user2).toFixed(2)}x</span>
                </div>
              </div>

              <div className="mobile-section-title">FINANCIAL DETAILS</div>
              <div className="mobile-financial-grid">
                <div className="mobile-financial-item">
                  <span className="mobile-financial-label">Your Bet</span>
                  <span className="mobile-financial-value">{selectedBet.amount_user2} FC</span>
                </div>
                <div className="mobile-financial-item">
                  <span className="mobile-financial-label">Potential Win</span>
                  <span className="mobile-financial-value win">+{selectedBet.potential_return_win_user2} FC</span>
                </div>
                <div className="mobile-financial-item">
                  <span className="mobile-financial-label">Potential Loss</span>
                  <span className="mobile-financial-value loss">-{selectedBet.potential_return_loss_user2} FC</span>
                </div>
                <div className="mobile-financial-item">
                  <span className="mobile-financial-label">Draw Return</span>
                  <span className="mobile-financial-value draw">{selectedBet.potential_return_draw_user2} FC</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {renderPagination()}
      <style jsx>{`
        .rounded-table {
          border-radius: 12px;
          overflow: hidden;
          border: 1px solid #e0e0e0;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
        }
        .rounded-table thead {
          background-color: #f8f9fa;
        }
        .rounded-table th {
          padding: 12px 8px;
          font-weight: 600;
          color: #444;
          border-bottom: 2px solid #e0e0e0;
        }
        .rounded-table td {
          padding: 10px 8px;
          border-bottom: 1px solid #e0e0e0;
        }
        .rounded-table tr:last-child td {
          border-bottom: none;
        }
        .teams-header.compact {
          width: 20%;
        }
        .team-cell.compact {
          max-width: 80px;
          padding: 4px;
        }
        .vs-cell.compact {
          padding: 0 4px;
          width: 30px;
        }
        .team-info {
          gap: 4px;
        }
        .team-logo {
          width: 18px;
          height: 18px;
          min-width: 18px;
        }
        .pick-badge {
          font-size: 0.7rem;
          padding: 2px 4px;
          background: #e3f2fd;
          color: #1976d2;
          border-radius: 4px;
          white-space: nowrap;
        }
        .reference {
          color: #1976d2;
          text-decoration: underline;
          cursor: pointer;
        }
        .reference:hover {
          color: #0d47a1;
        }
        .table-responsive {
          margin: 16px;
          background: white;
          border-radius: 12px;
          overflow-x: auto;
        }
        .bets-table {
          width: 100%;
          border-collapse: collapse;
          min-width: 800px;
        }
        .bets-table tr:hover {
          background-color: #f5f5f5;
        }
        .username-cell {
          font-weight: 500;
          color: #2196f3;
        }
        
        .username {
          display: inline-block;
          padding: 2px 6px;
          border-radius: 4px;
          background: #e3f2fd;
          font-size: 0.9rem;
        }

        /* Mobile Styles */
        .mobile-bets-grid {
          display: none;
          padding: 16px;
          gap: 16px;
          flex-direction: column;
        }

        .mobile-bet-card {
          background: white;
          border-radius: 12px;
          padding: 16px;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          border: 1px solid #e0e0e0;
        }

        .mobile-bet-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 16px;
        }

        .mobile-bet-ref {
          color: #1976d2;
          font-weight: 500;
          text-decoration: underline;
          cursor: pointer;
        }

        .mobile-users-section {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 16px;
          padding: 8px 0;
          border-bottom: 1px solid #e0e0e0;
        }

        .mobile-user-info {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 4px;
        }

        .mobile-user-name {
          font-weight: 500;
          color: #333;
        }

        .mobile-user-status {
          font-size: 0.8rem;
          padding: 2px 8px;
          border-radius: 12px;
        }

        .mobile-user-status.creator {
          background: #e3f2fd;
          color: #1976d2;
        }

        .mobile-user-status.opponent {
          background: #f3e5f5;
          color: #7b1fa2;
        }

        .mobile-vs-divider {
          color: #757575;
          font-weight: 500;
        }

        .mobile-teams-container {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 16px;
          padding: 12px;
          background: #f8f9fa;
          border-radius: 8px;
        }

        .mobile-team {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 8px;
          flex: 1;
        }

        .mobile-team-logo {
          width: 40px;
          height: 40px;
          border-radius: 8px;
          object-fit: cover;
        }

        .mobile-team-name {
          font-weight: 500;
          text-align: center;
          font-size: 0.9rem;
        }

        .mobile-pick-badge {
          font-size: 0.7rem;
          padding: 2px 6px;
          background: #e3f2fd;
          color: #1976d2;
          border-radius: 4px;
          white-space: nowrap;
        }

        .mobile-vs {
          color: #757575;
          font-weight: 500;
          margin: 0 12px;
        }

        .mobile-bet-details {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 12px;
        }

        .mobile-detail-item {
          display: flex;
          flex-direction: column;
          gap: 4px;
        }

        .mobile-detail-item.full-width {
          grid-column: 1 / -1;
        }

        .mobile-detail-label {
          font-size: 0.8rem;
          color: #757575;
        }

        .mobile-detail-value {
          font-weight: 500;
          color: #333;
        }

        .mobile-detail-value.win {
          color: #2e7d32;
        }

        @media (max-width: 768px) {
          .table-responsive {
            display: none;
          }
          .mobile-bets-grid {
            display: flex;
          }
        }

        .date-cell {
          padding: 4px 8px;
          min-width: 100px;
        }

        .date-display {
          display: flex;
          flex-direction: column;
          gap: 2px;
          font-size: 0.85rem;
        }

        .date-line {
          color: #333;
        }

        .time-line {
          color: #666;
          font-size: 0.8rem;
        }

        .mobile-date-display {
          display: flex;
          flex-direction: column;
          gap: 2px;
          font-size: 0.85rem;
        }

        .mobile-date-line {
          color: #333;
        }

        .mobile-time-line {
          color: #666;
          font-size: 0.8rem;
        }
      `}</style>
    </div>
  );
}

export default AcceptedBets;