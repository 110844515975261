import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/league.css';

const UserAchievements = () => {
    const [achievements, setAchievements] = useState([]);
    const [stats, setStats] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchAchievements = async () => {
            try {
                const [achievementsRes, statsRes] = await Promise.all([
                    axios.get('/backend/handlers/user_achievements.php'),
                    axios.get('/backend/handlers/user_stats.php')
                ]);
                
                setAchievements(achievementsRes.data.data);
                setStats(statsRes.data.data);
            } catch (error) {
                console.error('Error fetching achievements:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchAchievements();
    }, []);

    if (loading) {
        return <div className="loading">Loading...</div>;
    }

    return (
        <div className="achievements-page">
            <header className="achievements-header">
                <h1>My Achievements</h1>
                {stats && (
                    <div className="achievement-stats">
                        <div className="stat-item">
                            <span className="stat-label">Total Badges</span>
                            <span className="stat-value">{stats.total_badges}</span>
                        </div>
                        <div className="stat-item">
                            <span className="stat-label">Total Points</span>
                            <span className="stat-value">{stats.total_points}</span>
                        </div>
                        <div className="stat-item">
                            <span className="stat-label">Ranking</span>
                            <span className="stat-value">{stats.global_rank}</span>
                        </div>
                    </div>
                )}
            </header>

            <div className="achievements-grid">
                {achievements.map(achievement => (
                    <div 
                        key={achievement.id} 
                        className={`achievement-card ${achievement.unlocked ? 'unlocked' : 'locked'}`}
                    >
                        <div className="achievement-icon">
                            {/* You can replace this with actual icons */}
                            <span className="icon">{achievement.icon_class}</span>
                        </div>
                        <div className="achievement-info">
                            <h3>{achievement.title}</h3>
                            <p>{achievement.description}</p>
                            {achievement.unlocked && (
                                <div className="achievement-date">
                                    Unlocked: {new Date(achievement.unlock_date).toLocaleDateString()}
                                </div>
                            )}
                            {!achievement.unlocked && achievement.progress && (
                                <div className="achievement-progress">
                                    <div 
                                        className="progress-bar" 
                                        style={{width: `${(achievement.current_value / achievement.target_value) * 100}%`}}
                                    ></div>
                                    <span className="progress-text">
                                        {achievement.current_value} / {achievement.target_value}
                                    </span>
                                </div>
                            )}
                        </div>
                        {achievement.reward && (
                            <div className="achievement-reward">
                                <span className="reward-label">Reward:</span>
                                <span className="reward-value">{achievement.reward}</span>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default UserAchievements;
