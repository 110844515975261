import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from '../utils/axiosConfig';
import '../styles/league-home.css';
import { 
    FaCrown, FaStar, FaTrophy, FaMedal, FaInfoCircle, 
    FaChartLine, FaHistory, FaAward, FaBolt, FaFire, FaUsers,
    FaCoins, FaRocket, FaChartBar
} from 'react-icons/fa';

const API_BASE_URL = '/backend';

const LeagueHome = () => {
    const navigate = useNavigate();
    const [leagues, setLeagues] = useState([]);
    const [userStats, setUserStats] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [showSuccessMessage, setShowSuccessMessage] = useState('');
    const [showWelcomeGuide, setShowWelcomeGuide] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentSeason, setCurrentSeason] = useState(null);
    const [joinAmount, setJoinAmount] = useState('');
    const [showJoinModal, setShowJoinModal] = useState(false);
    const [selectedLeague, setSelectedLeague] = useState(null);
    const [userBalance, setUserBalance] = useState(0);
    const leaguesPerPage = 12;

    // Add fetchUserBalance function
    const fetchUserBalance = async () => {
        try {
            const response = await axios.get('/backend/handlers/get_user_balance.php');
            if (response.data.status === 200) {
                setUserBalance(response.data.data.balance);
            }
        } catch (error) {
            console.error('Error fetching user balance:', error);
            setError('Failed to fetch user balance');
        }
    };

    // Fetch leagues and user stats
    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                setError('');

                const response = await axios.get('/backend/handlers/league_home.php');
                console.log('League response:', response.data); // Debug log
                
                if (response.data.status === 200) {
                    const { leagues, user_stats, current_season } = response.data.data;
                    if (leagues && Array.isArray(leagues)) {
                        setLeagues(leagues);
                        console.log('Leagues loaded:', leagues.length); // Debug log
                    } else {
                        setLeagues([]);
                        console.log('No leagues found or invalid data'); // Debug log
                    }
                    setUserStats(user_stats || null);
                    setCurrentSeason(current_season || null);
                    
                    if (!localStorage.getItem('leagueGuideShown')) {
                        setShowWelcomeGuide(true);
                        localStorage.setItem('leagueGuideShown', 'true');
                    }
                } else {
                    setError(response.data.message || 'Failed to load leagues');
                }
            } catch (err) {
                console.error('Error fetching data:', err);
                if (err.response?.status === 401) {
                    navigate('/login');
                } else {
                    setError('Failed to load leagues. Please try again.');
                }
            } finally {
                setLoading(false);
            }
        };

        fetchData();
        fetchUserBalance(); // Also fetch initial balance
    }, [navigate]);

    // Handle league join
    const handleJoinLeague = async (league) => {
        try {
            // First check if user is already in any active league
            const userId = localStorage.getItem('userId');
            if (!userId) {
                setError('Please log in to join a league');
                navigate('/login');
                return;
            }

            const response = await axios.get(`/backend/handlers/user_leagues.php?user_id=${userId}`);
            console.log('User leagues response:', response.data); // Debug log
            
            if (response.data.status === 200) {
                const activeLeagues = response.data.data.filter(l => l.status === 'active');
                if (activeLeagues.length > 0) {
                    setError(`You are already a member of an active league: ${activeLeagues[0].name}. You can only join one league at a time.`);
                    // Close modal if it's open
                    setShowJoinModal(false);
                    setSelectedLeague(null);
                    // Clear the error after 5 seconds
                    setTimeout(() => {
                        setError('');
                    }, 5000);
                    return;
                }
            } else {
                setError(response.data.message || 'Failed to verify league membership');
                setShowJoinModal(false);
                setSelectedLeague(null);
                return;
            }

            // If we get here, user can join the league
            setSelectedLeague(league);
            setShowJoinModal(true);
            setJoinAmount(league.min_bet_amount.toString());
            
            // Refresh user balance before showing modal
            await fetchUserBalance();
        } catch (error) {
            console.error('Error checking user leagues:', error);
            const errorMessage = error.response?.data?.message || error.message || 'Failed to verify league membership status';
            setError(`Error: ${errorMessage}`);
            // Close modal if it's open
            setShowJoinModal(false);
            setSelectedLeague(null);
            // Clear the error after 5 seconds
            setTimeout(() => {
                setError('');
            }, 5000);
        }
    };

    const confirmJoinLeague = async () => {
        try {
            setError('');
            setShowSuccessMessage('');

            const amount = parseFloat(joinAmount);
            if (isNaN(amount)) {
                setError('Please enter a valid amount');
                return;
            }

            if (amount < selectedLeague.min_bet_amount || amount > selectedLeague.max_bet_amount) {
                setError(`Amount must be between ${selectedLeague.min_bet_amount} and ${selectedLeague.max_bet_amount} FC`);
                return;
            }

            if (amount > userBalance) {
                setError(`Insufficient balance. Your current balance is ${userBalance} FC`);
                return;
            }

            const userId = localStorage.getItem('userId');
            if (!userId) {
                setError('User ID not found. Please log in again.');
                navigate('/login');
                return;
            }

            const response = await axios.post('/backend/handlers/join_league.php', 
                { 
                    league_id: selectedLeague.league_id,
                    user_id: userId,
                    amount: amount
                }
            );

            if (response.data.status === 200) {
                setLeagues(prevLeagues => prevLeagues.map(league => {
                    if (league.league_id === selectedLeague.league_id) {
                        return {
                            ...league,
                            ...response.data.data.league,
                            is_member: true
                        };
                    }
                    return league;
                }));
                setShowSuccessMessage('Successfully joined the league!');
                // Close modal after success
                setTimeout(() => {
                    setShowJoinModal(false);
                    setJoinAmount('');
                    setSelectedLeague(null);
                    setShowSuccessMessage('');
                    // Refresh user balance
                    fetchUserBalance();
                }, 2000);
            }
        } catch (err) {
            console.error('Error joining league:', err);
            const errorMessage = err.response?.data?.message || 'Failed to join league';
            setError(errorMessage);
            // Close modal on error
            setTimeout(() => {
                setShowJoinModal(false);
                setJoinAmount('');
                setSelectedLeague(null);
                setError('');
            }, 3000);
            if (err.response?.status === 401) {
                navigate('/login');
            }
        }
    };

    // Filter and paginate leagues
    const filteredLeagues = leagues.filter(league => 
        league.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (league.description && league.description.toLowerCase().includes(searchTerm.toLowerCase()))
    );

    const indexOfLastLeague = currentPage * leaguesPerPage;
    const indexOfFirstLeague = indexOfLastLeague - leaguesPerPage;
    const currentLeagues = filteredLeagues.slice(indexOfFirstLeague, indexOfLastLeague);
    const totalPages = Math.ceil(filteredLeagues.length / leaguesPerPage);

    // Navigation links
    const navLinks = [
        { to: '/user/leagues', label: 'Leagues', icon: <FaTrophy /> },
        { to: '/user/leagues/leaderboard', label: 'Leaderboard', icon: <FaChartLine /> },
        { to: '/user/leagues/achievements', label: 'Achievements', icon: <FaAward /> },
        { to: '/user/leagues/seasons', label: 'Season History', icon: <FaHistory /> }
    ];

    // Season Info Component
    const SeasonInfo = ({ season }) => (
        <div className="season-info">
            <div className="season-header">
                <FaChartLine className="season-icon" />
                <div className="season-title">
                    <h3>{season.name}</h3>
                    <div className="season-dates">
                        <span>Started: {new Date(season.start_date).toLocaleDateString()}</span>
                        <span>Ends: {new Date(season.end_date).toLocaleDateString()}</span>
                    </div>
                </div>
            </div>
            {season.days_remaining > 0 && (
                <div className="season-countdown">
                    <FaRocket className="countdown-icon" />
                    <span>{season.days_remaining} days remaining</span>
                </div>
            )}
        </div>
    );

    // User Stats Bar Component
    const UserStatsBar = ({ stats }) => (
        <div className="user-stats-container">
            <div className="user-stats-bar">
                <div className="stat-item">
                    <FaTrophy className="stat-icon" style={{ color: '#228B22' }} />
                    <div className="stat-info">
                        <label>Total Points</label>
                        <span>{stats?.total_points?.toLocaleString() || '0'}</span>
                    </div>
                </div>
                <div className="stat-item">
                    <FaBolt className="stat-icon" style={{ color: '#228B22' }} />
                    <div className="stat-info">
                        <label>Current Streak</label>
                        <span>{stats?.current_streak || '0'}</span>
                    </div>
                </div>
                <div className="stat-item">
                    <FaCoins className="stat-icon" style={{ color: '#228B22' }} />
                    <div className="stat-info">
                        <label>Balance</label>
                        <span>{parseFloat(stats?.balance || 0).toLocaleString()} FC</span>
                    </div>
                </div>
                <div className="stat-item">
                    <FaChartBar className="stat-icon" style={{ color: '#228B22' }} />
                    <div className="stat-info">
                        <label>Leagues Joined</label>
                        <span>{stats?.leagues_joined || '0'}</span>
                    </div>
                </div>
            </div>
            <div className="progress-container">
                <div className="progress-info">
                    <span>Progress to Next Level</span>
                    <span>{50 - (stats?.points % 50)} points needed</span>
                </div>
                <div className="progress-bar">
                    <div 
                        className="progress-fill" 
                        style={{ width: `${Math.min((stats?.points % 50) / 50 * 100, 100)}%` }}
                    />
                </div>
            </div>
        </div>
    );

    const renderNavigation = () => (
        <nav className="league-nav">
            {navLinks.map((link) => (
                <Link
                    key={link.to}
                    to={link.to}
                    className={`nav-item ${window.location.pathname === link.to ? 'active' : ''}`}
                >
                    {link.icon}
                    {link.label}
                </Link>
            ))}
        </nav>
    );

    // League Card Component
    const LeagueCard = ({ league, userStats, onJoin, navigate }) => (
        <div className="league-list-item">
            <div className="league-main-info">
                <div className="league-icon">
                    {league.league_icon ? (
                        <img src={league.league_icon} alt={`${league.name} icon`} />
                    ) : (
                        <FaTrophy />
                    )}
                </div>
                <div className="league-details">
                    <div className="league-header">
                        <h3 className="league-title">{league.name}</h3>
                        <div className="league-members">
                            <FaUsers className="info-icon" />
                            <span className="info-value">{league.member_count} Members</span>
                        </div>
                    </div>
                    <p className="league-description">{league.description}</p>
                </div>
            </div>

            <div className="league-betting">
                <div className="betting-limits">
                    <div className="limit-item">
                        <span className="limit-label">Min</span>
                        <span className="limit-value">{league.min_bet_formatted} FC</span>
                    </div>
                    <div className="limit-item">
                        <span className="limit-label">Max</span>
                        <span className="limit-value">{league.max_bet_formatted} FC</span>
                    </div>
                </div>
            </div>

            <div className="league-actions">
                {league.is_member ? (
                    <>
                        <div className="status-badge active" style={{ backgroundColor: '#228B22' }}>Active</div>
                        <button 
                            className="view-league-btn"
                            onClick={() => navigate(`/user/leagues/${league.league_id}`)}
                        >
                            <FaChartLine /> View
                        </button>
                    </>
                ) : league.has_active_membership ? (
                    <div className="status-badge inactive" style={{ backgroundColor: '#228B22' }}>In League</div>
                ) : (
                    <button 
                        onClick={() => onJoin(league)}
                        className="join-league-btn"
                        disabled={userStats?.balance < league.min_bet_amount}
                    >
                        {userStats?.balance < league.min_bet_amount ? (
                            <>
                                <FaCoins /> Low Balance
                            </>
                        ) : (
                            <>
                                <FaRocket /> Join
                            </>
                        )}
                    </button>
                )}
            </div>
        </div>
    );

    // Empty League List Component
    const EmptyLeagueList = () => (
        <div className="empty-leagues">
            <FaTrophy className="empty-state-icon" />
            <p>No leagues available at the moment</p>
            <span>Check back soon for new leagues!</span>
        </div>
    );

    // Welcome Guide Component
    const WelcomeGuide = ({ onClose }) => (
        <div className="welcome-guide">
            <div className="guide-content">
                <h2><FaCrown /> Welcome to 247 League!</h2>
                <div className="guide-features">
                    <div className="feature-item">
                        <FaTrophy className="feature-icon" />
                        <div className="feature-text">
                            <h3>Compete in Leagues</h3>
                            <p>Join leagues that match your betting range and compete with others</p>
                        </div>
                    </div>
                    <div className="feature-item">
                        <FaChartLine className="feature-icon" />
                        <div className="feature-text">
                            <h3>Earn Points</h3>
                            <p>Win: 3 points | Draw: 1 point | Climb the leaderboard</p>
                        </div>
                    </div>
                    <div className="feature-item">
                        <FaMedal className="feature-icon" />
                        <div className="feature-text">
                            <h3>Win Rewards</h3>
                            <p>Unlock badges and earn rewards as you progress</p>
                        </div>
                    </div>
                    <div className="feature-item">
                        <FaCoins className="feature-icon" />
                        <div className="feature-text">
                            <h3>Secure Investment</h3>
                            <p>Get your minimum deposit back after the season (90 days)</p>
                        </div>
                    </div>
                </div>
                <button className="start-button" onClick={onClose}>
                    Get Started <FaRocket />
                </button>
            </div>
        </div>
    );

    return (
        <div className="league-home-container">
            {/* User Stats Bar */}
            {userStats && <UserStatsBar stats={userStats} />}
            
            {/* Season Information */}
            {currentSeason && <SeasonInfo season={currentSeason} />}
            
            {/* Error Message */}
            {error && <div className="error-message" style={{
                position: 'fixed',
                top: '20px',
                right: '20px',
                background: '#ff4444',
                color: 'white',
                padding: '15px 25px',
                borderRadius: '5px',
                zIndex: 1000,
                animation: 'slideIn 0.3s ease-out'
            }}>{error}</div>}
            
            {/* Search and Controls */}
            <div className="league-controls">
                <input
                    type="text"
                    placeholder="Search leagues..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="league-search"
                />
                <Link to="/user/my-leagues" className="view-my-leagues-btn">
                    <FaUsers /> My Leagues
                </Link>
            </div>

            {/* Leagues List */}
            <div className="leagues-list">
                {loading ? (
                    <div className="loading">Loading leagues...</div>
                ) : error ? (
                    <div className="error-message">{error}</div>
                ) : currentLeagues.length === 0 ? (
                    <EmptyLeagueList />
                ) : (
                    currentLeagues.map(league => (
                        <LeagueCard 
                            key={league.league_id} 
                            league={league} 
                            userStats={userStats} 
                            onJoin={handleJoinLeague} 
                            navigate={navigate} 
                        />
                    ))
                )}
            </div>

            {/* Pagination - Always Visible */}
            <div className="pagination">
                <button 
                    onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                    disabled={currentPage === 1}
                >
                    <FaChartLine className="rotate-180" /> Previous
                </button>
                <span>Page {currentPage} of {Math.max(totalPages, 1)}</span>
                <button 
                    onClick={() => setCurrentPage(prev => Math.min(prev + 1, Math.max(totalPages, 1)))}
                    disabled={currentPage === Math.max(totalPages, 1)}
                >
                    Next <FaChartLine />
                </button>
            </div>

            {/* Welcome Guide */}
            {showWelcomeGuide && <WelcomeGuide onClose={() => setShowWelcomeGuide(false)} />}
            
            {/* Success Message */}
            {showSuccessMessage && (
                <div className="success-message">{showSuccessMessage}</div>
            )}
            
            {/* Join League Modal */}
            {showJoinModal && selectedLeague && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <h2>Join {selectedLeague.name}</h2>
                        <p>Your Balance: {userBalance.toLocaleString()} FC</p>
                        <p>Min Bet: {selectedLeague.min_bet_amount.toLocaleString()} FC</p>
                        <p>Max Bet: {selectedLeague.max_bet_amount.toLocaleString()} FC</p>
                        <div className="form-group">
                            <label>Enter Amount (FC):</label>
                            <input
                                type="number"
                                value={joinAmount}
                                onChange={(e) => setJoinAmount(e.target.value)}
                                min={selectedLeague.min_bet_amount}
                                max={Math.min(selectedLeague.max_bet_amount, userBalance)}
                            />
                        </div>
                        {error && <div className="error">{error}</div>}
                        <div className="modal-buttons">
                            <button onClick={confirmJoinLeague} 
                                    disabled={parseFloat(joinAmount) > userBalance}
                                    className="primary-button">
                                Join League
                            </button>
                            <button onClick={() => {
                                setShowJoinModal(false);
                                setError('');
                                setJoinAmount('');
                            }} className="secondary-button">
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default LeagueHome;
