import React, { useState, useEffect, useCallback } from 'react';
import { Link, useLocation, useNavigate, Outlet } from 'react-router-dom';
import axios from '../utils/axiosConfig';
import './UserLayoutSidebar.css';
import './UserLayout.css';
import { FaEnvelope, FaBars, FaChevronLeft, FaTimes } from 'react-icons/fa';
import { useUser } from '../context/UserContext';

function UserLayout() {
  const location = useLocation();
  const navigate = useNavigate();
  const { userData, setUserData } = useUser();
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);
  const [unreadMessages, setUnreadMessages] = useState(0);
  const [pendingRequests, setPendingRequests] = useState(0);
  const [prevUnreadCount, setPrevUnreadCount] = useState(0);
  const [notification, setNotification] = useState(null);
  const userId = localStorage.getItem('userId');
  const username = localStorage.getItem('username');
  const token = localStorage.getItem('userToken');

  // List of public routes that don't need auth
  const publicRoutes = ['/login', '/register', '/', '/about'];

  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth <= 768;
      setIsMobileView(mobile);
      if (!mobile) {
        setIsMobileSidebarOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Close mobile sidebar when route changes
  useEffect(() => {
    setIsMobileSidebarOpen(false);
  }, [location.pathname]);

  const fetchUserData = useCallback(async () => {
    if (!token || !userId) return;

    try {
      const response = await axios.get('/backend/handlers/user_data.php', {
        params: { userId },
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      
      if (response.data.success) {
        setUserData({
          balance: response.data.balance,
          points: response.data.points,
          username: response.data.username
        });
      } else {
        console.error('Failed to fetch user data:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      if (error.response?.status === 401) {
        navigate('/login', { state: { from: location.pathname } });
      }
    }
  }, [token, userId, navigate, location.pathname]);

  const fetchNotifications = useCallback(async () => {
    if (!token || !userId) return;

    try {
      const response = await axios.get('/backend/handlers/messages.php', {
        params: {
          user_id: userId,
          type: 'unread_count'
        }
      });
      
      if (response.data.success) {
        setUnreadMessages(response.data.unread_count);
      }
      
      setPendingRequests(0);
    } catch (error) {
      console.error('Error fetching notifications:', error);
      if (error.response?.status === 401 && !publicRoutes.some(route => location.pathname.startsWith(route))) {
        navigate('/login', { state: { from: location.pathname } });
      }
    }
  }, [token, userId, navigate, location.pathname, publicRoutes]);

  useEffect(() => {
    const isPublicRoute = publicRoutes.some(route => location.pathname.startsWith(route));
    
    if (!isPublicRoute && (!token || !userId)) {
      navigate('/login', { state: { from: location.pathname } });
      return;
    }

    if (!isPublicRoute && token && userId) {
      fetchUserData();
      fetchNotifications();
      
      const userDataInterval = setInterval(fetchUserData, 30000);
      const notificationInterval = setInterval(fetchNotifications, 60000);

      return () => {
        clearInterval(userDataInterval);
        clearInterval(notificationInterval);
      };
    }
  }, [token, userId, navigate, location.pathname, fetchUserData, fetchNotifications]);

  useEffect(() => {
    if (unreadMessages > prevUnreadCount) {
      setNotification({
        message: `You have ${unreadMessages} new message${unreadMessages > 1 ? 's' : ''}`,
        type: 'info'
      });
      setTimeout(() => setNotification(null), 5000);
    }
    setPrevUnreadCount(unreadMessages);
  }, [unreadMessages, prevUnreadCount]);

  const handleLogout = () => {
    localStorage.removeItem('userId');
    localStorage.removeItem('userToken');
    navigate('/login');
  };

  const getPageTitle = () => {
    const path = location.pathname;
    if (path === '/user/dashboard') return 'Dashboard';
    if (path === '/user/profile') return 'Profile';
    if (path === '/user/bets/outgoing') return 'Outgoing Bets';
    if (path === '/user/bets/incoming') return 'Incoming Bets';
    if (path === '/user/bets/accepted') return 'Accepted Bets';
    if (path === '/user/friends') return 'Friends';
    if (path === '/user/messages') return 'Messages';
    if (path === '/user/transfer') return 'Transfer';
    if (path === '/user/leaderboard') return 'Leaderboard';
    if (path === '/user/league') return '247 League';
    if (path === '/user/my-leagues') return 'My Leagues';
    if (path === '/user/wallet') return 'Wallet';
    if (path === '/user/settings') return 'Settings';
    if (path === '/user/friend-requests') return 'Friend Requests';
    if (path === '/user/challenges') return 'Challenges';
    if (path === '/user/recent-bets') return 'Recent Bets';
    return 'Dashboard';
  };

  const toggleSidebar = () => {
    if (isMobileView) {
      setIsMobileSidebarOpen(!isMobileSidebarOpen);
    } else {
      setIsSidebarCollapsed(!isSidebarCollapsed);
    }
  };

  return (
    <div className={`user-layout ${isMobileView ? 'mobile-view' : ''} ${isMobileSidebarOpen ? 'mobile-sidebar-open' : ''}`}>
      {/* Mobile Header */}
      {isMobileView && (
        <div className="mobile-header">
          <button 
            className="mobile-menu-toggle"
            onClick={toggleSidebar}
            aria-label="Toggle menu"
            aria-expanded={isMobileSidebarOpen}
          >
            {isMobileSidebarOpen ? <FaTimes /> : <FaBars />}
          </button>
          <div className="mobile-logo">
            <Link to="/">FanBet247</Link>
          </div>
          <div className="mobile-user-info">
            <span className="mobile-balance">₦{userData.balance.toLocaleString()}</span>
          </div>
        </div>
      )}

      {/* Sidebar Overlay for Mobile */}
      {isMobileView && isMobileSidebarOpen && (
        <div className="sidebar-overlay" onClick={() => setIsMobileSidebarOpen(false)} />
      )}

      <aside className={`user-dashboard-sidebar ${isSidebarCollapsed ? 'collapsed' : ''} ${isMobileView ? 'mobile' : ''} ${isMobileSidebarOpen ? 'open' : ''}`}>
        <div className="sidebar-header">
          <div className="user-dashboard-logo">
            <Link to="/">{isSidebarCollapsed && !isMobileView ? 'FB' : 'FanBet247'}</Link>
          </div>
          {!isMobileView && (
            <button className="sidebar-toggle" onClick={toggleSidebar}>
              {isSidebarCollapsed ? (
                <FaBars className="toggle-icon" />
              ) : (
                <FaChevronLeft className="toggle-icon" />
              )}
            </button>
          )}
        </div>
        
        <div className="user-dashboard-profile">
          <div className="profile-section" title={username}>
            <span role="img" aria-label="User profile" className="profile-icon">👤</span>
            {(!isSidebarCollapsed || isMobileView) && <span className="profile-name">{username}</span>}
          </div>
          <div className="profile-balance">
            <div className="balance-item" title={`Naira Balance: ₦${userData.balance.toLocaleString()}`}>
              <span role="img" aria-label="Naira currency" className="balance-icon">₦</span>
              {(!isSidebarCollapsed || isMobileView) && <span>{userData.balance.toLocaleString()}</span>}
            </div>
            <div className="balance-item" title={`Fancoins: ${userData.points.toLocaleString()} FC`}>
              <span role="img" aria-label="Coin" className="balance-icon">🪙</span>
              {(!isSidebarCollapsed || isMobileView) && <span>{userData.points.toLocaleString()} FC</span>}
            </div>
          </div>
        </div>

        <nav className="user-dashboard-nav">
          <ul className="dashboard-nav-list">
            <li className={location.pathname === '/user/dashboard' ? 'active' : ''}>
              <Link to="/user/dashboard" title="Dashboard">
                <span role="img" aria-label="Home" className="nav-icon">🏠</span>
                {(!isSidebarCollapsed || isMobileView) && <span>Dashboard</span>}
              </Link>
            </li>
            <li className={location.pathname === '/user/profile' ? 'active' : ''}>
              <Link to="/user/profile" title="Profile">
                <span role="img" aria-label="User" className="nav-icon">👤</span>
                {(!isSidebarCollapsed || isMobileView) && <span>Profile</span>}
              </Link>
            </li>
            <li className={location.pathname === '/user/bets/outgoing' ? 'active' : ''}>
              <Link to="/user/bets/outgoing" title="Outgoing Bets">
                <span role="img" aria-label="Outbox" className="nav-icon">📤</span>
                {(!isSidebarCollapsed || isMobileView) && <span>Outgoing Bets</span>}
              </Link>
            </li>
            <li className={location.pathname === '/user/challenges' ? 'active' : ''}>
              <Link to="/user/challenges" title="Challenges">
                <span role="img" aria-label="Target" className="nav-icon">🎯</span>
                {(!isSidebarCollapsed || isMobileView) && <span>Challenges</span>}
              </Link>
            </li>
            <li className={location.pathname === '/user/recent-bets' ? 'active' : ''}>
              <Link to="/user/recent-bets" title="Recent Bets">
                <span role="img" aria-label="Chart" className="nav-icon">📊</span>
                {(!isSidebarCollapsed || isMobileView) && <span>Recent Bets</span>}
              </Link>
            </li>
            <li className={location.pathname === '/user/bets/incoming' ? 'active' : ''}>
              <Link to="/user/bets/incoming" title="Incoming Bets">
                <span role="img" aria-label="Inbox" className="nav-icon">📥</span>
                {(!isSidebarCollapsed || isMobileView) && <span>Incoming Bets</span>}
              </Link>
            </li>
            <li className={location.pathname === '/user/bets/accepted' ? 'active' : ''}>
              <Link to="/user/bets/accepted" title="Accepted Bets">
                <span role="img" aria-label="Check mark" className="nav-icon">✅</span>
                {(!isSidebarCollapsed || isMobileView) && <span>Accepted Bets</span>}
              </Link>
            </li>
            <li className={location.pathname === '/user/friends' ? 'active' : ''}>
              <Link to="/user/friends" title="Friends">
                <span role="img" aria-label="People" className="nav-icon">👥</span>
                {(!isSidebarCollapsed || isMobileView) && <span>Friends</span>}
                {pendingRequests > 0 && <span className="notification-badge">{pendingRequests}</span>}
              </Link>
            </li>
            <li className={location.pathname === '/user/friend-requests' ? 'active' : ''}>
              <Link to="/user/friend-requests" title="Friend Requests">
                <span role="img" aria-label="Bell" className="nav-icon">🔔</span>
                {(!isSidebarCollapsed || isMobileView) && <span>Friend Requests</span>}
                {pendingRequests > 0 && (
                  <span className="notification-badge">{pendingRequests}</span>
                )}
              </Link>
            </li>
            <li className={location.pathname === '/user/messages' ? 'active' : ''}>
              <Link to="/user/messages" title="Messages">
                <span role="img" aria-label="Speech bubble" className="nav-icon">💬</span>
                {(!isSidebarCollapsed || isMobileView) && <span className="menu-text">Messages</span>}
                {unreadMessages > 0 && (
                  <span className="menu-notification">{unreadMessages}</span>
                )}
              </Link>
            </li>
            <li className={location.pathname === '/user/transfer' ? 'active' : ''}>
              <Link to="/user/transfer" title="Transfer">
                <span role="img" aria-label="Transfer arrows" className="nav-icon">↔️</span>
                {(!isSidebarCollapsed || isMobileView) && <span>Transfer</span>}
              </Link>
            </li>
            <li className={location.pathname === '/user/leaderboard' ? 'active' : ''}>
              <Link to="/user/leaderboard" title="Leaderboard">
                <span role="img" aria-label="Trophy" className="nav-icon">🏆</span>
                {(!isSidebarCollapsed || isMobileView) && <span>Leaderboard</span>}
              </Link>
            </li>
            <li className={location.pathname === '/user/league' ? 'active' : ''}>
              <Link to="/user/league" title="247 League">
                <span role="img" aria-label="Soccer ball" className="nav-icon">⚽</span>
                {(!isSidebarCollapsed || isMobileView) && <span>247 League</span>}
              </Link>
            </li>
            <li className={location.pathname === '/user/my-leagues' ? 'active' : ''}>
              <Link to="/user/my-leagues" title="My Leagues">
                <span role="img" aria-label="Trophy" className="nav-icon">🏆</span>
                {(!isSidebarCollapsed || isMobileView) && <span>My Leagues</span>}
              </Link>
            </li>
            <li className={location.pathname === '/user/wallet' ? 'active' : ''}>
              <Link to="/user/wallet" title="Credit Wallet">
                <span role="img" aria-label="Wallet" className="nav-icon">👛</span>
                {(!isSidebarCollapsed || isMobileView) && <span>Credit Wallet</span>}
              </Link>
            </li>
            <li className={location.pathname === '/user/credit-history' ? 'active' : ''}>
              <Link to="/user/credit-history" title="Credit History">
                <span role="img" aria-label="Scroll" className="nav-icon">📜</span>
                {(!isSidebarCollapsed || isMobileView) && <span>Credit History</span>}
              </Link>
            </li>
            <li className={location.pathname === '/user/settings' ? 'active' : ''}>
              <Link to="/user/settings" title="Settings">
                <span role="img" aria-label="Gear" className="nav-icon">⚙️</span>
                {(!isSidebarCollapsed || isMobileView) && <span>Settings</span>}
              </Link>
            </li>
          </ul>
        </nav>

        <button onClick={handleLogout} className="sidebar-logout-btn" title="Logout">
          <span role="img" aria-label="Door" className="logout-icon">🚪</span>
          {(!isSidebarCollapsed || isMobileView) && 'Logout'}
        </button>
      </aside>

      <main className={`main-container ${isSidebarCollapsed && !isMobileView ? 'expanded' : ''}`}>
        {notification && (
          <div className={`notification-banner ${notification.type}`}>
            <div className="notification-content">
              <FaEnvelope className="notification-icon" />
              <span>{notification.message}</span>
            </div>
          </div>
        )}
        
        {!isMobileView && (
          <header className="dashboard-header">
            <div className="dashboard-nav">
              <div className="nav-left">
                <h1 className="page-title">{getPageTitle()}</h1>
              </div>
              <div className="nav-right">
                <div className="user-balances">
                  <div className="balance-item">
                    <span className="balance-label">Balance:</span>
                    <span className="balance-amount">₦{userData.balance.toLocaleString()}</span>
                  </div>
                  <div className="balance-item">
                    <span className="balance-label">Points:</span>
                    <span className="balance-amount">{userData.points.toLocaleString()} FC</span>
                  </div>
                </div>
                <button onClick={handleLogout} className="nav-logout-btn">
                  <i className="nav-icon">🚪</i>
                  Logout
                </button>
              </div>
            </div>
          </header>
        )}
        
        <div className="dashboard-content">
          <Outlet />
        </div>
      </main>
    </div>
  );
}

export default UserLayout;